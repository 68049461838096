<template>
    <div>
        <div class="product-card  option-bottom" v-if="product && !type_card">
            <div class="img-box">

                <!-- <li class="active thumb">
                    <img v-bind:src="product.image" alt="thumbnail" />
                </li> -->
                <!-- <ul class="thumbnail-img">
                <template v-for="(imagen,index) in product.images" :key="index">
                    <li class="thumb" :class="{'active': index == 0 }">
                    <img v-bind:src="imagen.imagen" alt="thumbnail" />
                    </li>
                </template>     custom-class="img-fluid" style="min-height: 250px;" 
                
                </ul> :to="'/product/'+product.slug+getUrlDiscount(product)" 
            v-lazy="{ src: imagen_1_b, loading: '/assets/banner/banner_load.jpg', delay: 1000 }"
              bg-img style="min-height: 332px;" 
                :href="'/product/'+product.slug+getUrlDiscount(product)" -->

                <a v-if="!is_not_detail" href="javascript:void(0)" :data-url="'/product/'+product.slug+getUrlDiscount(product)"  class="primary-img">
                    <!-- <img class="img-fluid" loading="lazy" v-bind:src="product.image" style="min-height: 332px;"
                    alt="product" /> v-lazy="{ src: product.image, delay: 500 }"-->
                    <ProgressiveImage 
                        placeholder-src="/assets/svg_load/image-preloader.svg"
                        v-if="product.image" class="img-fluid"  :src="product.image" />
                </a>

                <router-link v-if="is_not_detail" :to="'/product/'+product.slug+getUrlDiscount(product)"  class="primary-img">
                    <!-- <img class="img-fluid" loading="lazy" v-bind:src="product.image" style="min-height: 332px;"
                    alt="product" /> v-lazy="{ src: product.image, delay: 500 }"-->
                    <ProgressiveImage 
                        placeholder-src="/assets/svg_load/image-preloader.svg"
                        v-if="product.image" class="img-fluid"  :src="product.image" />
                </router-link>
                
                <!-- s<span class="arrow-label-wrap" v-if="product.name_tag"> !product.color_tag && 
                <span class="arrow-label" :style="{background: product.color_tag+' !important'}"> {{product.name_tag}}</span> 
                </span> -->
                <span class="arrow-label-wrap" v-if="!product.discount_g && !product.code_offer && getTagNew(product)"> 
                    <span class="arrow-label bg-success"> {{ getNameTagNew(product) }}</span> 
                </span>

                <span class="arrow-label-wrap"  v-if="product.color_tag && !product.discount_g && !product.code_offer && !getTagNew(product)"> <span class="arrow-label bg-primary p-1" 
                    :style="{'background-color': product.color_tag+' !important'}" style="width: 100%;"> {{ product.name_tag }}</span> 
                </span>

                <span class="arrow-label-wrap"  v-if="product.discount_g && (typeof product.discount_g.discount === 'string' || product.discount_g.discount instanceof String) && !product.code_offer"> <span class="arrow-label bg-primary p-1" 
                    :style="{'background-color': product.discount_g.color_tag+'  !important'}" style="width: 100%;"> {{ product.discount_g.title }} - {{ getDiscountPrice(product) }}</span> 
                </span>
                
                <span class="arrow-label-wrap"  v-if="product.discount_g && !(typeof product.discount_g.discount === 'string' || product.discount_g.discount instanceof String) && !product.code_offer"> <span class="arrow-label bg-primary p-1" 
                    :style="{'background-color': product.discount_g.discount.color_tag+'  !important'}" style="width: 100%;"> {{ product.discount_g.discount.title }} - {{ getDiscountPrice(product) }}</span> 
                </span>

                <span class="arrow-label-wrap"  v-if="product.code_offer"> <span class="arrow-label bg-primary p-1" 
                    :style="{'background-color': product.code_offer.color_tag+'  !important'}" style="width: 100%;"> Descuento - {{ getOfferPrice(product) }}</span> 
                </span>
                <!-- <div class="rating">

                <span>{{ product.avg_rating }} <i data-feather="star"></i> </span>
                <span>{{ product.reviews_count }}</span>
                </div> wishlist-btn -->

                <ul class="option-wrap">

                    <template v-if="!pc_build_attribute">
                        <li data-bs-toggle="tooltip" data-bs-placement="left" title="Lista Deseos" @click="addWishList(product)">
                            <a href="javascript:void(0)" class="">
                            <!-- <i data-feather="heart"></i> -->
                            <img src="/assets/images/Nuevos/favorites-icon.webp">
                            </a>
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-placement="left" title="Comparar">
                            <a href="javascript:void(0)" @click="goToCompare(product)">
                            <!-- <i data-feather="repeat"></i> -->
                            <img src="/assets/images/Nuevos/compare-icon.webp">
                            </a>
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-placement="left" title="Ver Producto">
                            <a href="javascript:void(0)" @click="openDetailProduct(product)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <!-- <i data-feather="zoom-in"></i> -->
                                <img src="/assets/images/Nuevos/search-icon.webp">
                            </a>
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                            <a href="javascript:void(0)" @click="addCart(product)" class="">
                            <!-- <i data-feather="shopping-bag"></i> -->
                            <img src="/assets/images/Nuevos/cart-icon.webp">
                            </a>
                        </li>
                    </template>

                    <template v-if="pc_build_attribute">
                        <button class="btn btn-primary btn-sm" @click="pcBuildSelectedProduct(product)">Selected</button>
                    </template>
                </ul>
            </div>
            <div class="content-box">
                <a :href="'/product/'+product.slug+getUrlDiscount(product)">
                    <p><a :href="'/shop/brands/'+product.marca.id+'?text='+product.marca.name" target="_blank">{{ product.marca.name }}</a></p>
                    <h5>{{ product.title }}</h5>
                    <span>$ {{ getAmountTotal(product) }} </span> 
                    <del v-if="product.discount_g || product.code_offer">$ {{ getAmountDiscount(product) }} </del>
                </a>
            </div>
        </div>
        <div class="product-card  option-bottom" v-if="product && type_card">
            <div class="img-box">
                <a v-if="!is_not_detail" href="javascript:void(0)" :data-url="'/product/'+product.slug+getUrlDiscount(product)"  class="primary-img slide-link">
                    <ProgressiveImage 
                        placeholder-src="/assets/svg_load/image-preloader.svg"
                        v-if="product.image" class="img-fluid"  :src="product.image" />
                </a>

                <span v-if="is_not_detail" @click="toUrlProduct('/product/'+product.slug+getUrlDiscount(product))" class="primary-img slide-link" style="cursor: pointer;">
                    <ProgressiveImage 
                        placeholder-src="/assets/svg_load/image-preloader.svg"
                        v-if="product.image" class="img-fluid"  :src="product.image" />
                </span>

                <span class="arrow-label-wrap" v-if="!product.discount_g && !product.code_offer && getTagNew(product)"> 
                    <span class="arrow-label bg-success"> {{ getNameTagNew(product) }}</span> 
                </span>

                <span class="arrow-label-wrap"  v-if="product.color_tag && !product.discount_g && !product.code_offer && !getTagNew(product)"> <span class="arrow-label bg-primary p-1" 
                    :style="{'background-color': product.color_tag+' !important'}" style="width: 100%;"> {{ product.name_tag }}</span> 
                </span>

                <span class="arrow-label-wrap"  v-if="product.discount_g && (typeof product.discount_g.discount === 'string' || product.discount_g.discount instanceof String) && !product.code_offer"> <span class="arrow-label bg-primary p-1" 
                    :style="{'background-color': product.discount_g.color_tag+'  !important'}" style="width: 100%;"> {{ product.discount_g.title }} - {{ getDiscountPrice(product) }}</span> 
                </span>
                
                <span class="arrow-label-wrap"  v-if="product.discount_g && !(typeof product.discount_g.discount === 'string' || product.discount_g.discount instanceof String) && !product.code_offer"> <span class="arrow-label bg-primary p-1" 
                    :style="{'background-color': product.discount_g.discount.color_tag+'  !important'}" style="width: 100%;"> {{ product.discount_g.discount.title }} - {{ getDiscountPrice(product) }}</span> 
                </span>

                <span class="arrow-label-wrap"  v-if="product.code_offer"> <span class="arrow-label bg-primary p-1" 
                    :style="{'background-color': product.code_offer.color_tag+'  !important'}" style="width: 100%;"> Descuento - {{ getOfferPrice(product) }}</span> 
                </span>

                <ul class="option-wrap">

                    <template v-if="!pc_build_attribute">
                        <li data-bs-toggle="tooltip" data-bs-placement="left" title="Lista Deseos" @click="addWishList(product)">
                            <span href="javascript:void(0)" class="tooltip-icon">
                            <img src="/assets/images/Nuevos/favorites-icon.webp">
                            </span>
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-placement="left" title="Comparar">
                            <span href="javascript:void(0)" class="tooltip-icon" @click="goToCompare(product)">
                            <img src="/assets/images/Nuevos/compare-icon.webp">
                            </span>
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-placement="left" title="Ver Producto">
                            <span href="javascript:void(0)" class="tooltip-icon" @click="openDetailProduct(product)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <img src="/assets/images/Nuevos/search-icon.webp">
                            </span>
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                            <span href="javascript:void(0)" class="tooltip-icon" @click="addCart(product)">
                            <img src="/assets/images/Nuevos/cart-icon.webp">
                            </span>
                        </li>
                    </template>

                    <template v-if="pc_build_attribute">
                        <button class="btn btn-primary btn-sm" @click="pcBuildSelectedProduct(product)">Selected</button>
                    </template>
                </ul>
            </div>
            <div class="content-box">
                <span @click="toUrlProduct('/product/'+product.slug+getUrlDiscount(product))" class="brand-card-prod">
                    <p><span class="brand-card"
                         @click="toUrlProduct('/shop/brands/'+product.marca.id+'?text='+product.marca.name)" 
                        >{{ product.marca.name }}</span></p>
                    <h5>{{ product.title }}</h5>
                    <span>$ {{ getAmountTotal(product) }} </span> 
                    <del v-if="product.discount_g || product.code_offer">$ {{ getAmountDiscount(product) }} </del>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import CartResource from '../../resource/cart'
import WishListResource from '../../resource/wishlist'
import CompareResource from '../../resource/compare'

export default {
    name:'CardProductA',
    props: ["product","is_not_detail","pc_build_attribute","type_card"],
    methods: {
        // getAmountTotal(product){
        //     if(product.discount_g && !product.code_offer){
        //         if(product.discount_g.discount_id){
        //             return product.discount_g.new_amount;
        //             // percentage
        //         }else{
        //             if(product.discount_g.type_discount == 1){//%
        //                 return product.precio_general - (product.precio_general*product.discount_g.discount*0.01);
        //             }else{
        //                 return product.precio_general - product.discount_g.discount;
        //             }
        //         }
        //     }
        //     if(product.code_offer){
        //         if(product.code_offer.new_amount){
        //             return product.code_offer.new_amount;
        //             // percentage
        //         }else{
        //             if(product.code_offer.type_discount == 1){//%
        //                 return product.precio_general - (product.precio_general*product.code_offer.discount*0.01);
        //             }else{
        //                 return product.precio_general - product.code_offer.discount;
        //             }
        //         }
        //     }
        //     return product.precio_general;
        // },
        toUrlProduct(URL){
            window.location.href = URL;
        },
        getAmountTotal(product){
            if(product.discount_g && !product.code_offer){
                if(product.discount_g.discount_id){
                return Number(product.discount_g.new_amount.toFixed(2)).toFixed(2);
                // percentage
                }else{
                if(product.discount_g.type_discount == 1){//%
                    return Number((product.precio_general - (product.precio_general*product.discount_g.discount*0.01)).toFixed(2)).toFixed(2);
                }else{
                    return Number((product.precio_general - product.discount_g.discount).toFixed(2)).toFixed(2);
                }
                }
            }
            if(product.code_offer){
                if(product.code_offer.new_amount){
                return Number(product.code_offer.new_amount.toFixed(2)).toFixed(2);
                // percentage
                }else{
                if(product.code_offer.type_discount == 1){//%
                    return Number((product.precio_general - (product.precio_general*product.code_offer.discount*0.01)).toFixed(2)).toFixed(2);
                }else{
                    return Number((product.precio_general - product.code_offer.discount).toFixed(2)).toFixed(2);
                }
                }
            }
            return Number(product.precio_general).toFixed(2);
        },
        getUrlDiscount(product){
            if(product.code_offer){
                return "?CODE_OFFER="+product.code_offer.uniqd;
            }
            return "";
        },
        getAmountDiscount(product){
            return (product.precio_general).toFixed(2);
        },
        goDetailProduct(product){
            this.$router.push({
                name: 'DetailProduct',
                params: {
                slug: product.slug,
                }
            })
        },
        openDetailProduct(product){
            this.emitter.emit('product-detail-modal', product);
        },
        pcBuildSelectedProduct(product){
            this.emitter.emit('pc-build-product-selec', product);
        },
        addWishList(productT){
            console.log(this.WISHLIST);
            let WISHLIST = this.WISHLIST;//$cookies.get("wishlist") ? this.$cookies.get("wishlist") : [];
            WishListResource.add(WISHLIST,productT,this.emitter, this.$notify,this.$cookies);
            // let INDEX = WISHLIST.findIndex(product => product.uniqd == productT.uniqd);
            
            // if(INDEX != -1){
            //     this.$notify({
            //         title: "LISTA DE DESEOS",
            //         text: `TU PRODUCTO '${productT.title}' YA SE ENCUENTRA EN LA LISTA DE DESEO!`,
            //         type:'error',
            //         duration: 1000,
            //     });
            //     return;
            // }else{
            //     WISHLIST.push({
            //       title: productT.title ,
            //       imagen: productT.image,
            //       uniqd: productT.uniqd ,
            //       slug: productT.slug ,
            //       categorie_first: productT.categorie_first,
            //       categorie_second: productT.categorie_second,
            //       categorie_third: productT.categorie_third,
            //       precio_general: productT.precio_general,
            //     });
            // }
            // // console.log(WISHLIST);
            // this.$cookies.set('wishlist',WISHLIST);
            // this.emitter.emit('wish-list-product', WISHLIST);
            // this.$notify({
            //     title: "LISTA DE DESEOS",
            //     text: `TU PRODUCTO '${productT.title}' SE AGREGO A LA LISTA DE DESEO!`,
            //     duration: 1000,
            // });
        },
        goToCompare(productT){
            // console.log(productT);
            CompareResource.add(productT,this.emitter, this.$notify,this.$cookies,this.$router)
        },
        addCart(PRODUCTY){
            let CARTS = this.CARTS;//this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
            // console.log(CARTS);
            CartResource.add(CARTS,PRODUCTY,this.emitter,this.$notify,this.$cookies);
        },
        getDiscountPrice(product){
            if(typeof product.discount_g.discount === 'string' || product.discount_g.discount instanceof String){
                return (Number.isInteger(parseFloat(product.discount_g.discount)) ? parseInt(parseFloat(product.discount_g.discount)) : (product.discount_g.discount).toFixed(2))+ "%";//parseInt
            }else{
                if(product.discount_g.discount){
                    return (Number.isInteger(product.discount_g.percentage) ? parseInt(product.discount_g.percentage) : (product.discount_g.percentage).toFixed(2))+"%";//Math.ceil
                }
            }
            return "%"
        },
        getOfferPrice(product){
            if(product.code_offer.type_discount == 1){
                return parseInt(product.code_offer.discount)+"%";
            }
            if(product.code_offer.type_discount == 2){
                return "$";
            }
            if(!product.code_offer.type_discount){
                return this.getDiscountPrice(product);
            }
            return "%"
        },
        getTagNew(product){
            if(product.tags_multiples.length > 0){
                return product.tags_multiples.find(item => item == '1') || product.tags_multiples.find(item => item == '3');
            }
            return false;
        },
        getNameTagNew(product){
            if(product.tags_multiples.length > 0){
                let tag_m = product.tags_multiples.find(item => item == '1');
                let tag_m_2 = product.tags_multiples.find(item => item == '3');
                if(tag_m || tag_m_2){
                    if(tag_m_2){
                        return "Reingreso";
                    }
                    if(tag_m){
                        return "Nuevo";
                    }
                }
            }
            return false;
        },
    },
    mounted(){
        // PARA EL CARRITO
        this.emitter.on('cart-shop',data => {
            this.CARTS = data;
        });
        this.emitter.emit("cart-list", {});

        //PARA LA LISTA DE DESEO
        this.emitter.on('wish-list-product',data => {
            this.WISHLIST = data;
        });
        this.emitter.emit("wish-list-list", {});
    }
}
</script>
<style>
    .bg-primary{
        border-radius: initial !important;
    }
    /* .v-progressive-image{
        height: 254px !important;
    } */
</style>