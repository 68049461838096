<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <!-- <div class="breadcrumb-wrap">
                <div class="banner">
                <img class="bg-img bg-top" src="/assets/images/bg/bg-header-001.jpg" alt="banner" />

                <div class="container-lg">
                    <div class="breadcrumb-box">
                    <div class="heading-box">
                        <h1>Oferta: {{ offer_selected ? offer_selected.title : ''}}</h1>
                    </div>
                    <ol class="breadcrumb">
                        <li><a href="/">Inicio</a></li>
                        <li>
                        <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                        </li>
                        <li class="current"><a href="shop-categories.html">Oferta</a></li>
                    </ol>
                    </div>
                </div>
                </div>
            </div> -->

            <div class="breadcrumb-wrap" v-if="offer_selected">
                <div class="banner2 banner-h" :style="{'background-image': 'url('+offer_selected.imagen_banner+')'}" style="background-color: #0076d5;">
                <div class="container-lg">
                    <div class="breadcrumb-box breadcrumb-box-oferta">
                    <div class="heading-box">
                        <h1 :style="{'color': offer_selected.state_color_text == 2 ? offer_selected.color_text : 'none'}">{{ offer_selected ? offer_selected.title : ''}}</h1>
                    </div>
                    <div class="decription-oferta container">
                            <p :style="{'color': offer_selected.state_color_text == 2 ? offer_selected.color_text : 'none'}">{{ offer_selected ? offer_selected.description : ''}}</p>
                        </div>
                    <div class="decription-oferta container" v-if="offer_selected.politica">
                       <p class="link-visit" :style="{'color': offer_selected.state_color_text == 2 ? offer_selected.color_text : 'none'}">Para más información sobre condiciones de estas ofertas visita <a :href="'/condiciones-de-ofertas?offer_id='+offer_selected.id" target="_blank">este enlace</a> </p> 
                    </div>    
                        
                    <!-- <ol class="breadcrumb">
                        <li><a href="/">Inicio</a></li>
                        <li>
                        <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                        </li>
                        <li class="current"><a href="/ofertas">Ofertas</a></li>
                    </ol> -->
                    </div>
                </div>
                </div>
            </div>

            <!-- Breadcrumb End -->
            <FilterProduct v-if="offer_id" :offer_id="offer_id" ></FilterProduct>
        </main>
        <!-- Main End -->

    </div>
</template>
<script>
import FilterProduct from './layouts/FilterProduct.vue'
import myApi from '../../plugins/axios'
import { useMeta } from 'vue-meta'

export default {
    name: 'FilterOffer',
    components:{
        FilterProduct,
    },
    data(){
        return {
            slug_offer: null,
            offer_id: null,
            offer_selected: null,
        }
    },
    methods: {
        async showOffers(){
            const resp = await myApi.get(
                "ecommerce/show_offer/" + this.slug_offer
            );
            console.log(resp);
            this.offer_id = resp.data.offer.id;
            this.offer_selected = resp.data.offer;
        },
        makeTitle(slug) {
            var words = slug.split('-');
            for (var i = 0; i < words.length; i++) {
                var word = words[i];
                words[i] = word.charAt(0).toUpperCase() + word.slice(1);
            }
            return words.join(' ');
        }
    },
    created(){
        this.slug_offer = this.$route.params.slug;
    },
    mounted(){
        let TITLE_OFERTA = this.makeTitle(this.slug_offer);
        this.useMetaT = useMeta({
            title: TITLE_OFERTA,
            htmlAttrs: { lang: 'en', amp: true },
            meta: [],
        })
        this.showOffers();
    }
}
</script>