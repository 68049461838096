<template>
    <div>
        <!-- Header Start -->
        <header class="">
            <div class="logo-center">
                <a href="/" class="logo-link">
                    <img class="logo logo-light" src="/assets/images/logos/logo-w.webp" alt="logo" />
                </a>
            </div>
        </header>
        <!-- Header End -->
        <!-- Main End -->
        <section class="form-checkout">
            <div class="progress-bar">
                <!-- :class="{'active': !loggedIn}" -->
                <div class="progress" id="progress"></div>
                <div class="progress-step active" >
                   <h6>1</h6>
                    <p>Cuenta</p> 
                </div>
                <!-- loggedIn -->
                <div class="progress-step " :class="{'active': type_nav >= 2}">
                    <h6>2</h6>
                    <p>Información</p> 
                </div>
                <div class="progress-step" data-title="Contact " :class="{'active': type_nav >= 3}">
                     <h6>3</h6>
                    <p>Entrega</p> 
                </div>
                <div class="progress-step" data-title="Experiences" :class="{'active': type_nav >= 4}">
                    <h6>4</h6>
                    <p>Pago</p> 
                </div>
            </div>
            <form method="post" class="form">
                <!-- Progress Bar -->



                <!-- Steps !loggedIn-->

                <div class="form-step " :class="{'active': type_nav == 1}">
                    <ul class="tab-menu" id="tab-menu3">
                        <li><a href="#tab-content66" class="active">  <img src="/assets/images/checkout/User Check.svg" alt=""> Iniciar sesión</a></li>
                        <li><a href="#tab-content76"> <img src="/assets/images/checkout/User Plus.svg" alt=""> Crear cuenta</a></li>
                        <li><a href="#tab-content8"> <img src="/assets/images/checkout/User Cross.svg" alt=""> Modo invitado</a></li>
                    </ul>

                    <div id="tab-content66" class="tab-content active">
                        <p class="subtitle">Si ya posees una cuenta, por favor ingresa tu correo electrónico y contraseña
                        </p>
                        <div class="input-group">
                            <label>Correo electrónico</label>
                            <input type="email" v-model="email_login">
                        </div>

                        <div class="input-group">
                            <label for="phone">Contraseña</label>
                            <input type="password" v-model="password_login">

                        </div>


                        <div class="btn-sesion">
                            <div class="loader-5 center" v-if="loading"><span></span></div>
                            <a class="btn " @click="Login()" v-if="!loading">Iniciar sesión</a>
                        </div>
                    </div>
                    <div id="tab-content76" class="tab-content">
                        <p class="subtitle">Tomate un minuto para crear tu cuenta y disfrutar de muchos beneficios

                        </p>

                        <div class="input-group">
                            <label>Nombres</label>
                            <input type="text" v-model="name_register">
                        </div>
                        <div class="input-group">
                            <label>Apellidos</label>
                            <input type="text" v-model="surname_register">
                        </div>
                        <div class="input-group">
                            <label>Correo electrónico</label>
                            <input type="email" v-model="email_register">
                        </div>

                        <div class="input-group">
                            <label for="phone">Contraseña</label>
                            <input type="password" v-model="password_register">

                        </div>




                        <div class="btn-sesion">
                            <div class="loader-5 center" v-if="loading"><span></span></div>
                            <a class="btn " @click="Register()" v-if="!loading">Registrarme</a>
                        </div>

                    </div>

                    <div id="tab-content8" class="tab-content">
                        <p class="subtitle">Finaliza tu compra sin necesidad de crear una cuenta</p>


                        <div class="btn-center">
                            <a class="btn btn-next" @click="type_nav = 2">Continuar como invitado</a>
                        </div>
                    </div>

                </div>
                <!-- :class="{'active': loggedIn}" -->
                <div class="form-step" :class="{'active': type_nav == 2}">
                    <!-- <p class="d-block d-sm-none"> Información del cliente</p> -->
                    <div class="" :class="{'d-none': loggedIn}">
                        <div class="input-flex">
                            <div class="input-group">
                                <label for="full-name">Nombre Completo</label>
                                <input type="text" v-model="client_guest.full_name" name="full-name" id="full-name">
                            </div>

                            <div class="input-group">
                                <label for="email">Correo electrónico</label>
                                <input type="email" v-model="client_guest.email" name="email" id="email">
                            </div>

                            <div class="input-group">
                                <label for="phone">Número de teléfono</label>
                                <input type="number" v-model="client_guest.phone" name="phone" maxlength="9" id="phone" placeholer="99999999">
                            </div>

                            <div class="input-group">
                                <label for="phone">DUI:</label>
                                <input type="text" name="dui" @input="applyMask" maxlength="10" id="dui"  v-model="client_guest.dui" placeholer="">
                            </div>

                            <div class="message-alert message-alert-checkout" v-if="PHONE_ERROR_TEXT">
                                <span class="close-button" @click="PHONE_ERROR_TEXT = null">&times;</span>
                                <p> {{ PHONE_ERROR_TEXT }}</p>
                            </div>
                            <div class="message-alert message-alert-checkout" v-if="DUI_ERROR_TEXT">
                                <span class="close-button" @click="DUI_ERROR_TEXT = null">&times;</span>
                                <p> {{ DUI_ERROR_TEXT }}</p>
                            </div>
                            
                        </div>
            
                        <!-- <div class="input-flex">
                            <div class="input-group">
                                <label>Departamento Factura:</label>
                                <select id="dpto" name="departamento"  @change="changeLocation" v-model="client_guest.departament_factura">
                                     <template
                                        v-for="(DEPARTAMENTO, index) in S_DEPARTAMENTOS"
                                        :key="index"
                                    >
                                        <option :value="DEPARTAMENTO.name">{{DEPARTAMENTO.name}}</option>
                                    </template>
                                </select>
                            </div>

                            <div class="input-group">
                                <label>Municipio Factura:</label>
                                <select id="municipio" name="municipio" v-model="client_guest.municipality_factura">
                                    <template
                                        v-for="(MUNICIPIO, index) in S_MUNICIPIOS"
                                        :key="index"
                                    >
                                        <option :value="MUNICIPIO.city">{{MUNICIPIO.city}}</option>
                                    </template>
                                </select>
                            </div>
                        </div> -->
                        <!-- <div class="input-group">
                            <label>Dirección Completa*
                            </label>
                            <input type="text" v-model="client_guest.address_factura">
                        </div> -->
                    </div>

                    <div class="bg-info2" v-if="loggedIn">
                        <!-- <h5 class="subtitle-t">Información del cliente</h5> -->
                        <ul>
                            <li> <span>Nombre:</span> {{ client_guest.full_name }}</li>
                            <li> <span>Correo:</span> {{ client_guest.email }}</li>
                            <li v-if="client_guest_phone_initial"> <span>Teléfono:</span> {{ client_guest.phone }}</li>
                            <li v-if="client_guest_dui_initial"> <span>DUI:</span> {{ client_guest.dui }}</li>
                        </ul>
                        <div class="input-group" v-if="!client_guest_phone_initial">
                            <label for="phone">Número de teléfono: </label>
                            <input type="number" class="w-50"  maxlength="9" style="border: 1px solid #fbfbfb !important;" name="phone" id="phone" placeholer="" v-model="client_guest.phone">
                            <div class="message-alert message-alert-checkout" v-if="PHONE_ERROR_TEXT">
                                <span class="close-button" @click="PHONE_ERROR_TEXT = null">&times;</span>
                                <p> {{ PHONE_ERROR_TEXT }}</p>
                            </div>
                        </div>

                        <div class="input-group" v-if="!client_guest_dui_initial">
                            <label for="phone">DUI:: </label>
                            <input type="text" class="w-50"  maxlength="10" @input="applyMask" style="border: 1px solid #fbfbfb !important;" name="dui" id="dui" placeholer="" v-model="client_guest.dui">
                            <div class="message-alert message-alert-checkout" v-if="DUI_ERROR_TEXT">
                                <span class="close-button" @click="DUI_ERROR_TEXT = null">&times;</span>
                                <p> {{ DUI_ERROR_TEXT }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="gps-flex" v-show="mapa_error">
                        <img src="/assets/images/checkout/marcador.png" alt="">
                        <p>Si tu ubicacion es la del envio, se recomienda encender tu GPS para obtener una
                            ubicación más precisa.
                        </p>
                    </div>
                    <!-- <div class="btn-gps">
                        <button> <a class="btn btn-green">Activar GPS</a></button>
                    </div> -->

                    <!-- <div class="input-group" v-if="loggedIn">
                        <label>Dirección:</label>
                        <select id="addre" name="address" @change="changeAddress">
                            <option value="">-- Selecciona una dirección--</option>
                            <template v-for="(ADDRE,index) in ADDRESS" :key="index">
                                <option :value="ADDRE.id">{{ADDRE.title}}</option>
                            </template>
                        </select>
                    </div> @click="new_direction = !new_direction" Enviar a otra ubicación :class="{'d-none': !new_direction}" -->

                    <div class="input-group">
                        <label for="phone">Comentarios Adicional de tu orden: (Opcional)</label>
                        <textarea name="" id="" cols="30" rows="4" v-model="client_guest.description"></textarea>
                    </div>


                    <div class="input-1">
                        <p>
                            <label>¿Quiere Crédito Fiscal de la compra?&nbsp;<span class="optional">(opcional)</span>
                            </label>
                        </p>

                        <div class="switch">
                            <input type="checkbox" id="switchID" @click="is_credito_fiscal = !is_credito_fiscal">
                            <label for="switchID">
                                <span class="switch-text switch-text-on" >Sí</span>
                                <span class="switch-text switch-text-off">No</span>
                                <div class="toggle"></div>
                            </label>
                        </div>

                        <div id="camposOpcion1" class="input group hide">
                            <div class="input-flex">
                                <label>NIT: </label>
                                <input type="text" placeholder="0000-000000-000-0" @input="updateMaskNit" v-model="client_guest.nit" >
                                <label>NRC:</label>
                                <input type="text" placeholder="000000-0" @input="updateMaskNrc" v-model="client_guest.nrc" >
                                <label>Giro:</label>
                                <input type="text" maxlength="200" v-model="client_guest.giro" >
                            </div>
                            <div class="message-alert message-alert-checkout" v-if="NIT_NRC_ERROR_TEXT">
                                <span class="close-button" @click="NIT_NRC_ERROR_TEXT = null">&times;</span>
                                <p> {{ NIT_NRC_ERROR_TEXT }}</p>
                            </div>
                            
                            <!-- <button class="collapcheckout" onclick="return false;" >Información de facturación: </button> -->
    
                            <div class="">
                                <div class="input-flex">
                                    <div class="input-group">
                                        <label>Departamento:</label>
                                        <select id="dpto" name="departamento" @change="changeLocation" v-model="client_guest.departament_factura">
                                            <option selected disabled value="">Selecciona...</option>
                                            <template
                                                v-for="(DEPARTAMENTO, index) in S_DEPARTAMENTOS"
                                                :key="index"
                                            >
                                                <option :value="DEPARTAMENTO.name">{{DEPARTAMENTO.name}}</option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="input-group">
                                        <label>Municipio:</label>
                                        <select id="municipio" name="municipio" v-model="client_guest.municipality_factura">
                                            <option selected disabled value="">Choose...</option>
                                            <template
                                                v-for="(MUNICIPIO, index) in S_MUNICIPIOS"
                                                :key="index"
                                            >
                                                <option :value="MUNICIPIO.city">{{MUNICIPIO.city}}</option>
                                            </template>
                                        </select>
                                    </div>
                                </div>
                                <div class="input-group">
                                    <label>Dirección Completa*
                                    </label>
                                    <input type="text" v-model="client_guest.address_factura">
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="btn-group py-4">
                        <!-- :class="{'btn-prev': !loggedIn}" -->
                        <a class="btn btn-prev" v-if="!loggedIn" @click="type_nav = 1">
                            Volver</a>
                        <router-link to="/cart" class="btn btn-prev" v-if="loggedIn">
                            Carrito
                        </router-link>
                        <!-- <a class="btn btn-prev disabled" v-if="loggedIn">
                            Volver
                        </a> -->
                        <a class="btn btn-next" @click="nextInfoDelivery()">Siguiente</a>
                    </div>
                </div>
                
                <div class="form-step " :class="{'active': type_nav == 3}">
                    <!-- <p class="d-block d-sm-none"> Información de entrega</p> -->
                    <div class="tab-container">
                        <ul class="tab-menu" id="tab-menu2">
                            <li><a href="#tab-content3" class="active" @click="selectedTypeEntrega(1)"> 
                                        <img src="/assets/images/checkout/Map Point.svg"
                                    alt=""> Recoger en Sucursal</a></li>
                            <li><a href="#tab-content4" @click="selectedTypeEntrega(2)"> <img src="/assets/images/checkout/Delivery.svg" alt=""> Envio a
                                    domicilio</a></li>
                        </ul>
                        <div id="tab-content3" :class="{'active': type_entrega == 1}" class="tab-content">
                            <h2>Selecciona una sucursal</h2>
                            <div class="sucursales-flex1" v-if="loadingSucursal && !SucursalEmpty">
                                <!-- 
                                 -->
                                <label v-if="white && isAvalibleSucursal('San Salvador')" :class="{'sucursales-checked': delivery_information.sucursal == 'San Salvador'}">
                                    <input type="checkbox" name="sucursal" value="metrocentro" @click="selectedTypeSucursal('San Salvador')">
                                    <img src="/assets/logos/checkout/s-metrocentro-light.webp" alt="San Salvador"
                                        title="San Salvador">
                                    <p>San Salvador</p>
                                </label>

                                <label v-if="white && isAvalibleSucursal('Plaza Merliot')" :class="{'sucursales-checked': delivery_information.sucursal == 'Plaza Merliot'}">
                                    <input type="checkbox" name="sucursal" value="plaza-merliot" @click="selectedTypeSucursal('Plaza Merliot')">
                                    <img src="/assets/logos/checkout/s-plazamerliot-light.webp" alt="Plaza Merliot"
                                        title="Plaza Merliot">
                                    <p>Plaza Merliot</p>
                                </label>

                                <label v-if="white && isAvalibleSucursal('Plaza Mundo')" :class="{'sucursales-checked': delivery_information.sucursal == 'Plaza Mundo'}">
                                    <input type="checkbox" name="sucursal" value="plaza-mundo" @click="selectedTypeSucursal('Plaza Mundo')">
                                    <img src="/assets/logos/checkout/s-plazamundo-light.webp" alt="Plaza Mundo"
                                        title="Plaza Mundo">
                                    <p>Plaza Mundo</p>
                                </label>

                                <label v-if="white && isAvalibleSucursal('San Miguel')" :class="{'sucursales-checked': delivery_information.sucursal == 'San Miguel'}">
                                    <input type="checkbox" name="sucursal" value="san-miguel" @click="selectedTypeSucursal('San Miguel')">
                                    <img src="/assets/logos/checkout/s-metrocentro-light.webp" alt="San Miguel"
                                        title="San Miguel">
                                    <p>San Miguel</p>
                                </label>

                                <!-- <label v-if="white && isAvalibleSucursal('Warehouse Matrix')">
                                    <input type="checkbox" name="sucursal" value="bodega" @click="selectedTypeSucursal('Warehouse Matrix')">
                                    <img src="/assets/logos/checkout/logos-bodega-light.png" alt="Bodega"
                                        title="Bodega">
                                    <p>Bodega</p>
                                </label> -->

                                <!--  -->
                                <label v-if="dark && isAvalibleSucursal('San Salvador')" :class="{'sucursales-checked': delivery_information.sucursal == 'San Salvador'}">
                                    <input type="checkbox" name="sucursal" value="metrocentro" @click="selectedTypeSucursal('San Salvador')">
                                    <img src="/assets/logos/checkout/s-metrocentro-dark.webp" alt="San Salvador"
                                        title="San Salvador">
                                    <p>San Salvador</p>
                                </label>

                                <label v-if="dark && isAvalibleSucursal('Plaza Merliot')" :class="{'sucursales-checked': delivery_information.sucursal == 'Plaza Merliot'}">
                                    <input type="checkbox" name="sucursal" value="plaza-merliot" @click="selectedTypeSucursal('Plaza Merliot')">
                                    <img src="/assets/logos/checkout/s-plazamerliot-dark.webp" alt="Plaza Merliot"
                                        title="Plaza Merliot">
                                    <p>Plaza Merliot</p>
                                </label>

                                <label v-if="dark && isAvalibleSucursal('Plaza Mundo')" :class="{'sucursales-checked': delivery_information.sucursal == 'Plaza Mundo'}">
                                    <input type="checkbox" name="sucursal" value="plaza-mundo" @click="selectedTypeSucursal('Plaza Mundo')">
                                    <img src="/assets/logos/checkout/s-plazamundo-dark.webp" alt="Plaza Mundo"
                                        title="Plaza Mundo">
                                    <p>Plaza Mundo</p>
                                </label>

                                <label v-if="dark && isAvalibleSucursal('San Miguel')" :class="{'sucursales-checked': delivery_information.sucursal == 'San Miguel'}">
                                    <input type="checkbox" name="sucursal" value="metrocentro" @click="selectedTypeSucursal('San Miguel')">
                                    <img src="/assets/logos/checkout/s-metrocentro-dark.webp" alt="San Miguel"
                                        title="San Miguel">
                                    <p>San Miguel</p>
                                </label>
                                
                                <!-- <label v-if="dark && isAvalibleSucursal('Warehouse Matrix')">
                                    <input type="checkbox" name="sucursal" value="bodega" @click="selectedTypeSucursal('Warehouse Matrix')">
                                    <img src="/assets/logos/checkout/logos-bodega-dark.png" alt="Bodega"
                                        title="Bodega">
                                    <p>Bodega</p>
                                </label> -->

                            </div>
                            <div class="sucursales-flex1 mt-1" v-if="!loadingSucursal && !SucursalEmpty">
                                <img src="/assets/svg/loading-store.gif" style="height: 250px;" alt="" srcset="">
                            </div>
                            <div class="sucursales-flex1 mt-1" v-if="loadingSucursal && SucursalEmpty">
                                <img src="/assets/svg/outofstock-store.svg" style="height: 250px;" alt="" srcset="">
                            </div>
                            
                            <div class="message-alert message-alert-checkout" v-if="SUCURSAL_ERROR_TEXT">
                                <span class="close-button" @click="SUCURSAL_ERROR_TEXT = null">&times;</span>
                                <p> {{ SUCURSAL_ERROR_TEXT }}</p>
                            </div>
                        </div>
                        <div id="tab-content4" :class="{'active': type_entrega == 2}" class="tab-content">
                            <h2>Dirección de Envío</h2>

                            <div class="input-group" v-if="loggedIn" >
                                <label>Dirección:</label>
                                <select id="addres" name="address" @change="changeAddress">
                                    <option value="">-- Selecciona una dirección--</option>
                                    <template v-for="(ADDRE,index) in ADDRESS" :key="index">
                                        <option :value="ADDRE.id">{{ADDRE.title}}</option>
                                    </template>
                                </select>
                            </div>

                            <div class="message-alert message-alert-checkout" v-if="SUCURSAL_ERROR_TEXT">
                                <span class="close-button" @click="SUCURSAL_ERROR_TEXT = null">&times;</span>
                                <p> {{ SUCURSAL_ERROR_TEXT }}</p>
                            </div>

                            <button class="collapcheckout"  v-if="loggedIn && !ADDRES_SELECTED" onclick="return false;" @click="resetAddress">Enviar a otra ubicación</button>

                            <div class="bg-info2" v-if="ADDRES_SELECTED">
                                <h5 class="subtitle-t">Información de la dirección</h5>
                                <ul>
                                    <li> <span>Departamento:</span> {{ client_guest.departament }}</li>
                                    <li> <span>Municipio:</span> {{ client_guest.municipality }}</li>
                                </ul>
                                <div class="input-group">
                                    <label for="phone">Dirección Completa: {{ client_guest.address }}</label>
                                </div>
                                <div class="input-group">
                                    <label for="phone">Puntos de referencia: {{ client_guest.point_reference }}</label>
                                </div>
                                <div class="my-3">
                                    <div class="google-map" ref="googleMap" style="height: 300px"></div>
                                </div>
                            </div>
                            <div class="" v-if="(!ADDRES_SELECTED && OPTION_SELECTED) || !loggedIn">
                                <div class="input-flex">
                                    <div class="input-group">
                                        <label>Departamento:</label>
                                        <select id="dpto" name="departamento" @change="changeLocation" v-model="client_guest.departament">
                                            <option selected disabled value="">Selecciona...</option>
                                            <template
                                                v-for="(DEPARTAMENTO, index) in S_DEPARTAMENTOS"
                                                :key="index"
                                            >
                                                <option :value="DEPARTAMENTO.name">{{DEPARTAMENTO.name}}</option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="input-group">
                                        <label>Municipio:</label>
                                        <select id="municipio" name="municipio" v-model="client_guest.municipality">
                                            <option selected disabled value="">Selecciona...</option>
                                            <template
                                                v-for="(MUNICIPIO, index) in S_MUNICIPIOS"
                                                :key="index"
                                            >
                                                <option :value="MUNICIPIO.city">{{MUNICIPIO.city}}</option>
                                            </template>
                                        </select>
                                    </div>
                                </div>
                                <div class="input-group">
                                    <label>Dirección Completa*
                                    </label>
                                    <input type="text" v-model="client_guest.address">
                                </div>
                                <div class="input-group">
                                    <label>Puntos de referencia
                                    </label>
                                    <input type="text" v-model="client_guest.point_reference">
                                </div>
                                <div class="my-3">
                                    <p class="alert-valido">Permite acceder a la ubicación GPS de tu dispositivo. Actualiza la página si es necesario para obtener tu posición actual. Puedes ampliar el mapa y hacer clic para ajustar tu ubicación según sea necesario.</p>
                                    <div class="google-map" ref="googleMap" style="height: 300px"></div>
                                </div>
                                <div class=" input-group" v-if="!ADDRES_SELECTED && loggedIn">
                                    <label>Puedes guardar la dirección para futuros usos
                                    </label>
                                    <div class="input-flex2">
                                        <input type="text" name="coupon" class="form-control3 direcc-input" v-model="title" placeholder="Nombre de la ubicación">
                                        <button type="button" @click="SaveAddress()" class="btn-green">GUARDAR</button>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="input-group">
                                <label>Dirección:</label>
                                <input type="text">
                            </div>

                            <div class="input-group">
                                <label>Punto de Referencia:</label>
                                <input type="text">
                            </div>

                            <div class="gps-flex">
                                <img src="/assets/images/checkout/marcador.png" alt="">
                                <p>Si tu ubicacion es la del envio, se recomienda encender tu GPS para obtener una
                                    ubicación más precisa.
                                </p>

                            </div>
                            <div class="btn-gps">
                                <button> <a class="btn btn-green">Activar GPS</a></button>
                            </div>

                            <div class="ubicacion">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1056.798924578466!2d-89.2184058503832!3d13.696844869173296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f6327a659640657%3A0x6f9a16eb98854832!2sEl%20Salvador!5e0!3m2!1ses-419!2sve!4v1689621304744!5m2!1ses-419!2sve"
                                    width="900" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div> -->
                        </div>
                    </div>

                    <div class="btn-group">
                        <a class="btn btn-prev" href="#" onclick="return false" @click="type_nav = 2">Volver </a>
                        <a class="btn btn-next" @click="nextInfoEntrega()">Siguiente</a>
                    </div>
                </div>

                <div class="form-step" :class="{'active': type_nav == 4}">
                    <!-- <p class="d-block d-sm-none"> Información de pago</p> -->
                    <ul class="tab-menu" id="tab-menupay">
                        <li><a href="#tab-content1a" class="active" @click="selectTypePayment(2)"> 
                            <img src="/assets/images/checkout/Card.svg"
                                    alt="">
                            Pago con tarjeta</a></li>
                        <li><a href="#tab-content3a" @click="selectTypePayment(3)">
                            <img src="/assets/images/Nuevos/paypal.svg"
                                    alt="">
                                    Paypal</a></li>
                        <li><a href="#tab-content2a" @click="selectTypePayment(1)"> 
                            <img src="/assets/images/checkout/transferencia.svg"
                                    alt="">
                            Transferencia</a></li>

                        <li v-if="loggedIn && orders_count > 2"><a href="#tab-content4a" @click="selectTypePayment(4)"> 
                            <img src="/assets/svg/cash-on-delivery-w.svg"
                                    alt="">
                            Contra-Entrega</a></li>
                    </ul>

                    <div class="tab-container">
                        <div id="tab-content1a" :class="{'active' : type_payment == 2}" class="tab-content">
                            <!-- <div class="mb-3">
                                <ul class="tab-menu">
                                    <li><a href="#" onclick="return false;" :class="{'active': selected_type_pay == 1}" @click="selectTypePay(1)">Pago completo</a></li>
                                    <li><a href="#" onclick="return false;" :class="{'active': selected_type_pay == 2}" @click="selectTypePay(2)">Pago cuotas</a></li>
                                </ul>
                            </div>

                            <div class="mb-3" v-if="selected_type_pay == 2">
                                <ul class="tab-menu">
                                    <li><a href="#" onclick="return false;" :class="{'active': selected_type_pay_cuotas == 1}" @click="selectTypePayCuotas(1)">3 Plazos</a></li>
                                    <li><a href="#" onclick="return false;" :class="{'active': selected_type_pay_cuotas == 2}" @click="selectTypePayCuotas(2)">6 Plazos</a></li>
                                    <li><a href="#" onclick="return false;" :class="{'active': selected_type_pay_cuotas == 3}" @click="selectTypePayCuotas(3)">9 Plazos</a></li>
                                    <li><a href="#" onclick="return false;" :class="{'active': selected_type_pay_cuotas == 4}" @click="selectTypePayCuotas(4)">12 Plazos</a></li>
                                </ul>
                            </div> -->

                            <div class="pago-flex">

                                <div class="form-pago">
                                    <div>
                                        <div class="box-cuotas">
                                            <div class="flex-cuota">
                                                <div>

                                                    <img src="/assets/images/checkout/america.png" alt="">

                                                    <li> Pagar en Cuotas </li>
                                                </div>

                                                <div class="switch">
                                                    <input type="checkbox" id="switchID1" @change="selectTypePay($event.target.checked)">
                                                    <label for="switchID1">
                                                        <span class="switch-text switch-text-on">Sí</span>
                                                        <span class="switch-text switch-text-off">No</span>
                                                        <div class="toggle"></div>
                                                    </label>
                                                </div>

                                            </div>
                                            <br>

                                            <br> Disponible únicamente para pagos con tarjetas
                                                de crédito de Banco de América Central
                                            <br><br>

                                            <div id="additionalDiv" class="additional-div">
                                                <select name="selected_type_pay_cuotas" id="" v-model="selected_type_pay_cuotas">
                                                    <option value="1">3 meses</option>
                                                    <option value="2">6 meses</option>
                                                    <option value="3">9 meses</option>
                                                    <option value="4">12 meses</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="credit-card-box">
                                        <div class="flip">
                                            <div class="front">
                                                <div class="chip"></div>

                                                <div class="number">{{ number_card.length > 0 ? number_card : '0000 0000 0000 0000'  }}</div>
                                                <div class="card-holder">
                                                    <label>Nombre</label>
                                                    <div>{{ full_name_card }}</div>
                                                </div>

                                                <div class="card-visa" v-if="is_visa && is_visa == 1">
                                                    <img src="/assets/images/checkout/pagos/Logo-tarjeta-Visa.png" alt="">
                                                </div>

                                                <div class="card-visa" v-if="is_visa && is_visa == 2">
                                                    <img src="/assets/images/checkout/pagos/Logo-tarjeta-Mastercard.png" alt="">
                                                </div>

                                                <div class="card-expiration-date">
                                                    <label>Expira</label>
                                                    <div>{{month_card}}/{{ year_card }}</div>
                                                </div>
                                            </div>
                                            <div class="back">
                                                <div class="strip"></div>

                                                <div class="ccv">
                                                    <label>CVC</label>
                                                    <div>{{ cvv_card }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="input-group">
                                        <!-- oninput="aplicarMascara(this)"  -->
                                        <label>Número de Tarjeta</label>
                                        <input type="text" @keypress="isNumber($event)" placeholder="xxxx xxxx xxxx xxxx" v-model="number_card" @keyup="digitalCard($event.target.value)">
                                    </div>

                                    <div class="input-group">
                                        <label>Nombre de la tarjeta</label>
                                        <input type="text" maxlength="20" @keypress="isStringT($event)" @keyup="fullNameCard($event.target.value)">
                                    </div>

                                    <div class="input-flex">

                                        <div class="input-group">
                                            <label>Mes</label>
                                            <select name="mes" @change="MonthCard($event.target.value)">
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                                <option value="04">04</option>
                                                <option value="05">05</option>
                                                <option value="06">06</option>
                                                <option value="07">07</option>
                                                <option value="08">08</option>
                                                <option value="09">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>


                                        <div class="input-group">
                                            <label for="">Año</label>
                                            <select name="Año" @change="YearCard($event.target.value)">
                                                <template v-for="(year_prop, index) in year_propies" :key="index">
                                                    <option :value="year_prop.value" :selected="year_card == year_prop.value">{{ year_prop.year }}</option>
                                                </template>
                                                <!-- <option value="20">2020</option>
                                                <option value="21">2021</option>
                                                <option value="22">2022</option>
                                                <option value="23">2023</option> -->

                                            </select>
                                        </div>


                                    </div>
                                    <div class="input-group">
                                        <label>CVC</label>
                                        <input type="password" inputmode="numeric" placeholder="XXX" v-model="cvv_card" @keyup="cvvCard($event.target.value)">
                                    </div>
                                    <img src="/assets/images/checkout/pagos/Pago-con-tarjeta-mode-white.png" v-if="white" alt="">
                                    <img src="/assets/images/checkout/pagos/Pago-con-tarjeta-mode-dark.png" v-if="dark" alt="">
                                </div>

                                <div class="resumen">
                                    <div class="summery-wrap">
                                        <div class="cart-wrap grand-total-wrap">
                                            <h3>Resumen de la Orden</h3>
                                            <div>
                                                <div class="order-summery-box">

                                                    <ul class="order-summery">
                                                        <!---->
                                                        <li class="pb-0"><span>Subtotal </span><span>$
                                                                {{ (TOTAL_SUM).toFixed(2) }}</span></li>

                                                        <li class="pb-0" v-if="type_entrega == 2"><span>Envío </span>
                                                            
                                                            <span v-if="is_free == 2">GRATIS</span>
                                                            <span v-if="is_free == 1">
                                                                {{ tax == 0 ? 'GRATIS' : '$ '+tax }}</span>
                                                            
                                                            </li>

                                                    </ul>


                                                    <ul class="order-summery">
                                                        <!-- <li class="pb-0"><span>Descuento </span><span>10%</span></li> -->
                                                    </ul>

                                                    <p class="price-total">Total a Pagar <span class="size-total">${{ (TOTAL_SUM + (isTaxP() ? tax : 0)).toFixed(2)  }}</span> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div id="tab-content2a" :class="{'active' : type_payment == 1}" class="tab-content">
                            <div class="pago-flex">
                                <div class="form-pago">

                                    <div class="alert alert-info alert-with-icon" role="alert">
                                            <div class="aviso-flex">
                                                <div class="alert-icon-box">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-alert-triangle alert-icon">
                                                        <path
                                                            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z">
                                                        </path>
                                                        <line x1="12" y1="9" x2="12" y2="13"></line>
                                                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                                    </svg>
                                                </div>
                                                <div><b><i class="fa fa-warning fa-lg"></i>Importante</b></div>
                                            </div>


                                            <div> 1. Realizar la transferencia desde la plataforma del banco de su preferencia. <br>
                                                2. Elija una de las siguientes cuentas a donde hará el depósito: </div>
                                            <!-- <ul>
                                                <template v-for="(cuenta, index) in cuentas" :key="index">
                                                    <li>
                                                        <button type="button" onclick="return false;" @click="copyTextAccount(cuenta.n_cuenta)"> <img src="/assets/images/checkout/copiar.png"
                                                                style="width: 18px;"></button> &nbsp;Banco
                                                        {{ cuenta.banco }} - No Cuenta: {{ cuenta.n_cuenta }}
                                                    </li>
                                                </template>
                                            </ul> -->
                                            3. Adjuntar captura de comprobante de Pago
                                        </div>

                                    <h2>Selecciona una cuenta bancaria:</h2>


                                    <template v-for="(cuenta, index) in cuentas" :key="index">
                                        <div class="box-select">
                                            <div class="flex-cuenta">
                                                <input type="checkbox" :id="'banco'+cuenta.id" @click="selectedTypeCuentaBancaria(cuenta.id)" name="banco" value="201328200">
                                                <label :for="'banco'+cuenta.id" class="inside">
    
                                                    <img v-if="cuenta.id == 2" src="/assets/images/checkout/america.png" alt="">
                                                    <img v-if="cuenta.id == 1" src="/assets/images/checkout/cuscatlan.png" alt="">
                                                    <img v-if="cuenta.id == 3" src="/assets/images/checkout/agricola.png" alt="">
                                                    <img v-if="cuenta.id == 4" src="/assets/images/checkout/promerica.webp" alt="">
    
                                                    <li>  {{cuenta.banco}}</li>
                                                    <br>
                                                    <div class="flex-cuenta">
                                                        <button class="blue-option" type="button" onclick="return false;" @click="copyTextAccount(cuenta.n_cuenta)"> 
                                                            <div>
                                                                <img src="/assets/images/checkout/copiar.png"
                                                                    style="width: 18px;"> 
                                                            </div>
                                                            <div>
                                                                {{cuenta.n_cuenta}} &nbsp; ZD, SA de CV &nbsp; Cuenta Corriente
                                                            </div>    
                                                        </button>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="input-flex">
                                        <div class="input-group">
                                            <label>Titular Cuenta:</label>
                                            <input type="text" maxlength="250"  @keypress="isStringT($event)" v-model="payment_info.account_holder">
                                        </div>

                                        <div class="input-group">
                                            <label>Concepto:</label>
                                            <input type="text" maxlength="250"  @keypress="isStringAlfaNumer($event)" v-model="payment_info.concept">
                                        </div>
                                    </div>

                                    <div class="input-flex">
                                        <div class="input-group">
                                            <label>Número de referencia:</label>
                                            <input type="number" v-model="payment_info.reference_number">
                                        </div>

                                        <div class="input-group">
                                            <label>Monto:</label>
                                            <input type="number" v-model="payment_info.amount">
                                        </div>
                                    </div>

                                    <div class="input-group">
                                        <label>Comprobante de Depósito: (jpg,jpeg, png, webp y pdf) 5MB</label>
                                        <input type="file" accept=".jpg, .jpeg, .png, .webp, .pdf" @change="onFileChange">
                                    </div>
                                </div>

                                <div class="resumen">
                                    <div class="summery-wrap">
                                        <div class="cart-wrap grand-total-wrap">
                                            <h3>Resumen de la Orden</h3>
                                            <div>
                                                <div class="order-summery-box">

                                                    <ul class="order-summery">
                                                        <!---->
                                                        <li class="pb-0"><span>Subtotal </span><span>$
                                                                {{ (TOTAL_SUM).toFixed(2) }}</span></li>

                                                        <li class="pb-0" v-if="type_entrega == 2"><span>Envío </span>
                                                            <span v-if="is_free == 2">GRATIS</span>
                                                                <span v-if="is_free == 1">
                                                                {{ tax == 0 ? 'GRATIS' : '$ '+tax }}</span>
                                                        </li>

                                                    </ul>

                                                    <!-- <ul class="order-summery">
                                                        <li class="pb-0"><span>Descuento </span><span>10%</span></li>
                                                    </ul> -->

                                                    <p class="price-total">Total a Pagar <span class="size-total">${{ (TOTAL_SUM + (isTaxP() ? tax : 0)).toFixed(2) }}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div id="tab-content3a" :class="{'active' : type_payment == 3}" class="tab-content">
                            <div class="pago-flex">
                                <div class="form-pago">
                                    <div :class="{'d-none': !acepte_terminos}">
                                        <PaypalButtons :reference="reference" 
                                        @onApproveR="onApproveR"
                                        @onErrorR="onErrorR"
                                        />
                                    </div>
                                    <div>
                                        <div class="d-flex align-items-center">
                                            <input type="checkbox" id="acepte_terminos" @click="acepte_terminos = acepte_terminos ? null: 1" :checked="acepte_terminos" name="acepte_terminos">
                                            <label for="acepte_terminos" class="inside mb-0 mx-2">
                                                Acepto los <a href="#" data-bs-toggle="modal" data-bs-target="#viewTerminosPages"  onclick="return false;" class="text-primary" target="_blank">términos y condiciones de pago</a> , 
                                                <a href="#" data-bs-toggle="modal" data-bs-target="#viewEntregasEnviosPages"  onclick="return false;" class="text-primary" target="_blank">envío</a>  y 
                                                <a href="#" data-bs-toggle="modal" data-bs-target="#viewPloticaGarantiaPages" class="text-primary" target="_blank">garantías</a>  para empezar con Paypal
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="resumen">
                                    <div class="summery-wrap">
                                        <div class="cart-wrap grand-total-wrap">
                                            <h3>Resumen de la Orden</h3>
                                            <div>
                                                <div class="order-summery-box">

                                                    <ul class="order-summery">
                                                        <!---->
                                                        <li class="pb-0"><span>Subtotal </span><span>$
                                                                {{ (TOTAL_SUM).toFixed(2) }}</span></li>

                                                        <li class="pb-0" v-if="type_entrega == 2"><span>Envío </span>
                                                            <span v-if="is_free == 2">GRATIS</span>
                                                            <span v-if="is_free == 1">
                                                                {{ tax == 0 ? 'GRATIS' : '$ '+tax }}</span>
                                                            </li>

                                                        <li class="pb-0" ><span>Comisión Paypal: </span>
                                                            <span> ${{ comisionPaypal().toFixed(2) }} </span>
                                                        </li>
                                                    </ul>

                                                    <p class="price-total">Total a Pagar <span class="size-total">${{ (TOTAL_SUM + (isTaxP() ? tax : 0) + comisionPaypal()).toFixed(2) }}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="tab-content4a" :class="{'active' : type_payment == 4}" class="tab-content">
                            <div class="pago-flex">
                                <div class="form-pago">

                                    <!-- <div class="alert alert-info alert-with-icon" role="alert">
                                        <div class="aviso-flex">
                                            <div class="alert-icon-box">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-alert-triangle alert-icon">
                                                    <path
                                                        d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z">
                                                    </path>
                                                    <line x1="12" y1="9" x2="12" y2="13"></line>
                                                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                                </svg>
                                            </div>
                                            <div><b><i class="fa fa-warning fa-lg"></i>Importante</b></div>
                                        </div>
                                        <div>
                                            1. Elija una de las siguientes opciones para pago. 
                                            <br>
                                            2. Llene la información solicitada.
                                        </div>
                                    </div> -->

                                    <!-- <h2>Selecciona una tipo de pago:</h2>

                                    <div class="box-select mb-0" @click="selectedTypePayment(1)">
                                        <div class="flex-cuenta">
                                            <input type="checkbox" id="banco1" @click="selectedTypePayment(1)" :checked="type_payment_contra_entrega == 1" name="banco_type_payment" value="201328200">
                                            <label for="banco1" class="inside">
                                                <li> Efectivo</li>
                                            </label>
                                        </div>
                                    </div> -->

                                    <div class="input-flex w-50" v-if="type_payment_contra_entrega == 1">
                                        <div class="input-group">
                                            <label>Cambio para:</label>
                                            <input type="number" maxlength="15" v-model="amount_efectivo_contra_entrega">
                                        </div>
                                    </div>

                                    <!-- <div class="box-select mt-3 mb-0" @click="selectedTypePayment(2)">
                                        <div class="flex-cuenta">
                                            <input type="checkbox" id="banco2" @click="selectedTypePayment(2)" :checked="type_payment_contra_entrega == 2" name="banco_type_payment" value="201328200">
                                            <label for="banco2" class="inside">
                                                <li> Pago con tarjeta</li>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="input-flex w-50" v-if="type_payment_contra_entrega == 2">
                                        <div class="input-group">
                                            <label>Tipo: </label>
                                            <select name="mes" v-model="type_payment_card">
                                                <option value="1">PAGO DIRECTO</option>
                                                <option value="2">PAGO CUOTAS</option>
                                            </select>
                                        </div>
                                    </div> -->
                                    
                                </div>

                                <div class="resumen">
                                    <div class="summery-wrap">
                                        <div class="cart-wrap grand-total-wrap">
                                            <h3>Resumen de la Orden</h3>
                                            <div>
                                                <div class="order-summery-box">

                                                    <ul class="order-summery">
                                                        <!---->
                                                        <li class="pb-0"><span>Subtotal </span><span>$
                                                                {{ (TOTAL_SUM).toFixed(2) }}</span></li>

                                                        <li class="pb-0" v-if="type_entrega == 2"><span>Envío </span>
                                                            <span v-if="is_free == 2">GRATIS</span>
                                                            <span v-if="is_free == 1">
                                                                {{ tax == 0 ? 'GRATIS' : '$ '+tax }}</span>
                                                            </li>

                                                        <!-- <li class="pb-0" ><span>Comisión Paypal: </span>
                                                            <span> ${{ comisionPaypal().toFixed(2) }} </span>
                                                        </li> -->
                                                    </ul>

                                                    <p class="price-total">Total a Pagar <span class="size-total">${{ (TOTAL_SUM + (isTaxP() ? tax : 0) + comisionPaypal()).toFixed(2) }}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- <div v-if="embed && !WARNING_TEXT" 
                        style="max-height: max-content;
                        height: max-content;">
                                <iframe id="threedsIframe" ref="threedsIframe" :srcdoc="embed" 
                                    style="width: 100%;height: 100%;"
                                >
                                </iframe>
                    </div> -->
                    <div class="alert alert-info alert-with-icon" role="alert" v-if="type_payment == 2">
                        <div class="aviso-flex">
                            <div class="alert-icon-box">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-alert-triangle alert-icon">
                                    <path
                                        d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z">
                                    </path>
                                    <line x1="12" y1="9" x2="12" y2="13"></line>
                                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                </svg>
                            </div>
                            <div><b><i class="fa fa-warning fa-lg"></i>Importante</b></div>
                        </div>
                        <div>
                            "El titular" debe presentar la tarjeta de crédito/débito y documento de identificación al momento de recibir el pedido.
                        </div>
                    </div>
                    <!-- /termino-y-condiciones-de-uso href="/entregas-y-envios" href="/politica-de-garantia"-->
                    <div class="d-flex align-items-center" v-if="type_payment != 3">
                        <input type="checkbox" id="acepte_terminos" @click="acepte_terminos = acepte_terminos ? null: 1" :checked="acepte_terminos" name="acepte_terminos">
                        <label for="acepte_terminos" class="inside mb-0 mx-2">
                            Acepto los <a href="#" data-bs-toggle="modal" data-bs-target="#viewTerminosPages"  onclick="return false;" class="text-primary" target="_blank">términos y condiciones de pago</a> , 
                            <a href="#" data-bs-toggle="modal" data-bs-target="#viewEntregasEnviosPages"  onclick="return false;" class="text-primary" target="_blank">envío</a>  y 
                            <a href="#" data-bs-toggle="modal" data-bs-target="#viewPloticaGarantiaPages" class="text-primary" target="_blank">garantías</a>  al realizar la compra
                        </label>
                    </div>
                    <div class="btn-group">
                        <a href="#" onclick="return false;" :class="{'d-none': PROCESS_CARD}" class="btn btn-prev" @click="type_nav = 3">Volver</a>
                        
                        <template v-if="type_payment != 3">
                            <a data-bs-toggle="modal" data-bs-target="#viewTerminos" name="complete" class="btn btn-complete" v-if="!loading && !acepte_terminos">Proceder a Pagar</a>
                            <a @click="API_BANC_PASARELA()" type="button" name="complete" class="btn btn-complete" v-if="!loading && acepte_terminos">Proceder a Pagar</a>
                            <!-- @click="API_BANC_PASARELA()" type="button"  -->
                            <div class="loader-5 center" v-if="loading"><span></span></div>
                        </template>
                    </div>

                    <div class="pago-flex">
                        <div v-if="embed && !WARNING_TEXT" style="max-height: 300px;height: 300px;">
                            <iframe id="threedsIframe" ref="threedsIframe" :srcdoc="embed" style="width: 100%;height: 100%;"></iframe>
                        </div>
                        <div class="message-warning" v-if="WARNING_TEXT">
                            <span class="close-button" @click="WARNING_TEXT = null">&times;</span>
                            <p> {{ WARNING_TEXT }} </p>
                        </div>
                    </div>
                    
                </div>

            </form>
        </section>
        <!-- Main End -->

        <ModalTerminos></ModalTerminos>
        <ModalPageTerminos></ModalPageTerminos>
        <ModalPageConditionPago></ModalPageConditionPago>
        <ModalPagePoliticaGarantia></ModalPagePoliticaGarantia>
    </div>
</template>
<script>
import store from '../../plugins/store'
import myApi from '../../plugins/axios'
import GoogleMapsApiLoader from "google-maps-api-loader"
import SALVADOR from '../../assets/js/salvador'
import { useMeta } from 'vue-meta'
import PaypalButtons from './layouts/PaypalButton.vue'
import ModalTerminos from './modal/ModalTerminos.vue'
import ModalPageTerminos from './modal/ModalPageTerminos.vue'
import ModalPageConditionPago from './modal/ModalPageConditionPago.vue'
import ModalPagePoliticaGarantia from './modal/ModalPagePoliticaGarantia.vue'
export default {
    name: 'NewCheckout',
    data() {
        return {
            loggedIn: false,
            client_guest:{
                full_name: null,
                email: null,
                phone: null,
                description: null,
                // 
                nit: null,
                nrc: null,
                giro: null,
                dui: '',

                departament: null,
                municipality: null,
                address: null,
                point_reference: null,
                latitud: null,
                longitud: null,
                // 
                departament_factura: null,
                municipality_factura: null,
                address_factura: null,
            },
            is_credito_fiscal: null,
            delivery_information:{
                sucursal: null,
                departament: null,
                municipality: null,
                address: null,
                point_reference: null,
            },
            payment_info: {
                account_holder: null,
                concept: null,
                reference_number: null,
                amount: null,
                type_cuenta_bancaria: 1,
            },
            S_MUNICIPIOS:[],
            BACKUP_MUNICIPIOS:[],
            S_DEPARTAMENTOS:[],
            // 
            ADDRESS: [],
            ADDRES_SELECTED: null,
            // Login
            email_login: null,
            password_login: null,
            // Register
            name_register: null,
            surname_register: null,
            email_register: null,
            password_register: null,
            //   
            CARTS: [],
            TOTAL_SUM: 0,
            // MAPS
                mapa_error: false,
                direccion_correcta: false,
                direccion_incorrecta: false,
                google: null,
                map: null,
                marker:null,
                latitud: 0,
                id: null,
                longitud: 0,
                referencia:null,
                numDeltas : 50,
                delay : 5,
                i : 0,
                loading: false,
                new_direction: null,
            // ADDRESS
            title:null,
            departament:null,
            municipality:null,
            address:null,
            point_reference:null,
            // 
            sucursal: null,
            photo_file: null,
            cuentas: [],
            // 
            today: null,
            next_name_day: null,
            next_num_day: null,
            next_name_two_day: null,
            next_num_two_day: null,
            next_num_month: null,
            // 
            embed: null,
            // 
            is_visa: false,
            number_card: "",
            cvv_card: "",
            full_name_card:"",
            month_card:"01",
            year_card: (new Date().getFullYear()% 100)+"",
            year_propies:[],
            token_card: null,
            // 
            type_payment: 2,
            type_entrega: 1,
            type_cuenta_bancaria: null,
            // 
            tax: 2.50,
            is_free: 1,
            // 
            type_nav: 1,
            // 
            WARNING_TEXT: null,
            PROCESS_CARD: null,
            // 
            PHONE_ERROR_TEXT: null,
            NIT_NRC_ERROR_TEXT: null,
            SUCURSAL_ERROR_TEXT: null,
            DUI_ERROR_TEXT: null,
            OPTION_SELECTED: false,
            // 
            reference: {
                price: 0,
            },
            resultado_fac: null,
            client_guest_phone_initial: null,
            client_guest_dui_initial: null,
            // 
            selected_type_pay: 1,
            selected_type_pay_cuotas: null,
            // 
            type_payment_contra_entrega: 1,
            amount_efectivo_contra_entrega: 0,
            type_payment_card: '1',
            orders_count: 0,
            // 
            white: null,
            dark: null,
            acepte_terminos: null,
            sucursal_validate: [],
            loadingSucursal: true,
            SucursalEmpty: null,
        }
    },
    components:{
        PaypalButtons,
        ModalTerminos,
        ModalPageTerminos,
        ModalPageConditionPago,
        ModalPagePoliticaGarantia,
    },
    methods: {
        applyMask(){
            let value = this.client_guest.dui.toString().replace(/\D/g, '') // Eliminar todos los caracteres no numéricos
            if (value.length > 9) {
                value = value.slice(0, 8) // Limitar el tamaño a 8 dígitos
            }
            if (value.length > 8) {
                value = value.slice(0, 8) + '-' + value.slice(8) // Añadir el guion
            }
            this.client_guest.dui = value
        },
        isValidDui(dui) {
            const regex = /^\d{8}-\d{1}$/
            return regex.test(dui)
        },
        copyTextAccount(value) {
            const el = document.createElement('textarea');
            el.value = value;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);         
            if (selected) {                                 
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
            this.$notify({
                title: "COPIAR",
                text: "SE COPIO EL TEXTO ",
                duration: 3500,
                type:'primary',
            });
        },
        selectedTypePayment(val){
            this.type_payment_contra_entrega = val;
        },
        selectTypePay(checked){
            if(checked){
                this.selected_type_pay = 2;
                this.selected_type_pay_cuotas = 1;          
            }else{
                this.selected_type_pay = 1;
                this.selected_type_pay_cuotas = null;            
            }
        },
        selectTypePayCuotas(value){
            console.log(value);
            this.selected_type_pay_cuotas = value;
        },
        selectTypePayment(value){
            this.type_payment = value;
            // 
            this.reference.price = (this.TOTAL_SUM + (this.isTaxP() ? this.tax : 0) + this.comisionPaypal()).toFixed(2);
        },
        isAvalibleSucursal(sucursalN){
           let avalible_sucursal = this.sucursal_validate.find((item) => item.name == sucursalN);
           if(avalible_sucursal){
            return true;
           }
           return false;
        },
        // only_show_profile
        async showProfile(){
            const resp = await myApi.post('ecommerce/only_show_profile',{});
            console.log(resp);
            
            this.client_guest.full_name = resp.data.client.name + ' ' +(resp.data.client.surname ? resp.data.client.surname : '');
            this.client_guest.email = resp.data.client.email;
            this.client_guest.phone = resp.data.client.mobile;
            this.client_guest_phone_initial = resp.data.client.mobile;

            this.client_guest.nit = resp.data.client.nit;
            this.client_guest.nrc = resp.data.client.nrc;
            this.client_guest.giro = resp.data.client.gito;
            this.client_guest.dui = resp.data.client.dui;
            this.client_guest_dui_initial =  resp.data.client.dui;
            // 
            this.client_guest.departament_factura = resp.data.client.departament;
            this.client_guest.municipality_factura = resp.data.client.municipality;
            this.client_guest.address_factura = resp.data.client.address_f;
            // this.client_guest.point_reference = resp.data.client.point_reference;
            this.ADDRESS = resp.data.client.address;
            this.changeLocation({target:{
                value: this.client_guest.departament_factura,
            }})
            this.orders_count = resp.data.orders_count;
        },
        // 
        async Login(){
            try {
                if(!this.email_login || !this.password_login){
                        this.$notify({
                            title: "VALIDACIÓN",
                            text: `SE DEBE INGRESAR TODOS LOS CAMPOS PARA PODER INGRESAR A LA TIENDA!`,
                            duration: 3500,
                            type:'error',
                        });
                        return;
                }
                this.loading = true;
                let resp = await myApi.post('ecommerce/login',
                {
                    email: this.email_login,
                    password: this.password_login,
                    CARTS: this.CARTS,
                });
                this.loading = false;
                console.log(resp);
                if(resp.data.error){
                    for (let key in resp.data.error) {
                        this.$notify({
                            title: "VALIDACIÓN",
                            text: resp.data.error[key],
                            duration: 3500,
                            type:'error',
                        });
                    }
                }else{
                    store.dispatch('saveToken',{
                        token: resp.data.token,
                        user: resp.data.user,
                    });
                    setTimeout(() => {
                        window.location.href = process.env.VUE_APP_FRONTED+"checkout";
                    }, 50);
                }
          } catch (err) {
            this.loading = false;
            if(err.response.data){
                  // alert(err.response.data.message)
                this.$notify({
                      title: "VALIDACIÓN",
                      text: err.response.data.message,
                      duration: 3500,
                      type:'error',
                });
              }else{
                this.$notify({
                      title: "VALIDACIÓN",
                      text: "Hubo un error al intentar registrarse, intentalo nuevamente",
                      duration: 3500,
                      type:'error',
                });
              }
              console.log(err);
          }
        },
        async Register(){

            try {
                if(!this.name_register || !this.surname_register
                || !this.email_register || !this.password_register){
                    this.$notify({
                        title: "VALIDACIÓN",
                        text: `SE DEBE INGRESAR TODOS LOS CAMPOS PARA PODER REGISTRARSE`,
                        duration: 3500,
                        type:'error',
                    });
                    return;
                }
                this.loading = true;
                let resp = await myApi.post('ecommerce/register',
                {
                    surname: this.name_register,
                    name: this.surname_register,
                    email: this.email_register,
                    password: this.password_register,
                    CARTS: this.CARTS,
                });
                console.log(resp);
                this.loading = false;
                if(resp.data.error){
                    for (let key in resp.data.error) {
                        this.$notify({
                            title: "VALIDACIÓN",
                            text: resp.data.error[key],
                            duration: 3500,
                            type:'error',
                        });
                    }
                }else{
                    this.email_login = this.email_register;
                    this.password_login = this.password_register;
                    setTimeout(() => {
                        this.Login();
                    }, 25);
                }
          } catch (err) {
            this.loading = false;
            if(err.response.data){
                  // alert(err.response.data.message)
                this.$notify({
                      title: "VALIDACIÓN",
                      text: err.response.data.message,
                      duration: 3500,
                      type:'error',
                });
            }else{
                this.$notify({
                        title: "VALIDACIÓN",
                        text: "Hubo un error al intentar registrarse, intentalo nuevamente",
                        duration: 3500,
                        type:'error',
                });
            }
            console.log(err);
          }
        },
        // MAPAS
        initializeMap(lat,lng) {
            const mapContainer = this.$refs.googleMap
            console.log(this.google,mapContainer);
            if(this.google){
                this.map = new this.google.maps.Map(mapContainer, {
                    center: {lat: lat, lng: lng},
                    gestureHandling: 'greedy',
                    zoom: 16
                })
                const icon2 = {
                    url: '/assets/logos/servicio-al-cliente.png', // Ruta de tu icono personalizado
                    scaledSize: new this.google.maps.Size(50, 50), // Tamaño del icono
                };
                this.marker = new this.google.maps.Marker({
                    position: {
                        lat: lat,
                        lng: lng
                    },
                    map: this.map,
                    title: "Latitude:"+ 0 +" | Longitude:"+ 0,
                    icon: icon2,
                });
    
                var result = {
                    lat : lat,
                    lng : lng
                };
                var geocoder = new this.google.maps.Geocoder;
                geocoder.geocode({'location': result}, (results, status) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            if(!this.ADDRES_SELECTED){
                                this.client_guest.address = results[0].formatted_address;
                                let ARRAY_TEXT = this.client_guest.address.split(",")
                                if(ARRAY_TEXT.length > 2){
                                    this.changeForMunicipio(ARRAY_TEXT[ARRAY_TEXT.length - 2]);
                                }
                            }
                        } else {
                            window.alert('No results found');
                        }
                    } else {
                        window.alert('Geocoder failed due to: ' + status);
                    }
                });
    
                this.map.addListener('click', (mapsMouseEvent) => {
    
                    let lat = Number(mapsMouseEvent.latLng.lat().toString());
                    let lng = Number(mapsMouseEvent.latLng.lng().toString());
                    console.log(lat,lng);
                    var result = {
                        lat : lat,
                        lng : lng
                    };
                    // self.validar(lat,lng)
                    this.efecto(result);
                    this.direccion_correcta = true;
                    // this.moveMarker();
    
                    var geocoder = new this.google.maps.Geocoder;
                    // var self = this;
                    geocoder.geocode({'location': result}, (results, status) => {
                        if (status === 'OK') {
                            if (results[0]) {
                                // infowindow.setContent();
                                // console.log(results[0].formatted_address);
                                if(!this.ADDRES_SELECTED){
                                    this.client_guest.address = results[0].formatted_address;
                                    let ARRAY_TEXT = this.client_guest.address.split(",")
                                    if(ARRAY_TEXT.length > 2){
                                        this.changeForMunicipio(ARRAY_TEXT[ARRAY_TEXT.length - 2]);
                                    }
                                }
                            } else {
                                window.alert('No results found');
                            }
                        } else {
                            window.alert('Geocoder failed due to: ' + status);
                        }
                    });
    
                });
            }else{
                this.initGoogleMap();
                setTimeout(() => {
                    this.initializeMap(lat,lng);
                }, 50);
            }
        },
        efecto(result){
            this.i = 0;
            this.deltaLat = (result.lat - this.latitud)/ this.numDeltas;
            this.deltaLng = (result.lng - this.longitud)/ this.numDeltas;
            this.moveMarker();
        },
        moveMarker(){
            this.latitud += this.deltaLat;
            this.longitud += this.deltaLng;
            this.client_guest.latitud = this.latitud;
            this.client_guest.longitud = this.longitud;
            this.marker.setTitle("Latitude:" + this.latitud + " | Longitude:"+ this.longitud);
            this.marker.setPosition({
                lat: this.latitud,
                lng: this.longitud
            });
            if(this.i !== this.numDeltas){
                this.i++;
                setTimeout(this.moveMarker(), this.delay);
            }
        },
        async initGoogleMap(){
            // 
            const googleMapApi = await GoogleMapsApiLoader({
                apiKey: 'AIzaSyA6MRhgLWpKf7XfNNUP3H9yz-ts9R3hvD8',
            });
            this.google = googleMapApi
            var that = this
            function onError() {
                that.mapa_error = true;
            }
            function onSuccess(geo) {
                // const position = {
                //     lat: geo.coords.latitude,
                //     lng: geo.coords.longitude
                // };
                console.log(geo);
                // Reposition the map to the detected location
                if(!that.ADDRES_SELECTED){
                    that.latitud = Number(geo.coords.latitude) //13.678767566370581;
                    that.longitud = Number(geo.coords.longitude) //-89.27604134734102;
                    that.mapa_error = false;
                    that.initializeMap(that.latitud,that.longitud);
                }
                // that.validar(that.latitud,that.longitud)
            }

            if (!navigator.geolocation) {
                onError();
            } else {
                navigator.geolocation.getCurrentPosition(onSuccess, onError);
            }
            // this.obtener_direcciones();
            // this.initializeMap(this.latitud,this.longitud);
        },
        async SaveAddress(){
            try {
                if(!this.client_guest.departament || !this.client_guest.municipality || !this.client_guest.address
                || !this.title){
                    this.$notify({
                        title: "VALIDACIÓN",
                        text: "PARA REGISTRAR UNA DIRECCIÓN TODAS LOS CAMPOS SON NECESARIOS",
                        duration: 3500,
                        type:'error',
                    });
                    return;
                }
                let data = {
                    title: this.title,
                    latitud: this.latitud,
                    longitud: this.longitud,
                    departament: this.client_guest.departament,
                    municipality: this.client_guest.municipality,
                    address: this.client_guest.address,
                    point_reference: this.client_guest.point_reference,
                };
                const resp = await myApi.post('ecommerce/profile_address',data);
                console.log(resp);
                this.ADDRESS.push(resp.data.address);
                setTimeout(() => {
                    this.changeAddress({target:{value: resp.data.address.id}});
                }, 25);
                this.$notify({
                    title: "REGISTRO DE DIRECCIÓN",
                    text: "SE AGREGO UNA NUEVA DIRECCIÓN A SU PANEL",
                    duration: 3500,
                    type:'primary',
                });
                this.departament = null;
                this.municipality = null;
                this.address = null;
                this.point_reference = null;
                this.title = null;
            } catch (error) {
                console.log(error);
                this.$notify({
                    title: "Hubo un error a nivel interno, comuniquese con el soporte de la pagina",
                    duration: 3500,
                    type:'error',
                });
            }
        },
        changeLocation(event){
            let MUNICIPIO_SELECTED = event.target.value;
            let MUNUCIPIOS = this.BACKUP_MUNICIPIOS.filter(item => item.state == MUNICIPIO_SELECTED);
            this.S_MUNICIPIOS = MUNUCIPIOS;
        },
        changeForMunicipio(municipio){
            let DEPARTAMENTO = this.BACKUP_MUNICIPIOS.filter(item => item.city == municipio.trim());
            // console.log(DEPARTAMENTO,municipio);
            if(DEPARTAMENTO.length > 0){
                this.client_guest.departament = DEPARTAMENTO[0].state;
                this.client_guest.municipality = municipio.trim();
                this.changeLocation({target: {value : DEPARTAMENTO[0].state}});
            }
        },
        changeAddress(event){
            let ADDRESS_SELECTED = event.target.value;
            if(!ADDRESS_SELECTED){
                // 
                this.title = null;
                this.client_guest.departament = null;
                this.client_guest.municipality = null;
                this.client_guest.address = null;
                this.client_guest.point_reference = null;
                this.client_guest.latitud = null;
                this.client_guest.longitud = null;
                // 
                this.ADDRES_SELECTED = null;
                this.OPTION_SELECTED = false;
                // setTimeout(() => {
                //     this.initGoogleMap();
                // }, 50);
                return;
            }
            this.OPTION_SELECTED = true;
            this.ADDRES_SELECTED = ADDRESS_SELECTED;
            let ADDRE = this.ADDRESS.find(item => item.id == ADDRESS_SELECTED);
            this.client_guest.departament = ADDRE.departament;
            this.client_guest.municipality = ADDRE.municipality;
            this.client_guest.address = ADDRE.address;
            this.client_guest.point_reference = ADDRE.point_reference;
            this.client_guest.latitud = ADDRE.latitud;
            this.client_guest.longitud = ADDRE.longitud;

            this.changeLocation({target:{
                value: ADDRE.departament,
            }})
            if(this.loggedIn){
                this.initGoogleMap();
            }
            setTimeout(() => {
                if(ADDRE.latitud){
                this.latitud = Number(ADDRE.latitud);
                this.longitud  = Number(ADDRE.longitud);
                this.initializeMap(this.latitud,this.longitud);
                }
            }, 50);

        },
        selectedAddress(ADDRE){
            this.client_guest.departament = ADDRE.departament;
            this.client_guest.municipality = ADDRE.municipality;
            this.client_guest.address = ADDRE.address;
            this.client_guest.point_reference = ADDRE.point_reference;
            this.client_guest.latitud = ADDRE.latitud;
            this.client_guest.longitud = ADDRE.longitud;

            this.changeLocation({target:{
                value: ADDRE.departament,
            }})

            setTimeout(() => {
                if(ADDRE.latitud){
                this.latitud = Number(ADDRE.latitud);
                this.longitud  = Number(ADDRE.longitud);
                this.initializeMap(this.latitud,this.longitud);
                }
            }, 50);
        },
        resetAddress(){
            this.OPTION_SELECTED = true;
            // 
            this.title = null;
            this.client_guest.departament = null;
            this.client_guest.municipality = null;
            this.client_guest.address = null;
            this.client_guest.point_reference = null;
            this.client_guest.latitud = null;
            this.client_guest.longitud = null;
            // 
            this.initGoogleMap();
        },
        // 
        selectedTypeEntrega(val) {
            this.type_entrega = val;
            // 
            this.client_guest.departament = null;
            this.client_guest.municipality = null;
            this.client_guest.address = null;
            this.client_guest.point_reference = null;
            this.client_guest.latitud = null;
            this.client_guest.longitud = null;
            // 
            this.ADDRES_SELECTED = null;
            this.OPTION_SELECTED = false;
            // 
            this.delivery_information.sucursal = null;
            // 
            if(!this.loggedIn){
                this.initGoogleMap();
            }
            // this.initGoogleMap();
        },
        // 
        async storeOrder(){

            // this.client_guest
            // this.delivery_information
            // this.payment_info
            // this.photo_file
            if(this.type_payment == 1){
                if(!this.type_cuenta_bancaria){
                    this.$notify({
                        title: "VALIDACIÓN",
                        text: `Necesitas seleccionar una cuenta bancaria`,
                        duration: 3500,
                        type:'error',
                    });
                    return;
                }
                if(!this.payment_info.account_holder){
                    this.$notify({
                        title: "VALIDACIÓN",
                        text: `Necesitas digitar al titular de la cuenta`,
                        duration: 3500,
                        type:'error',
                    });
                    return;
                }
                // if(!this.payment_info.concept){
                //     this.$notify({
                //         title: "VALIDACIÓN",
                //         text: `Necesitas ingresar por concepto de la cuenta`,
                //         duration: 3500,
                //         type:'error',
                //     });
                //     return;
                // }
                if(!this.payment_info.reference_number){
                    this.$notify({
                        title: "VALIDACIÓN",
                        text: `Necesitas ingresar por número de referencia`,
                        duration: 3500,
                        type:'error',
                    });
                    return;
                }
                if(!this.payment_info.amount){
                    this.$notify({
                        title: "VALIDACIÓN",
                        text: `Necesitas ingresar el monto del deposito`,
                        duration: 3500,
                        type:'error',
                    });
                    return;
                }
                if(!this.photo_file){
                    this.$notify({
                        title: "VALIDACIÓN",
                        text: `Necesitas ingresar el comprobante del deposito`,
                        duration: 3500,
                        type:'error',
                    });
                    return;
                }
            }
            if(this.type_payment == 4){
               //  if(!this.amount_efectivo_contra_entrega && this.tp_pay_contra_entrega == 1){
                    // this.$notify({
                       //  title: "VALIDACIÓN",
                       //  text: `Necesitas ingresar un CAMBIO POR`,
                       //  duration: 3500,
                      //   type:'error',
                   //  });
                    // return;
                // }
            }

            let formData = new FormData();
            if(this.client_guest){
                formData.append("client_guest",JSON.stringify(this.client_guest));
            }
            formData.append("delivery_information",JSON.stringify(this.delivery_information));
            if(this.token_card){
                formData.append("token_card",this.token_card);
            }else{
                if(this.type_payment != 4){
                    this.payment_info.type_cuenta_bancaria = this.type_cuenta_bancaria;
                    formData.append("payment_info",JSON.stringify(this.payment_info));
                    formData.append("photo_file",this.photo_file);
                }
            }
            if(this.resultado_fac){
                formData.append("resultado_fac",JSON.stringify(this.resultado_fac));
            }
            formData.append("order_details",JSON.stringify(this.CARTS));
            try {
                let order = {
                is_credito_fiscal: this.is_credito_fiscal,
                type_entrega: this.type_entrega,
                type_payment: this.type_payment,
                sucursal: this.delivery_information.sucursal,
                // estimated_at: null,
                sel_type_pay: this.selTypePay(),
                sel_type_pay_cuotas: this.selTypePayCuotas(),
                is_visa_master: this.is_visa ? this.is_visa : null,
                // received_at: null,
                tax: this.isTaxP() ? this.tax : 0,
                comision_paypal: this.comisionPaypal(),
                // code: ,
                discount: 0,
                subtotal: (this.TOTAL_SUM).toFixed(2),
                total: (this.TOTAL_SUM + (this.isTaxP() ? this.tax : 0) + this.comisionPaypal()).toFixed(2),
                }

                if(this.type_payment == 4){
                    order.tp_pay_contra_entrega = this.type_payment_contra_entrega;
                    order.amount_efect_contra_entrega = this.amount_efectivo_contra_entrega;
                    order.type_payment_card = this.type_payment_card;
                }

                formData.append("order",JSON.stringify(order));
                this.loading = true;
                const resp = await myApi.post('ecommerce/order',formData);
                this.loading = false;
                if(resp.data.error){
                for (let key in resp.data.error) {
                    this.$notify({
                        title: "VALIDACIÓN",
                        text: resp.data.error[key],
                        duration: 3500,
                        type:'error',
                    });
                }
                }else{
                console.log(resp);
                // this.$cookies.set("carts", []);
                // this.emitter.emit("cart-shop", []);
                if(resp.data.message == 403){
                    resp.data.errors.forEach(elem => {
                    this.$notify({
                            title: "VALIDACIÓN",
                            text: elem,
                            duration: 3500,
                            type:'error',
                    });
                    });
                }else{
                    // this.$cookies.remove("carts");
                    localStorage.removeItem("carts");
                    this.$router.push({
                    name: 'OrderSuccess',
                    params: {
                        uniqd: resp.data.uniqd_order,
                    }
                    });
                    this.token_card = null;
                    this.$notify({
                        title: "PROCESO DE COMPRA",
                        text: `LA COMPRA SE REALIZO EXITOSAMENTE`,
                        duration: 2000,
                    });
                }
                }
            } catch (error) {
                this.loading = false;
                console.log(error);
                this.$notify({
                    title: "DEBUG",
                    text: "SE PRESENTO UNA INCIDENCIA AL MOMENTO DE PROCESAR LA COMPRA , INTENTELO NUEVAMENTE O SINO CONSULTE CON SU PROVEEDOR",
                    duration: 3500,
                    type:'error',
                });
            }
        },
        selTypePay(){
            if(this.type_payment == 2){
                return this.selected_type_pay;
            }
            return null;
        },
        selTypePayCuotas(){
            if(this.type_payment == 2){
                return this.selected_type_pay == 2 ? this.selected_type_pay_cuotas : null;
            }
            return null;
        },
        // 
        isTaxP(){
            if(this.type_entrega == 2){
                // if(this.client_guest.departament == "San Salvador" || this.client_guest.departament == "La Libertad"){
                //     if(this.client_guest.municipality == "San Salvador" || this.client_guest.municipality == "Santa Tecla"){
                //         return false;
                //     }
                // }
                if(this.is_free == 2){
                    return false;
                }
                return true;
            }
            return false;
        },
        comisionPaypal(){
            if(this.type_payment == 3){
                return ((this.TOTAL_SUM + (this.isTaxP() ? this.tax : 0))*0.054 + 0.30);
            }
            return 0;
        },
        // 
        validateFile(fileInput) {
            const allowedExtensions = /\.(jpg|jpeg|png|webp|pdf)$/i; // Extensiones permitidas en regex
            const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB en bytes

            if (!fileInput.files || fileInput.files.length === 0) {
                return false; // No se seleccionó ningún archivo
            }

            const file = fileInput.files[0];

            if (!allowedExtensions.test(file.name)) {
                return false; // La extensión del archivo no es válida
            }

            if (file.size > maxSizeInBytes) {
                return false; // El archivo supera el tamaño máximo permitido
            }

            return true; // El archivo cumple con todas las condiciones
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length){
                return;
            }
            if (!this.validateFile(e.target)) {
                this.$notify({
                    title: "Solo se permite estas extesiones (jpg, png, webp y pdf) y un peso maximo de 5MB.",
                    duration: 4000,
                    type:'error'
                });
                return;
            }
            this.photo_file = files[0];
        },
        async API_BANC_PASARELA(){
            try {
                if(!this.acepte_terminos){
                    this.$notify({
                        title: "Necesitas primero aceptar nuestros terminos y condiciones",
                        duration: 3500,
                        type:'error',
                    });
                    return ;
                }
                if(this.type_payment == 1 || this.type_payment == 4){
                    this.storeOrder();
                }else{
                    if(!this.number_card || !this.cvv_card || 
                    !this.full_name_card || !this.month_card || 
                    !this.year_card){
                        this.$notify({
                            title: "Debes llenar toda la información solicitada para procesar el pago con tarjeta!",
                            duration: 3500,
                            type:'error',
                        });
                        return;
                    }
                    const resp = await myApi.post('banc-api-request',{
                        card_pan: this.number_card.replace(/\s+/g, ''),
                        card_cvv: this.cvv_card,
                        card_holder_name: this.full_name_card,
                        month_card: this.month_card,
                        year_card: this.year_card,
                        total_payment: this.TOTAL_SUM+ (this.isTaxP() ? this.tax : 0),
                        sel_type_pay: this.selected_type_pay,
                        sel_type_pay_cuotas: this.selected_type_pay_cuotas,
                    });
                    window.scrollTo(0, document.body.scrollHeight);
                    console.log(resp);
                    this.embed = resp.data.response.RedirectData;
                    // console.log(this.embed);
                    let SpiToken = resp.data.response.SpiToken;
                    this.token_card = SpiToken;
                    // console.log(SpiToken);
                    this.loading = true;
                    this.WARNING_TEXT = null;
                    this.PROCESS_CARD = 1;
                    let timeoutId = setInterval(async () => {
                        console.log(SpiToken);
                        const check_val = await myApi.post('checkout-valid-process',{SpiToken:SpiToken});
                        console.log(check_val);
                        if(check_val.data.message == 200){
                            this.storeOrder();
                            console.log("PROCESAR LA VENTA");
                             this.PROCESS_CARD = null;
                            clearTimeout(timeoutId)
                        }
                        if(check_val.data.message == 403){
                            //REPETIR EL CICLO
                            console.log("REPETIR EL CICLO");
                        }
                        if(check_val.data.message == 500){
                            // PROCESAR EL PAGO NUEVAMENTE
                            this.loading = false;
                            console.log("PROCESAR EL PAGO NUEVAMENTE");
                             this.PROCESS_CARD = null;
                            this.WARNING_TEXT = "INTENTA INGRESAR NUEVAMENTE TUS DATOS";
                            clearTimeout(timeoutId)
                            // this.number_card = '';
                            // this.cvv_card = '';
                            // this.full_name_card = '';
                        }
                    }, 5000);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async selectedTypeSucursal(sucursal){
            this.delivery_information.sucursal = sucursal;
        },
        selectedTypeCuentaBancaria(val) {
            this.type_cuenta_bancaria = val;
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        isStringT(evt){
            // console.log(evt);
            if(evt.key == ' ' || evt.key.match(/^[A-Za-z]*$/)){
                return true;
            } else {
                evt.preventDefault();
            }
        },
        isStringAlfaNumer(evt){
            // console.log(evt);
            // var valor = evt.target.value;
            // // Elimina todos los caracteres que no sean alfanuméricos
            // var valorLimpio = valor.replace(/[^a-zA-Z0-9]/g, '');
            // // Actualiza el valor del input con el valor limpio
            // evt.target.value = valorLimpio;evt.key == ' ' || 
            if(evt.key.match(/^[^a-zA-Z0-9\s]*$/)){
                evt.preventDefault();
            } else {
                return true;
            }
        },
        digitalCard(valor){
            if(valor){
                let FIRST_LETER = valor.charAt(0);
                if(FIRST_LETER == '4'){
                    this.is_visa = 1;
                }
                if(FIRST_LETER == '5'){
                    this.is_visa = 2;
                }
            }else{
                this.is_visa = null;
            }
            // this.number_card = valor;
            let TEXTS = valor.replace(/\s/g, ""); // Eliminar espacios existentes
            TEXTS = TEXTS.slice(0, 16); // Limitar a un máximo de 16 dígitos
            
            var valorFormateado = "";
            for (var i = 0; i < TEXTS.length; i++) {
                if (i > 0 && i % 4 === 0) {
                valorFormateado += " ";
                }
                valorFormateado += TEXTS[i];
            }
            this.number_card = valorFormateado;//this.number_card ? this.number_card.toString().replace(/\B(?=(\d{4})+(?!\d))/g, " ") : null;
        },
        cvvCard(valor){
            this.cvv_card = valor;
            this.cvv_card = this.cvv_card.replace(/\D/g, '');
            this.limitarDigitos();
        },
        limitarDigitos(maxDigitos = 4) {
            var valor = this.cvv_card;
            if (valor.length > maxDigitos) {
                valor = valor.slice(0, maxDigitos);
                this.cvv_card = valor;
            }
        },
        fullNameCard(valor){
            this.full_name_card = valor;
        },
        MonthCard(valor){
            this.month_card = valor;
        },
        YearCard(valor){
            this.year_card = valor;
        },
        nextInfoDelivery(){
            this.PHONE_ERROR_TEXT = null;
            // if(!this.client_guest.departament_factura || !this.client_guest.municipality_factura || !this.client_guest.address_factura){
            //     // this.$notify({
            //     //     title: "VALIDACIÓN",
            //     //     text: `SE DEBE INGRESAR TODOS LOS CAMPOS DE LA DIRECCIÓN DE FACTURACIÓN!`,
            //     //     duration: 3500,
            //     //     type:'error',
            //     // });
            //     this.PHONE_ERROR_TEXT = "Se debe ingresar todos los campos de la dirección de facturación!";
            //     if(this.is_credito_fiscal){
            //         if(!this.client_guest.nit || !this.client_guest.nrc || !this.client_guest.giro){
            //             // this.$notify({
            //             //     title: "VALIDACIÓN",
            //             //     text: `LLENAR TODOS LOS CAMPOS DE LA FACTURA DE CREDITO FISCAL`,
            //             //     duration: 3500,
            //             //     type:'error',
            //             // });
            //             this.NIT_NRC_ERROR_TEXT = "Llenar todos los campos de la factura de credito fiscal";
            //             return;
            //         }
            //     }
            //     return;
            // }
            this.priceShoppingCategorie(this.CARTS);
            this.processCartSucursal(this.CARTS);
            if(!(/^[^@]+@\w+(\.\w+)+\w$/.test(this.client_guest.email))){
                this.$notify({
                    title: "Ingresa un dirección de correo electronica valida.",
                    duration: 3500,
                    type:'error'
                });
                return;
            }
            if(!this.client_guest.phone){
                this.PHONE_ERROR_TEXT = "Debe llenar un telefono de referencia!";
                return;
            }
            if(!this.isPhoneValid()){
                // this.$notify({
                //     title: "VALIDACIÓN",
                //     text: `EL TELEFONO INGRESADO ES INCORRECTO!`,
                //     duration: 3500,
                //     type:'error',
                // });
                this.PHONE_ERROR_TEXT = "El telefono ingresado no tiene el formato correcto!";
                return;
            }
            if(!this.isValidDui(this.client_guest.dui)){
                this.DUI_ERROR_TEXT = "El DUI debe ingresarse y estar en el formato correcto";
                return;
            }
            if(this.is_credito_fiscal){
                
                if(!this.isNitValid()){
                    // this.$notify({
                    //     title: "VALIDACIÓN",
                    //     text: `EL NIT INGRESADO ES INCORRECTO!`,
                    //     duration: 3500,
                    //     type:'error',
                    // });
                    this.NIT_NRC_ERROR_TEXT = "El NIT ingresado no tiene el formato correcto";
                    return;
                }
                if(!this.isNRCValid()){
                    // this.$notify({
                    //     title: "VALIDACIÓN",
                    //     text: `EL NRC INGRESADO ES INCORRECTO!`,
                    //     duration: 3500,
                    //     type:'error',
                    // });
                    this.NIT_NRC_ERROR_TEXT = "El NRC ingresado no tiene el formato correcto!";
                    return;
                }
            }
            this.type_nav = 3;
        },
        nextInfoEntrega(){
            if(this.type_entrega == 2){
                if(!this.client_guest.departament || !this.client_guest.municipality || !this.client_guest.address ){
                    // this.$notify({
                    //     title: "VALIDACIÓN",
                    //     text: `SE DEBE INGRESAR TODOS LOS CAMPOS DE LA INFORMACIÓN DE ENTREGA A DOMICILIO!`,
                    //     duration: 3500,
                    //     type:'error',
                    // });
                    this.SUCURSAL_ERROR_TEXT = 'Se debe ingresar todos los campos de la información de entrega a domicilio!';
                    return;
                }
                this.priceShoppingZona(this.CARTS);
            }else{
                if(!this.delivery_information.sucursal){
                    // this.$notify({
                    //     title: "VALIDACIÓN",
                    //     text: `SE DEBE SELECCIONAR UNA SUCURSAL`,
                    //     duration: 3500,
                    //     type:'error',
                    // });
                    this.SUCURSAL_ERROR_TEXT = 'Tienes que seleccionar una sucursal'; 
                    return;
                }
            }
            // this.modalOpen = false
            this.type_nav = 4;
        },
        updateMaskNit(){
            let value = this.client_guest.nit.replace(/\D/g, '');

            if (value.length > 0) {
                let maskedValue = '';

                if (value.length > 4) {
                    maskedValue += value.substr(0, 4) + '-';
                } else {
                    maskedValue += value;
                }

                if (value.length > 10) {
                 maskedValue += value.substr(4, 6) + '-';
                } else {
                    maskedValue += value.substr(4);
                }

                if (value.length > 13) {
                    maskedValue += value.substr(10, 3) + '-';
                } else {
                    maskedValue += value.substr(10);
                }

                if (value.length > 14) {
                    maskedValue += value.substr(13, 1);
                } else {
                    maskedValue += value.substr(13);
                }

                this.client_guest.nit = maskedValue;
            }
        },
        updateMaskNrc(){
            let value = this.client_guest.nrc.replace(/\D/g, '');

            if (value.length > 0) {
                let maskedValue = '';

                if (value.length > 6) {
                    maskedValue += value.substr(0, 6) + '-';
                } else {
                    maskedValue += value;
                }

                if (value.length > 7) {
                    maskedValue += value.substr(6, 1);
                } else {
                    maskedValue += value.substr(6);
                }

                this.client_guest.nrc = maskedValue;
            }
        },
        isPhoneValid(){
            var re = /^[7|6|2][0-9]{7}$/;
            return re.test(this.client_guest.phone);
        },
        isNitValid(){
            var re = /^[0-9]{4}-[0-9]{6}-[0-9]{3}-[0-9]{1}$/;
            return re.test(this.client_guest.nit);
        },
        isNRCValid(){
            var re = /^[0-9]{1,6}-[0-9]{1}$/;
            return re.test(this.client_guest.nrc);
        },
        async priceShoppingCategorie(CARTS = []){
            const resp = await myApi.post('ecommerce/define_price_shipping_categorie',{
                CARTS: CARTS,
                TOTAL_SUM: this.TOTAL_SUM,
            });
            console.log(resp);
            this.is_free = resp.data.is_free;
            this.tax = resp.data.tax;
        },
        async priceShoppingZona(CARTS = []){
            const resp = await myApi.post('ecommerce/define_price_shipping_zona',{
                CARTS: CARTS,
                departament: this.client_guest.departament,
                municipio: this.client_guest.municipality,
                TOTAL_SUM: this.TOTAL_SUM,
            });
            console.log(resp);
            if(this.is_free != 2){
                this.is_free = resp.data.is_free;
            }
            if(this.tax > resp.data.tax){
                this.tax = resp.data.tax;
            }
        },
        async processCartSucursal(CARTS = []){
            try {
                this.loadingSucursal = null;
                const resp = await myApi.post('ecommerce/process_cart_sucursal',{
                    CARTS: CARTS,
                });
                console.log(resp);
                this.loadingSucursal = true;
                if(resp.data.message == 500){
                    this.SucursalEmpty = 1;
                    this.$notify({
                        title: "Hay productos que ya no se pueden procesar, porque no se encuentran disponibles",
                        duration: 3500,
                        type:'error',
                    });
                    setTimeout(() => {
                        this.$router.push({
                            name: 'CartShop',
                            query: {sin_stock: 'validar'}
                        });
                    }, 1000);
                }
                if(resp.data.message == 403){
                    this.SucursalEmpty = 1;
                    this.$notify({
                        title: "No se encontro ninguna sucursal disponible",
                        duration: 3500,
                        type:'error',
                    });
                    setTimeout(() => {
                        this.$router.push({
                            name: 'CartShop',
                            query: {sin_stock: 'validar'}
                        });
                    }, 1000);
                }
                if(resp.data.message == 200){
                  this.sucursal_validate = resp.data.sucursales;
                }
            } catch (error) {
                console.log(error);
                this.loadingSucursal = true;
                this.$notify({
                    title: "Hubo un error a nivel interno, comuniquese con el soporte de la pagina",
                    duration: 3500,
                    type:'error',
                });
            }
        },
        onApproveR(value){
            console.log(value);
            // .purchase_units[0].payments.captures[0].id
            this.resultado_fac = {
                "idTransaccion":value.purchase_units[0].payments.captures[0].id,
                "esReal":true,
                "esAprobada":true,
                "codigoAutorizacion":"123456",
                "mensaje":"AUTORIZADO DE PAYPAL",
                "formaPago":"PagoNormal",
                "monto":value.purchase_units[0].amount.value
            }
            this.$notify({
                text: `TU PAGO SE REALIZO EXITOSAMENTE,AHORA ESTAMOS PROCESADO TU COMPRA`,
                duration: 3000,
            });
            setTimeout(() => {
                this.storeOrder();
            },100);
        },
        onErrorR(value){
            console.log(value);
            this.$notify({
                title: "TU CUENTA DE PAYPAL ESTA TENIENDO PROBLEMAS PARA PROCESAR EL PAGO, INTENTE CON OTRO METODO DE PAGO",
                duration: 8500,
                type:'error',
            });
        },
        isDarkLigth(){
            let darkId = localStorage.getItem("darkId") ? localStorage.getItem("darkId") : 'light-btn';
            if(darkId == 'light-btn'){
                this.white = null;
                this.dark = true;
            }

            if(darkId == 'dark-btn'){
                this.dark = null;
                this.white = true;
            }
        },
    },
    created() {
        this.isDarkLigth();
    },
    async mounted() {
        this.useMetaT = useMeta({
            title: 'Checkout',
            htmlAttrs: { lang: 'en', amp: true },
            meta: [],
        });
        this.emitter.on('dark-ligth',() => {
            this.isDarkLigth();
        });
        this.BACKUP_MUNICIPIOS = SALVADOR.MUNICIPIOS;
        this.S_DEPARTAMENTOS = SALVADOR.DEPARTAMENTOS;
        // 
        let CARTS = localStorage.getItem('carts') ? JSON.parse(localStorage.getItem('carts')) : [];//this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
        this.CARTS = CARTS;
        this.TOTAL_SUM = this.CARTS.reduce((sum,cart) => sum+ parseFloat(cart.total),0);
        this.emitter.on('cart-shop',data => {
            this.CARTS = data;
            this.TOTAL_SUM = this.CARTS.reduce((sum,cart) => sum+ parseFloat(cart.total),0);
            // const script2 = document.createElement('script');
            //     script2.src = '../../assets/js/feather/feather.min.js';
            //     document.body.appendChild(script2);
            //     script2.onload = () => {
            //     console.log('archivo.js cargado 2');
            //     }
        });
        this.emitter.emit("cart-list", {});
        
        console.log(store.getters.loggedIn);
        this.loggedIn = store.getters.loggedIn;
        if(store.getters.loggedIn){
            this.showProfile();
            this.type_nav = 2;//2;
        }
        // if(!this.loggedIn){
        //   this.initGoogleMap();
        // }
        this.emitter.on('acept-terminos',data => {
            this.acepte_terminos = data;
        });

        var minYear = new Date().getFullYear() - 1;
        // let y = 0;
        for (let i = 0; i <= 1; i++) {
            minYear = minYear + 1;
            this.year_propies.push({
                value: minYear % 100,
                year: minYear,
            });
        }
        var maxYear = new Date().getFullYear() + 1;
        // y = 0;
        for (let i = 1; i <= 5; i++) {
            maxYear = maxYear + 1;
            this.year_propies.push({
                value: maxYear % 100,
                year: maxYear,
            });
        }
            
        setTimeout(() => {
            this.processCartSucursal(this.CARTS);
        }, 3000);

        const resp = await myApi.post('ecommerce/config_all',{});
        // console.log(resp.data);      
        this.cuentas = resp.data.cuentas;
        this.today = resp.data.today;
        this.next_name_day = resp.data.next_name_day;
        this.next_num_day = resp.data.next_num_day;
        this.next_name_two_day = resp.data.next_name_two_day;
        this.next_num_two_day = resp.data.next_num_two_day;
        this.next_num_month = resp.data.next_num_month;
        setTimeout(() => {
            // 
            const script2 = document.createElement('script');
            script2.src = '../../assets/js/checkout.js';
            document.body.appendChild(script2);
            script2.onload = () => {
                console.log('archivo.js cargado 2');
            }
        }, 50);

    },
}
</script>
<style>
.collapcheckout:before {
    content: none !important;
}
.collapcheckout {
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    margin-top: 27px;
    background: #293152;
    border-radius: 20px;
}
.message-warning {
    background: #fedc327a !important;
    border-bottom: 3px solid #f7d00e7a !important;
}
.message-alert-checkout {
    padding: 12px 23px;
    margin-top: 2%;
}
/* .sucursales-flex2{
    display: flex;
    column-gap: 15px;
    justify-content: center;
    margin-top: 5%;
}
.sucursales-flex2 input[type=checkbox] {
    display: none;
}
.sucursales-checked img {
    border: 1px solid #4184F3 !important;
    border-radius: 25px !important;
    padding: 10px !important;
} */
</style>