<template>
    <div class="modal view-product fade" id="viewTerminos" tabindex="-1" aria-labelledby="viewTerminos" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addNewAddressLabel">TERMINOS Y CONDICIONES </h5>
                <!-- <span class="close-modal" data-bs-dismiss="modal"><i data-feather="x"></i></span> -->
            </div>
            <div class="modal-body">
                <span class="close-modal d-none d-md-block" data-bs-toggle="modal" data-bs-target="#viewTerminos"><i data-feather="x"></i></span>
                <div class="row gy-4 g-md-0 justify-content-center">
                    <div class="col-12">
                        <div class="d-flex align-items-center justify-content-center">
                            <input type="checkbox" id="acepte_terminos" @click="selectedStar()" name="acepte_terminos">
                            <label for="acepte_terminos" class="inside mb-0 mx-2">
                                Acepto los <a href="#" data-bs-toggle="modal" data-bs-target="#viewTerminosPages"  onclick="return false;" class="text-primary" target="_blank">términos y condiciones de pago</a> , 
                            <a href="#" data-bs-toggle="modal" data-bs-target="#viewEntregasEnviosPages"  onclick="return false;" class="text-primary" target="_blank">envío</a>  y 
                            <a href="#" data-bs-toggle="modal" data-bs-target="#viewPloticaGarantiaPages" class="text-primary" target="_blank">garantías</a>  al realizar la compra 
                                <!-- <a href="/termino-y-condiciones-de-uso" class="text-primary" target="_blank">términos y condiciones de pago</a> , <a href="/entregas-y-envios" class="text-primary" target="_blank">envío</a>  y <a href="/politica-de-garantia" class="text-primary" target="_blank">garantías</a>  al realizar la compra -->
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="btn-box">
                    <button type="button" class="btn btn-outline rounded-pill" data-bs-dismiss="modal" aria-label="Close">Cerrar</button>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    name: 'ModalTerminos',
    data(){
        return {
            product_selected_modal:null,
            acepte_terminos:null,
        }
    },
    methods:{
        selectedStar(){
            this.acepte_terminos = this.acepte_terminos ? null: 1;
            this.emitter.emit("acept-terminos", this.acepte_terminos);
        },
    },
    mounted(){
    }
}
</script>