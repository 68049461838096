<template>
    <div>
        <section class="shop-page">
            <div class="container-custom">
                <div class="catagories-section mt-0" v-if="is_tienda">
                    <div class="swiper-box">
                    <div class="swiper shop-catagories-slider">
                        <div class="swiper-wrapper">
                        <template v-for="(categorie, index) in categories" :key="index">
                            <div class="swiper-slide">
                            <div class="catagories catagories-shop" :class="{'active_categorie': categorie_selected == categorie.id}">
                                <a :href="'/shop/categorie/'+categorie.id+'?text='+categorie.name">
                                <div class="img-wrap">
                                    <img :src="categorie.miniatura" alt="zd" />
                                </div>
                                <div class="btn-box">
                                    <span class="catagories-name"> {{ categorie.name }} </span>
                                </div>
                                </a>
                            </div>
                            </div>
                        </template>
                        </div>
                    </div>
                    <div class="swiper-button">
                        <button class="swiper-next">
                        <i data-feather="arrow-left"></i>
                        </button>
                        <button class="swiper-prev"><i data-feather="arrow-right"></i></button>
                    </div>
                    </div>
                </div>
                <div v-if="categories.length == 0">
                    <img :src="'/assets/svg_load/shop-subcategorias-preloader.svg'" alt="Icono SVG" />
                </div>
                <div class="title-search" v-if="slug_product">
                    <h5>Resultados de: <span class="blue-title"> {{ slug_product }}</span></h5>
                </div>

                <div class="row gy-4 g-lg-3 g-xxl-4">
                    <div class="col-4 col-xl-3 sidebar-controll sidebar-responsive">
                    <div class="sidebar-inner sticky">
                        <div class="back-box d-flex d-lg-none">
                        <span>Back</span>
                        <span><i data-feather="x"></i></span>
                        </div>

                        <div class="row gy-3 gx-0 g-lg-4">

                        <div class="col-lg-12">
                            <div class="sidebar-box">
                            <div class="title-box4">
                                <h4 class="heading">FILTROS </h4>
                            </div>

                            <div class="range-slider">
                                <div class="price-input">
                                <div class="field">
                                    <span>Min <strong class="theme-color"> $</strong></span>
                                    <input class="form-control input-min" ref="min_price" type="number" value="0" />
                                </div>
                                <div class="separator">-</div>
                                <div class="field">
                                    <span>Max <strong class="theme-color"> $</strong></span>
                                    <input class="form-control input-max" ref="max_price" type="number" value="100" />
                                </div>
                                </div>
                                <div class="slider">
                                <div class="progress" style="left: auto;right: auto;"></div>
                                </div>
                                <div class="range-input">
                                <input type="range" class="range-min" min="0" @change="changeRange" max="2000" value="0" step="100" />
                                <input type="range" class="range-max" min="0" @change="changeRange" max="2000" value="100" step="100" />
                                </div>
                            </div>

                            <div class="filter-option py-3">
                                <h5>MARCAS</h5>
                                <div class="filter-content">
                                    <ul class="filter-check">
                                        <template v-for="(marca, index) in marca_options" :key="index">
                                        <li>
                                            <label class="checkboxes style-1">
                                                <input type="checkbox" :name="'checked'+marca.marcas_id" @click="selectedMarca(marca)" />
                                                <span class="checkbox__checkmark"></span>
                                                <span class="checkbox__body">{{ marca.marcas_name }} <span> {{ marca.cantidad_productos }}</span>
                                                </span>
                                            </label>
                                        </li>
                                        </template> 
                                    </ul>
                                </div>
                            </div>

                            <template v-for="(attribute, index) in attributes" :key="index">
                                <div class="filter-option">
                                    <h5>{{ attribute.name }}</h5>
                                    <div class="filter-content">
                                    <ul class="filter-check">
                                        <template v-for="(subattribute, index2) in attribute.attributes" :key="index2">
                                            <li>
                                            <label class="checkboxes style-1">
                                                <input type="checkbox" :name="'checked'+subattribute.id" @click="selectedAttributes(subattribute)" />
                                                <span class="checkbox__checkmark"></span>
                                                <span class="checkbox__body">{{ subattribute.name }} 
                                                    <!-- <span> 25</span> -->
                                                </span>
                                            </label>
                                            </li>
                                        </template>
                                        
                                    </ul>
                                    </div>
                                </div>
                            </template>
                                

                            </div>
                        </div>

                        </div>
                    </div>
                    </div>

                    <div class="col-lg-8 col-xl-9 list-pc" v-if="!is_initial">
                        <!-- <div class="row gy-5 g-lg-3 g-xxl-4"> -->
                        
                        <!-- <div class="col-12 order-1 order-lg-2"> -->
                        <div class="shop-product">
                            <div class="top-header-wrap">

                            <div class="grid-option-wrap">
                                <div class="results-p">
                                <p>Se han encontrado {{ total ? total : '-' }} productos</p>
                                </div>
                                <button class="filter-btn btn-solid btn-sm mb-line d-flex d-lg-none">Filtros</button>

                                <div class="select-options">
                                <div class="select-menu">
                                    <div class="dropdown select-dropdown">
                                    <button class="select-showval" id="sortProduct"><span>Ordernar por</span> <i data-feather="chevron-down"></i></button>
                                    <ul class="onhover-show-div select-vallist">
                                        <li class="select-list" @click="tagSelect(1)">Todos</li>
                                        <li class="select-list" @click="tagSelect(2)" v-if="!slug_tags">Nuevo</li>
                                        <li class="select-list" @click="tagSelect(3)" v-if="!slug_tags">Destacado</li>
                                        <li class="select-list" @click="tagSelect(4)" v-if="!slug_tags">Reingreso</li>
                                        <li class="select-list" @click="tagSelect(5)">Precio más bajo</li>
                                        <li class="select-list" @click="tagSelect(6)">Precio más alto</li>
                                    </ul>
                                    </div>
                                    <div class="dropdown select-dropdown small-dropdown">
                                    <button class="select-showval" id="featureProduct"><span>30 Registros</span> <i data-feather="chevron-down"></i></button>
                                    <ul class="onhover-show-div select-vallist">
                                        <li class="select-list" @click="sizePage(30)">30 Registros</li>
                                        <li class="select-list" @click="sizePage(45)">45 Registros</li>
                                        <li class="select-list" @click="sizePage(60)">60 Registros</li>
                                    </ul>
                                    </div>
                                </div>
                                </div>

                                
                            </div>
                            </div>

                        </div>
                        <!-- </div> -->

                        <div class="col-12" v-if="!is_view_loading">
                            <img :src="'/assets/svg_load/shop-4cards-preloader.svg'" style="width: 100%;" alt="Icono SVG" />
                        </div>
                        <div class="col-12" v-if="!is_view_loading">
                            <img :src="'/assets/svg_load/shop-4cards-preloader.svg'" style="width: 100%;" alt="Icono SVG" />
                        </div>

                        <div class="col-12" v-if="is_view_loading && products.length == 0">
                            <section class="not-found-list">
                                <img :src="'/assets/svg_load/notfount-pcbuilder-2.svg'" alt="icon" />
                            </section>
                        </div>

                        <div class="cart-wrap" v-if="products.length > 0">
                            <div class="items-list ">
                                <table class="table cart-table m-md-0">
                                    <thead>
                                        <tr>
                                            <th>PRODUCTO</th>
                                            <th></th>
                                            <th class=""></th>
                                            <th class=""></th>
                                            <th class=""></th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        <tr v-for="(product, index) in products" :key="index">

                                            <td class="item-row-wrapper">
                                                <div class="list-section view-option row g-3 g-xl-4 ratio_asos">
                                                    <div class="product-card">
                                                        <div class="img-box">
                                                            <!-- Thumbnail -->


                                                            <a class="primary-img"><img class="img-fluid bg-img"
                                                                    :src="product.image"
                                                                    alt="product" />
                                                            </a>

                                                        </div>

                                                        <!-- Content Box -->
                                                        <div class="content-box">
                                                            <a>
                                                                <p>{{ product.marca.name }}</p>
                                                                <h5>{{ product.title }}</h5>
                                                                <span>$ {{ getAmountTotal(product) }} </span> 
                                                                <del v-if="product.discount_g || product.code_offer">$ {{ getAmountDiscount(product) }} </del>
                                                                <p class="details truncate-3">
                                                                    {{ product.summary }}
                                                                </p>
                                                            </a>

                                                            <div class="options-list">
                                                                <a class="btn btn-solid btn-sm mb-line addtocart-btn" @click="pcBuildSelectedProduct(product)">Añadir
                                                                </a>
                                                                <li data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    title="Ver">
                                                                    <a href="javascript:void(0)" @click="openDetailProduct(product)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                                                        <!-- <i data-feather="zoom-in"></i> -->
                                                                        <img :src="'/assets/images/pcbuild/lupa.svg'" style="width:24px !important;height: auto !important;" alt="Icono SVG" />
                                                                    </a>
                                                                </li>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="total">
                                                {{ getAttributeName(product,0) }}
                                            </td>
                                            <td class="total">
                                                {{ getAttributeName(product,1) }}
                                            </td>
                                            <td class="total">
                                                {{ getAttributeName(product,2) }}
                                            </td>
                                            <td class="total">
                                                {{ getAttributeName(product,3) }}
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                        
                        <!-- </div> -->
                    </div>

                    <div class="col-lg-8 col-xl-9 list-pc" v-if="![7,8,'7','8'].includes(atribute) && is_view_loading && is_initial">
                        <section class="catagories-style-3">
                            <div class="container-lg">
                                <div class="title-box">
                                    <h3 class="unique-heading">Selecciona marca de procesador</h3>
                                </div>
                                <div class="swiper-box2">
                                    <div class="swiper-slide2">
                                        <div class="catagories-box">
                                            <a href="#" onclick="return false;" @click="selectedFilterP(2)">
                                            <img src="/assets/images/pcbuild/AMD.svg" alt="icon" />
                                            </a>
                                        </div>
                                    </div>

                                    <div class="swiper-slide2">
                                        <div class="catagories-box">
                                            <a href="#" onclick="return false;" @click="selectedFilterP(1)">
                                            <img src="/assets/images/pcbuild/Intel.svg" alt="icon" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="title-box">
                                            <h3 class="unique-heading">PC Build recomendados</h3>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="container-lg">
                                            <div class="swiper-box">
                                            <div class="swiper kits-slider-home">
                                                <div class="swiper-wrapper">
                                                    <div class="swiper-slide" v-for="product in kits" :key="product._id">
                                                        <CardKitA v-if="product" :product="product" 
                                                        :pc_build_attribute="true" 
                                                        :ip_publica="ip_publica" :type_card="1"
                                                        :pc_build_user_selected="pc_build_user_selected"
                                                        ></CardKitA>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="swiper-button">
                                                <button class="swiper-next">
                                                <i data-feather="arrow-left"></i>
                                                </button>
                                                <button class="swiper-prev"><i data-feather="arrow-right"></i></button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div class="col-lg-8 col-xl-9 list-pc" v-if="atribute == 7 && is_view_loading && is_initial">
                        <section class="catagories-style-3">
                            <div class="container-lg">
                                <div class="title-box">
                                    <h3 class="unique-heading">Selecciona un almacenamiento</h3>
                                </div>
                                <div class="swiper-box2">
                                    <div class="swiper-slide2">
                                        <div class="catagories-box">
                                            <a href="#" onclick="return false;" @click="selectedFilterG(1)">
                                                <img src="/assets/images/pcbuild/filtro/1_disco_duro.svg" alt="icon" />
                                                <p>Disco duro</p>                                            
                                            </a>
                                        </div>
                                    </div>

                                    <div class="swiper-slide2">
                                        <div class="catagories-box">
                                            <a href="#" onclick="return false;" @click="selectedFilterG(2)">
                                            <img src="/assets/images/pcbuild/filtro/2_ssd_sata-02.svg" alt="icon" />
                                                <p>SSD Data</p>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="swiper-slide2">
                                        <div class="catagories-box">
                                            <a href="#" onclick="return false;" @click="selectedFilterG(3)">
                                            <img src="/assets/images/pcbuild/filtro/3_m2_horizontal.svg" alt="icon" />
                                                <p>SSD M.2</p>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>

                    <div class="col-lg-8 col-xl-9 list-pc" v-if="atribute == 8 && is_view_loading && is_initial">
                        <section class="catagories-style-3">
                            <div class="container-lg">
                                <div class="title-box">
                                    <h3 class="unique-heading">Selecciona un enfriamiento</h3>
                                </div>
                                <div class="swiper-box2">
                                    <div class="swiper-slide2">
                                        <div class="catagories-box">
                                            <a href="#" onclick="return false;" @click="selectedFilterG(1)">
                                                <img src="/assets/images/pcbuild/filtro/4_enfriamiento_aire.svg" alt="icon" />
                                                <p>Enfriamiento por Aire</p>                                            
                                            </a>
                                        </div>
                                    </div>

                                    <div class="swiper-slide2">
                                        <div class="catagories-box">
                                            <a href="#" onclick="return false;" @click="selectedFilterG(2)">
                                            <img src="/assets/images/pcbuild/filtro/5_enfriamiento_liquido.svg" alt="icon" />
                                                <p>Enfriamiento Líquido</p>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="swiper-slide2">
                                        <div class="catagories-box">
                                            <a href="#" onclick="return false;" @click="selectedFilterG(3)">
                                            <img src="/assets/images/pcbuild/filtro/6_ventilacion_case.svg" alt="icon" />
                                                <p>Ventilación para Case</p>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                    

                    <div class="col-12 order-1 order-lg-2 px-2 py-2" v-if="is_view_loading && !is_initial">
                        <PaginationG
                            :pagination="{
                                            per_page: per_page,
                                            total: total,
                                            total_pages: total_pages
                                        }"
                            :maxVisibleButtons="maxVisibleButtons"
                            :current-page="currentPage"
                            @pagechanged="listFilters"/>
                    </div>

                </div>
            </div>
        </section>
        <!-- Shop Section End -->
        <DetailProduct />

        <div class="sidenav right" v-if="option_side_nav == 2">
            <div class="pc-builder-header">
                <div class="first-group">
                    <div class="arrow-sidebar">
                        <img @click="option_side_nav = 1" src="/assets/svg/arrow-right-1-svgrepo-com.svg" width="" alt="">
                    </div>

                    
                </div>
                <div class="items-result-list">
                    <p>&nbsp; Items <span>({{ attribute_selected.length }})</span> &nbsp; Total <span class="color-price-list">${{ TOTAL_SUM_PC_BUILD }}</span>
                    </p>
                </div>
                <!-- <button class="btn btn-dark btn-sm" @click="show_new = !show_new">+ Crear lista</button>
                <input class="lista-input" type="text" v-if="show_new" v-model="name_pc_build"
                                        v-on:keyup.enter="add_pc_builder_user" placeholder="Escribir...">
                <button class="btn-line" @click="add_pc_builder_user()" v-if="show_new">Guardar</button> -->

            </div>
            <div class="scroll-info-list">
                <div class="pc-builder-info">
                    <div class="pc-builder-info-wrap">
                        <div class="pc-builder-name">
                            <div class="pc-builder-name-text">
                                <strong>Mis listas </strong>
                                <button class="btn btn-dark btn-sm" @click="show_new = !show_new">+ Crear lista</button>
                                <!-- <p>&nbsp; Items <span>({{ attribute_selected.length }})</span> &nbsp; Total <span
                                        class="color-price-list">$ {{ TOTAL_SUM_PC_BUILD }}</span> </p> -->
                                <div class="flex-input-list">
                                    <input class="lista-input" type="text"  v-if="show_new" v-model="name_pc_build"
                                                    v-on:keyup.enter="add_pc_builder_user" placeholder="Escribir...">

                                    <button class="btn-line" @click="add_pc_builder_user()" v-if="show_new">Guardar</button>
                                </div>
                            </div>
                            <div class="d-flex-this">
                                <div class="tag-pc">
    
                                    <span v-for="(pc_build_user, index) in pc_build_users" :key="index" :class="{'': pc_build_user_selected.id != pc_build_user.id,'bg-primary-s': pc_build_user_selected.id == pc_build_user.id}"><a href="#" onclick="return false;" @click="seletec_pc_build_user(pc_build_user)" >{{ pc_build_user.name.length > 15 ? pc_build_user.name.substring(0, 15) : pc_build_user.name }}</a>
                                        <div class="actions-tags">
                                            <a class="is-remove open-popup" href="#" onclick="return false;" data-bs-toggle="modal" data-bs-target="#modalDeletePc" @click="selected_modal = pc_build_user"><svg xmlns="http://www.w3.org/2000/svg"
                                                    width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-trash">
                                                    <polyline points="3 6 5 6 21 6"></polyline>
                                                    <path
                                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                                    </path>
                                                </svg></a>
                                        </div>
                                    </span>
                                   
                                </div>
    
                            </div>
    
                        </div>
    
                    </div>
                </div>
    
                <div class="pc-builder-th">
                    <div class="pc-builder-td">Componente</div>
                    <div class="pc-builder-td">Producto seleccionado</div>
                </div>
                <template v-for="(pc_build_attr, index) in pc_build_attributes" :key="index">
                    <div class="nav nav-padding" :class="{'bg-success' : atribute == pc_build_attr.id}">
                        <div class="container" v-if="pc_build_attr.product_selected">
                            <div class="row align-items-center row-line justify-content-between">
                                <div class="col-2 fa fa-home" @click="selectedPcBuildAttribute(pc_build_attr)"><img :src="pc_build_attr.imagen" width="32" alt="">
                                    <p class="text-dark">{{ pc_build_attr.name }}</p>
                                </div>
                                <div class="col-6 body">
    
                                    <div class="product-card-side bg-theme-p-side-2" v-if="pc_build_attr.product_selected">
                                        <div class="img-wrap">
                                            <a :href="'/product/'+pc_build_attr.product_selected.slug">
                                                <img class="img-fluid" :src="pc_build_attr.product_selected.image" alt="bag">
                                            </a>
                                        </div>
                                        <div class="content-box mb-0">
                                            <a :href="'/product/'+pc_build_attr.product_selected.slug">
                                                <p>{{ pc_build_attr.product_selected.marca.name }}</p>
                                                <h5 class="truncate-2">{{ pc_build_attr.product_selected.title }}</h5>
                                                <span >$ {{ getAmountTotal(pc_build_attr.product_selected) }}</span> 
                                                <del v-if="pc_build_attr.product_selected.discount_g">$ {{ getAmountDiscount(pc_build_attr.product_selected) }}</del>
                                            </a>
                                            <!-- <div class="rating">
                                                <span>{{ pc_build_attr.product_selected.avg_rating ? pc_build_attr.product_selected.avg_rating : 0 }} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg> </span>
                                                <span>{{ pc_build_attr.product_selected.reviews_count }}</span>
                                            </div> -->
                                            <!-- <div v-if="pc_build_attr.product_selected.discount">
                                                <span v-if="pc_build_attr.product_selected.type_discount == 1">Descuento: {{ pc_build_attr.product_selected.discount }} %</span>
                                                <span v-if="pc_build_attr.product_selected.type_discount == 2">Descuento: - $ {{ pc_build_attr.product_selected.discount }} </span>
                                            </div> -->
                                        </div>
                                    </div>
    
                                </div>
                                <div class="col-2 body">
                                    <div class="plus-minus mt-2 d-inline-flex " v-if="pc_build_attr.product_selected">
                                        <a href="javascript:void(0)" @click="minusCart(pc_build_attr)"> 
                                            <!-- <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 24 24"
                                            style="fill:#FFFFFF;" class="feather feather-minus sub"><line x1="5" y1="12" x2="19" y2="12"></line></svg> -->
                                            <img src="/assets/svg/minus.svg" style="width: 20px;" alt="">
                                        </a>
                                        <input type="number" name="count" :value="pc_build_attr.quantity" min="1" max="10" />
                                        <a href="javascript:void(0)" @click="plusCart(pc_build_attr)"> 
                                            <!-- <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 24 24"
                                            style="fill:#FFFFFF;">
                                            <path fill-rule="evenodd" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"></path>
                                            </svg> -->
                                            <img src="/assets/svg/plus.svg" style="width: 20px;" alt="">
                                        </a>
                                    </div>
                                </div>
                                <div class="col-2 body text-dark text-center">
                                    <div class="row action-price-list" v-if="pc_build_attr.product_selected">
                                        <div class="col-12">
                                            $ {{ pc_build_attr.total }}
                                        </div>
                                        <div class="col-12 d-flex justify-content-center">
                                            <button class="btn btn-danger btn-sm" @click="deleteCardItem(pc_build_attr)">X</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container" v-if="!pc_build_attr.product_selected" @click="selectedPcBuildAttribute(pc_build_attr)">
                            <div class="row align-items-center row-line justify-content-between">
                                <div class="col-2 fa fa-home" @click="selectedPcBuildAttribute(pc_build_attr)"><img :src="pc_build_attr.imagen" width="32" alt="">
                                    <p class="text-dark">{{ pc_build_attr.name }}</p>
                                </div>
                                <div class="col-6 body">
    
                                <button class="btn btn-primary btn-sm"  @click="selectedPcBuildAttribute(pc_build_attr)">+ Seleccionar</button>
                                </div>
                                <div class="col-2 body">
                                
                                </div>
                                <div class="col-2 body text-dark text-center">
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
    
                <div class="contentbar" v-if="false">
                    <div class="aux">
                        <div class="container">
                            <div class="row align-items-center row-line justify-content-between">
                                <!-- <div class="col-3"></div> -->
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-4">
                                            <button class="btn btn-dark btn-sm" @click="show_new = !show_new">Nueva</button>
                                        </div>
                                        <div class="col-8">
                                            <button class="btn btn-success btn-sm" @click="addCart()">Agregar al Carrito</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-1">
                                </div> -->
                                <div class="col-3" v-if="show_new">
                                    <input
                                            class="form-control text-white"
                                            type="text"
                                            placeholder="Nombre....."
                                            name="name"
                                            v-model="name_pc_build"
                                            v-on:keyup.enter="add_pc_builder_user"
                                        />
                                </div>
    
                                <div class="col-1 body">
                                    <img @click="option_side_nav = 1" src="/assets/svg/arrow-right-1-svgrepo-com.svg" width="" alt="">
                                    <!-- <button class="btn btn-dark btn-sm" @click="option_side_nav = 1">X</button> -->
                                </div>
    
                            </div>
                        </div>
                    </div>
                    <div class="aux">
                        <div class="container">
                            <div class="row align-items-center row-line justify-content-between">
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-4 d-flex" v-for="(pc_build_user, index) in pc_build_users" :key="index">
                                            <button class="btn btn-sm mb-0" @click="seletec_pc_build_user(pc_build_user)" :class="{'btn-primary': pc_build_user_selected.id != pc_build_user.id,'btn-warning': pc_build_user_selected.id == pc_build_user.id}">
                                                <h5 class="text-white"> <small>{{ pc_build_user.name.length > 15 ? pc_build_user.name.substring(0, 15) : pc_build_user.name }}</small> </h5>
                                            </button>
                                            <button class="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#modalDeletePc" @click="selected_modal = pc_build_user">X</button>
                                        </div>
                                    </div>
                                </div>
    
    
                                <div class="col-4 body">
                                    <div class="order-summery-box">
                                        <ul class="order-summery">
                                            <li class="pb-0 text-dark"><span>Subtotal </span>
                                                <span>$ {{ TOTAL_SUM_PC_BUILD }}</span>
                                            </li>
                                        </ul>
                                        <!-- <p class="price-total">Total a Pagar $</p> -->
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
    
                    <div class="aux my-2">
                        <div class="container">
                            <div class="row align-items-center row-line justify-content-between">
                                <div class="col-2 fa fa-home">
                                    <p class="text-dark">Componente</p>
                                </div>
                                <div class="col-6 body">
                                    <p class="text-dark"> Producto</p>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <template v-for="(pc_build_attr, index) in pc_build_attributes" :key="index">
                        <div class="nav" :class="{'bg-success' : atribute == pc_build_attr.id}">
                            <div class="container">
                                <div class="row align-items-center row-line justify-content-between">
                                    <div class="col-2 fa fa-home" @click="selectedPcBuildAttribute(pc_build_attr)">
                                        <img :src="pc_build_attr.imagen" width="32" alt="">
                                        <p class="text-dark">{{ pc_build_attr.name }}</p>
                                    </div>
                                    <div class="col-6 body">
                                        <button class="btn btn-primary btn-sm" v-if="!pc_build_attr.product_selected" @click="selectedPcBuildAttribute(pc_build_attr)">+ Seleccionar</button>
    
                                        <div class="row" v-if="!pc_build_attr.product_selected && (sub_attrs_pc.length > 0 && atribute == pc_build_attr.id)">
                                            <div class="col-12">
                                                <p>Necesitas seleccionar una opción: </p>
                                            </div>
                                            <div class="col-3" v-for="(sub_attr, index) in sub_attrs_pc" :key="index">
                                                <label>
                                                    <input type="radio" name="sub_attribut" :value="sub_attr.tipo" @click="selectedTypeSubAttribute(sub_attr)">
                                                    <p>{{ sub_attr.name }}</p>
                                                </label>
                                            </div>
                                        </div>
                                        
    
                                        <div class="product-card-side bg-theme-p-side-2" v-if="pc_build_attr.product_selected">
                                            <div class="img-wrap">
                                                <a :href="'/product/'+pc_build_attr.product_selected.slug">
                                                    <img class="img-fluid" :src="pc_build_attr.product_selected.image" alt="bag">
                                                </a>
                                            </div>
                                            <div class="content-box mb-0">
                                                <a :href="'/product/'+pc_build_attr.product_selected.slug">
                                                    <p>{{ pc_build_attr.product_selected.marca.name }}</p>
                                                    <h5 class="truncate-2">{{ pc_build_attr.product_selected.title }}</h5>
                                                    <span >$ {{ getAmountTotal(pc_build_attr.product_selected) }}</span> 
                                                    <del v-if="pc_build_attr.product_selected.discount_g">$ {{ getAmountDiscount(pc_build_attr.product_selected) }}</del>
                                                </a>
                                                <div class="rating">
                                                    <span>{{ pc_build_attr.product_selected.avg_rating ? pc_build_attr.product_selected.avg_rating : 0 }} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg> </span>
                                                    <span>{{ pc_build_attr.product_selected.reviews_count }}</span>
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                    <div class="col-2 body">
                                        <div class="plus-minus mt-2 d-inline-flex " v-if="pc_build_attr.product_selected">
                                            <a href="javascript:void(0)" @click="minusCart(pc_build_attr)"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 24 24"
                                                style="fill:#FFFFFF;">
                                                <path fill-rule="evenodd" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"></path>
                                                </svg>
                                            </a>
                                            <input type="number" name="count" :value="pc_build_attr.quantity" min="1" max="10" />
                                            <a href="javascript:void(0)" @click="plusCart(pc_build_attr)"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 24 24"
                                                style="fill:#FFFFFF;">
                                                <path fill-rule="evenodd" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"></path>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-2 body text-dark text-center">
                                        <div class="row" v-if="pc_build_attr.product_selected">
                                            <div class="col-12">
                                                $ {{ pc_build_attr.total }}
                                            </div>
                                            <div class="col-12 d-flex justify-content-center">
                                                <button class="btn btn-danger btn-sm" @click="deleteCardItem(pc_build_attr)">X</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div class="modal-footer-pc">
                <div class="modal-footer-actions">
                    <div class="modal-footer-action">
                        <div class="note-text">
                            <p>NOTA: La potencia de la fuente de alimentación que recomendamos solo le brinda
                                una idea general sobre qué considerar al seleccionar una fuente de alimentación.
                                Las tarjetas PCI, ventiladores de refrigeración y otros componentes pueden necesitar
                                más energía.
                                Antes de comprar, le recomendamos que verifique todas las especificaciones, como las
                                dimensiones físicas
                                y los detalles del artículo, para garantizar una compatibilidad del 100%.</p>
                        </div>
                        <div class="modal-footer-savings">
                            <div class="modal-footer-saving d-flex">
                                <div class="mx-3" v-if="TOTAL_SUM_ORIGINAL_PC_BUILD != TOTAL_SUM_PC_BUILD">
                                    <span> SubTotal:</span>
                                    <strong> ${{ TOTAL_SUM_ORIGINAL_PC_BUILD }}</strong>
                                </div>
                                <div class="mx-3" v-if="TOTAL_SUM_ORIGINAL_PC_BUILD != TOTAL_SUM_PC_BUILD">
                                    <span> Ahorro:</span>
                                    <strong> ${{ ((TOTAL_SUM_ORIGINAL_PC_BUILD - TOTAL_SUM_PC_BUILD).toFixed(2)) }}</strong>
                                </div>
                                <div>
                                    <span> Total:</span>
                                    <strong> ${{ TOTAL_SUM_PC_BUILD }}</strong>
                                </div>
                            </div>
                        <div class="final-list">
                            <button class="btn-outline" @click="resetPcBuilder()">Limpiar </button>
                            <button class="btn-sesion" v-if="!is_loading_carts && !show_go_cart" @click="goCart()">Ir al carrito <img src="/assets/images/Nuevos/cart-icon.webp" class="mx-2" style="filter: invert(1);"> </button>
                            <div class="loader-5 center" v-if="is_loading_carts"><span></span></div>
                            <button class="btn-sesion" data-bs-toggle="modal" data-bs-target="#viewTerminosPcBuild" v-if="!is_loading_carts && show_go_cart">Agregar al carrito </button> </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="sidenav-short right" v-if="option_side_nav == 1">
            <div class="contentbar my-2">
                <div class="aux">
                </div>
                <div class="aux">
                    <div class="container">
                        <div class="row align-items-center row-line">
                            <div class="arrow-sidebar">
                                <img @click="option_side_nav = 2" src="/assets/svg/arrow-left-svgrepo-com.svg" width="" alt="">
                                <!-- <button class="btn btn-dark btn-sm" @click="option_side_nav = 2">+</button> -->
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row align-items-center row-line">
                            <div class="col-12 fa fa-home py-1">
                                <h6 class="text-dark text-center" v-if="!pc_build_user_selected"> <b>Mi Nueva Pc</b> </h6>
                                <h6 class="text-dark text-center" v-if="pc_build_user_selected"> <b>{{ pc_build_user_selected.name.length > 15 ? pc_build_user_selected.name.substring(0, 15) : pc_build_user_selected.name }} </b> </h6>
                            </div>

                            <div class="col-12 py-1">
                                <div class="order-summery-box">
                                    <ul class="order-summery">
                                        <li class="pb-0 text-dark">
                                            <!-- <br> -->
                                            <span>$ {{ TOTAL_SUM_PC_BUILD }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <template v-for="(pc_build_attr, index) in pc_build_attributes" :key="index">
                    <div class="nav" :class="{'bg-success' : atribute == pc_build_attr.id}" @click="selectedPcBuildAttribute(pc_build_attr)">
                        <div class="container">
                            <div class="row align-items-center row-line justify-content-between">
                                <div class="col-12 fa fa-home text-center">
                                    <img :src="pc_build_attr.imagen" width="32" alt="">
                                    <p class="text-dark">{{ pc_build_attr.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                
            </div>
        </div>
        
        <div class="modal view-product fade" id="modalDeletePc" tabindex="-1" aria-labelledby="modalDeletePc" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content modal-sm" v-if="selected_modal" style="width: auto;">
                    <div class="modal-header p-0">
                        <span class="close-modal" data-bs-toggle="modal" data-bs-dismiss="modal"><i data-feather="x"></i></span>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <h5>¿SEGURO DE ELIMINAR ESTE PC : {{ selected_modal.name }}?</h5>
                            </div>
                            <div class="col-12 d-flex my-3">
                                <button class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-dismiss="modal" @click="deleteSegment()">Eliminar</button>
                                <button class="btn btn-dark btn-sm mx-2" data-bs-toggle="modal" data-bs-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ConfirmedCart></ConfirmedCart>
    </div>
</template>
<style>
    .bg-primary-s{
        font-size: 19px;
        padding: 10px 20px;
        border-radius: 10px;
        background: #3b73f1!important;
        border: none;
        font-weight: bold;
    }
    .sidenav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        right: 0px;
        top: 0px;
        /* top: 20vh; */
        height: 100vh;
        width: 860px;
        background: #ffffff;
        /*  */
        z-index: 1000;
    }
    .sidenav-short{
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        right: 0px;
        top: 0px;
        /* top: 20vh; */
        height: 100vh;
        width: 120px;
        background: #ffffff;
        /*  */
        z-index: 1000;
    }
    .contentbar{
        overflow: auto;
    }
    /* SCROLL */
    .contentbar::-webkit-scrollbar {
        width: 8px;     /* Tamaño del scroll en vertical */
        height: 8px;    /* Tamaño del scroll en horizontal */  /* Ocultar scroll */
    }
    /* Ponemos un color de fondo y redondeamos las esquinas del thumb */
    .contentbar::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 4px;
    }

    /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
    .contentbar::-webkit-scrollbar-thumb:hover {
        background: #b3b3b3;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }

    /* Cambiamos el fondo cuando esté en active */
    .contentbar::-webkit-scrollbar-thumb:active {
        background-color: #999999;
    }
    /* Ponemos un color de fondo y redondeamos las esquinas del track */
    .contentbar::-webkit-scrollbar-track {
        background: #e1e1e1;
        border-radius: 4px;
    }

    /* Cambiamos el fondo cuando esté en active o hover */
    .contentbar::-webkit-scrollbar-track:hover,
    .contentbar::-webkit-scrollbar-track:active {
        background: #d4d4d4;
    }
    /*  */
    .sidenav .nav {
      position: relative;
      /* display: inline; */
      align-items: center;
      z-index: 1000;
      left: 0px;
      transition: all 0.4s ease;
      cursor: pointer;

      height: 168px;
      max-height: 168px;
    }

    .sidenav-short .nav {
      position: relative;
      /* display: inline; */
      align-items: center;
      z-index: 1000;
      left: 0px;
      transition: all 0.4s ease;
      cursor: pointer;

      height: 100px;
      max-height: 100px;
    }

    .sidenav .aux,.sidenav-short .aux{
        position: relative;
        /* display: inline; */
        align-items: center;
        z-index: 1000;
        left: 0px;
        transition: all 0.4s ease;
        cursor: pointer;
    }
    .sidenav .nav .row-line{ 
        /* border: 1px solid #000; */
    }
    .sidenav .nav .row-line p {
        margin-bottom: 0 !important;
    }
    .sidenav .nav .row-line .fa-home {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sidenav .nav.active {
      left: -10px;
    }

    .contentbar .product-card-side{
        max-height: 168px;
        overflow: hidden;
    }
    .contentbar .img-fluid{
        height: 100% !important;
    }
    /* .sidenav .nav:hover {
      left: -85px;       */
      /* Border width*2 */
    /* } */

    .sidenav .description, .sidenav .icon {
        height: 120px;
        line-height: 120px;
        background: #ffffff;
    }

    .sidenav .nav.active .description, .sidenav .nav.active .icon {
        /* background: #FF5722; */
    }

    .sidenav .icon {
        width: 60px;          /* Width of navbar */
        text-align: center;
        color: #e9e9e9;
        font-size: 25px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    .sidenav .body { 
        position: relative;
    }
    .sidenav .description {
        position: relative;
        left: 60px;
        color: #000000;
        min-width: 80px;
        padding-left: 20px;
        height: 100%;
    }
</style>
<script>
import myApi from '../../plugins/axios'
// import CardProductA from '../../components/cards/CardProductA.vue'
import PaginationG from '../PaginationG.vue'
import DetailProduct from "../guest/modal/DetailProduct.vue";
import CartResource from '@/resource/cart';
import store from '../../plugins/store'
import ConfirmedCart from './modal/ConfirmedCart.vue'
import CardKitA from '../../components/cards/CardKitA.vue'

export default {
    name:'PcBuild',
    data(){
        return {
            marca_options: [],
            attributes: [],
            products: [],
            sub_attributes: [],
            search_product: null,
            min_price: null,
            max_price: null,
            // PAGINACIÓN
            currentPage:1,
            per_page:1,
            total:0,
            total_pages:1,
            maxVisibleButtons:3,
            marcas_selected: [],
            // 
            categories: [],
            CARTS:[],
            // 
            categorie_selected: null,
            size_page: 30,
            tag_selected:null,
            // 
            categories_selected: [],
            categorie_g_selected: null,
            sub_categorie_selected: null,
            INDEXBDS: [],
            // 
            pc_build_attributes: [],
            // 
            option_side_nav: 1,
            first_selected: 2,
            atribute: 1,
            sub_atribute:null,
            group_ids:[],
            attribute_selected:[],
            sub_attrs_pc:[],
            // 
            TOTAL_SUM_PC_BUILD:0,
            ip_publica: null,
            pc_build_users: [],
            pc_build_user_selected: null,
            name_pc_build: null,
            show_new: false,
            selected_modal: null,
            // 
            is_initial: null,
            is_view_productos: 1,
            is_view_loading: null,
            TOTAL_SUM_ORIGINAL_PC_BUILD: 0,
            // 
            is_loading_carts: false,
            kits:[],
            show_go_cart: true,
        }
    },
    components: {
    //   CardProductA,
      PaginationG,
      DetailProduct,
      ConfirmedCart,
      CardKitA,
    },
    props: ['categorie_id','is_marca_general',
    'marca_id','categorie_second_id','categorie_third_id',
      "categorie_selected_external","is_best_sellers"
    ],
    methods: {
        goCart(){
            this.$router.push({
                name: 'CartShop',
            })
        },
        getAttributeName(product,val){
            let atribute_ecommerce = product.atributos_ecommerce.length > val ? product.atributos_ecommerce[val] : null;
            if(atribute_ecommerce){
                return this.getAttributeN(atribute_ecommerce);
            }
            return "";
        },
        getAttributeN(attribute){
            if(attribute.value){
                let text_before = attribute.attribute_categorie.attribute.s_option == 1 ? attribute.attribute_categorie.attribute.value :  "";
                let text_after = attribute.attribute_categorie.attribute.s_option == 2 ? attribute.attribute_categorie.attribute.value : "";
                return  (text_before ? text_before : "") + " " + attribute.value + " " + (text_after ? text_after : "") ;
            }else if(attribute.sub_attributes.length > 0){ 
                let CONCAT = "";
                attribute.object_sub_attributes.forEach((SUBA) => {
                let text_before = SUBA.s_propiedad == 1 && SUBA.value_propiedad ? " "+ SUBA.value_propiedad :  "";
                let text_after = SUBA.s_propiedad == 2 && SUBA.value_propiedad ? " "+ SUBA.value_propiedad : "";
                CONCAT = CONCAT + (text_before+SUBA.name+text_after+ " / ")
                });
                return  CONCAT;
            }else{
                let text_before = attribute.attribute_sub.s_propiedad == 1 && attribute.attribute_sub.value_propiedad ? attribute.attribute_sub.value_propiedad :  "";
                let text_after = attribute.attribute_sub.s_propiedad == 2 ? attribute.attribute_sub.value_propiedad : "";
                return  text_before + " " + attribute.attribute_sub.name + " " + text_after ;
            }
        },
        sizePage(sizePage){
          this.size_page = sizePage;
          this.listFilters();
        },
        tagSelect(TAG){
          this.tag_selected = TAG;
          this.listFilters();
        },
        openDetailProduct(product){
            this.emitter.emit('product-detail-modal', product);
        },
        pcBuildSelectedProduct(product){
            this.emitter.emit('pc-build-product-selec', product);
        },
        minusCart(pc_build_attr){
            if(pc_build_attr.quantity <= 1){
                this.$notify({
                    text: 'Cantidad de producto no válida',//`AL PRODUCTO '${pc_build_attr.product_selected.title}' NO SE LE PUEDE DISMINUIR MAS!`,
                    type:'error',
                    duration: 3500,
                });
                return;
            }
            pc_build_attr.quantity --;
            pc_build_attr.total =  Number(pc_build_attr.subtotal)*pc_build_attr.quantity;

            let PC_ATTR = this.attribute_selected.find(item => item.id == pc_build_attr.pb_build_attr_id);
            if(PC_ATTR){
                PC_ATTR.quantity --;
                this.add_pc_builder_product(PC_ATTR,pc_build_attr.pb_build_user_id);
            }
            this.getTotalPcBuild();
        },
        plusCart(pc_build_attr){
            pc_build_attr.quantity ++;
            pc_build_attr.total =  Number(pc_build_attr.subtotal)*pc_build_attr.quantity;

            let PC_ATTR = this.attribute_selected.find(item => item.id == pc_build_attr.pb_build_attr_id);
            if(PC_ATTR){
                PC_ATTR.quantity ++;
                this.add_pc_builder_product(PC_ATTR,pc_build_attr.pb_build_user_id);
            }
            this.getTotalPcBuild();
        },
        selectedPcBuildAttribute(pc_build_attr){
            if(pc_build_attr.id == this.atribute){
                if(this.is_initial == 1){
                    this.$notify({
                        text: `NECESITAS SELECCIONAR UNA OPCIÓN DE FILTRO!`,
                        type:'error',
                        duration: 3500,
                    });
                    return;
                }
            }
            this.atribute = pc_build_attr.id;
            this.sub_attrs_pc = pc_build_attr.filters;
            if(this.sub_attrs_pc.length == 0){
                this.sub_atribute = null;
                this.listFilters();
                this.option_side_nav = 1;
                this.is_initial = null;
            }else{
                this.is_initial = 1;
            }
        },
        selectedTypeSubAttribute(sub_attr){
            this.sub_atribute = sub_attr.tipo;
            this.listFilters();
        },
        selectedFilterP(first_selected){
            this.first_selected = first_selected;
            this.update_pc_builder_user(this.pc_build_user_selected);
            this.is_initial = null;
            this.listFilters();
        },
        selectedFilterG(sub_atribute){
            this.sub_atribute = sub_atribute;
            this.is_initial = null;
            this.listFilters();
        },
        async listKits(){
          const resp = await myApi.get(
                "ecommerce/kits-pc-build");
            console.log(resp);
            this.kits = resp.data.kits.data;
        },
        async listFilters(page=1){
            let data = {

                first_selected: this.first_selected,
                atribute: this.atribute,
                sub_atribute: this.sub_atribute,
                group_ids: this.group_ids,
                attribute_selected: this.attribute_selected,


                search: this.search_product,
                categorie_id: this.categorie_selected,
                sub_attributes: this.sub_attributes,
                min_price: this.min_price,
                max_price: this.max_price,
                marcas_selected: this.marcas_selected,
                size_page: this.size_page,
                tag_selected: this.tag_selected,
                // 
                categorie_second_id: this.categorie_g_selected,
                categorie_third_id: this.sub_categorie_selected,
                // 
                is_marca_general: this.is_marca_general,
                //
                marca_id: this.marca_id,
                is_best_sellers: this.is_best_sellers,
            }
            this.currentPage = page;
            this.is_view_loading = null;
            const resp = await myApi.post(
                "ecommerce/pc_build/?page="+this.currentPage,data
            );
            console.log(resp);
            this.is_view_loading = true;
            this.products = resp.data.products.data;
            // return;
            // this.products = [];
            // this.products = resp.data.products.data.map(product => {
            //   let OFFER = resp.data.productos_offers.find((item) => item.product_id == product.id);
            //   if(OFFER){
            //     product.code_offer = OFFER;
            //   }
            //   if(resp.data.categories_offers.length > 0){
            //     resp.data.categories_offers.forEach(off => {
            //       product.code_offer = off;
            //     });
            //   }
            //   return product;
            // });
            // console.log(this.products);
            // // this.products = resp.data.products.data;
            // this.attributes = resp.data.attributes;
            this.marca_options = resp.data.marca_options.sort(function(a, b) {
                var textA = a.marcas_name.toUpperCase();
                var textB = b.marcas_name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            // // STORAGE_ATTR_MARCA
            // if(this.categorie_selected){
            //   const item = {
            //       value: this.attributes,
            //       expiry: new Date().getTime() + 3600000,
            //   }
            //   localStorage.setItem('attributes_shop_'+this.categorie_selected, JSON.stringify(item))
            //   const item2 = {
            //       value: this.marca_options,
            //       expiry: new Date().getTime() + 3600000,
            //   }
            //   localStorage.setItem('marcas_shop_'+this.categorie_selected, JSON.stringify(item2))
            //   // 
            //   let local_dinamics = localStorage.getItem("local_dinamic") || JSON.stringify([]);
            //   local_dinamics = JSON.parse(local_dinamics);
            //   local_dinamics.push('attributes_shop_'+this.categorie_selected)
            //   local_dinamics.push('marcas_shop_'+this.categorie_selected)
            //   localStorage.setItem("local_dinamic",JSON.stringify(local_dinamics))
            // }

            this.per_page = resp.data.per_page;
            this.total = resp.data.total;
            this.total_pages = resp.data.total_pages;
            this.maxVisibleButtons = this.total_pages > 4 ? 3 : this.total_pages;

            // this.categories_selected = resp.data.categorie;
            // if(this.categories_selected && this.categorie_selected){
            //   this.emitter.emit('categorie-selected', resp.data.categorie_father);
            // }
            // this.emitter.emit('categorie-selected-add', this.categories_selected);
            this.scrollTop();
            setTimeout(() => {
                // this.reloadMyJs()
                const detail_product = document.createElement("script");
                detail_product.src = "../../assets/js/myjs/swiperpcbuild.js";
                document.body.appendChild(detail_product);
                detail_product.onload = () => {
                    console.log("archivo.js cargado swiperPcBuild");
                };
            }, 50);
        },
        async filters_config_all(){
          const resp = await myApi.get(
                "ecommerce/filters_config_all/"
          );
          console.log(resp);
          this.categories = resp.data.categories;
          this.pc_build_attributes = resp.data.atributes;


          this.ip_publica = localStorage.getItem("ip_publica");
          if(!this.ip_publica){
            fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                console.log(data.ip);
                localStorage.setItem("ip_publica", data.ip+"-"+new Date().getTime());
                this.ip_publica = localStorage.getItem("ip_publica");
                this.pc_builder_users();
            })
            .catch(error => {
                console.error(error);
            })
          }
          if(this.ip_publica){
            this.pc_builder_users();
          }

        },
        async pc_builder_users(pc_build_user=null){
          const resp = await myApi.get(
                "ecommerce/pc_builders?ip="+this.ip_publica
          );
          console.log(resp);
          this.pc_build_users = resp.data.pc_build_users;
          if(!pc_build_user){
              if(this.pc_build_users.length == 0){
                this.add_pc_builder_user(2);
                this.is_initial = 1;
                this.option_side_nav = 1;
              }else{
                this.seletec_pc_build_user(this.pc_build_users[0]);
                this.is_initial = null;
                // this.pc_build_user_selected = this.pc_build_users[0];
    
                // this.attribute_selected = this.pc_build_user_selected.attribute_selected;
    
                // this.attribute_selected.forEach((attr) => {
                //     let INDEX_ATTR = this.pc_build_attributes.findIndex((pc_build_attr) => pc_build_attr.id == attr.attribute_id);
                //     if(INDEX_ATTR != -1){
                //         this.pc_build_attributes[INDEX_ATTR].product_selected = attr.product;
                //         this.pc_build_attributes[INDEX_ATTR].quantity = 1;
                //         this.pc_build_attributes[INDEX_ATTR].subtotal = CartResource.getAmountTotalProduct(attr.product);
                //         this.pc_build_attributes[INDEX_ATTR].total = Number(this.pc_build_attributes[INDEX_ATTR].subtotal);
                //     }
                //     setTimeout(() => {
                //         this.sub_atribute = null;
                //         this.getTotalPcBuild();
                //     }, 50);
                // })
    
              }
          }else{
            let PC_BUILD_SELEC = this.pc_build_users.find(item => item.id == pc_build_user.id);
            this.is_initial = null;
            this.seletec_pc_build_user(PC_BUILD_SELEC);
          }
        },
        async add_pc_builder_user(pc_data=1){
            if(!this.name_pc_build && pc_data == 1){
                this.$notify({
                    text: `Necesitas ingresar un nombre!`,
                    type:'error',
                    duration: 3500,
                });
                return;
            }
            let data = {
                ip_public: this.ip_publica,
                name: this.name_pc_build ? this.name_pc_build : 'PC Default',
            }
          const resp = await myApi.post(
                "ecommerce/pc_builders",
                data
          );
          console.log(resp);
          this.pc_build_users.push(resp.data.group);
          if(this.pc_build_users.length == 1){
            this.pc_build_user_selected = resp.data.group;
            this.is_initial = 1;
            this.option_side_nav = 1;
            this.listFilters();
          }else{
            this.is_initial = null;
          }
          this.name_pc_build = null;
        },
        async update_pc_builder_user(pc_user){
            let data = {
                name: pc_user.name,
                tipo: this.first_selected,
            }
          const resp = await myApi.put(
                "ecommerce/pc_builders/"+pc_user.id,
                data
          );
          console.log(resp);
        },
        async add_pc_builder_product(attribute_slt,pb_build_user_id = null){
            let data = {
                pb_build_user_id: pb_build_user_id ? pb_build_user_id : this.pc_build_user_selected.id,
                attribute_selected: attribute_slt,
            }
          const resp = await myApi.post(
                "ecommerce/pc_builders/add-product",
                data
          );
          console.log(resp);
          let INDEX = this.attribute_selected.findIndex((attr) => attr.attribute_id == this.atribute);

          if(INDEX != -1){
            this.attribute_selected[INDEX].id = resp.data.pc_build_product.id;
            this.attribute_selected[INDEX].pb_build_user_id = resp.data.pc_build_product.pb_build_user_id;
          }
            this.$notify({
                text: `El producto ha sido agregado`,
                type:'primary',
                duration: 3500,
            });
            this.pc_build_asigned_discount()
        },
        async deleteSegment(){
            try {
                const resp = await myApi.delete(
                    "ecommerce/pc_builders/"+this.selected_modal.id
                );
                console.log(resp);
                let index = this.pc_build_users.findIndex((pc_build) => pc_build.id == this.selected_modal.id);
                if(index != -1){
                    this.pc_build_users.splice(index,1);
                }
                this.$notify({
                    title: `TU SEGMENTO '${this.selected_modal.name}' SE ELIMINO DEL CARRITO!`,
                    type: 'error',
                    duration: 3500,
                });
                if(this.pc_build_users.length == 0){
                    this.seletec_pc_build_user(null);
                }
                if(this.pc_build_users.length > 0){
                    this.seletec_pc_build_user(this.pc_build_users[0]);
                }
            } catch (error) {
                console.log(error);
            }
        },
        formatAttributeSelected(pc_build_user){
            this.attribute_selected = this.pc_build_user_selected.attribute_selected;
            if(this.attribute_selected.length == 0){
                this.is_initial = 1;
                this.option_side_nav = 1;
                this.products = [];
            }
            this.first_selected = pc_build_user.tipo;
            this.attribute_selected.forEach((attr) => {
                let INDEX_ATTR = this.pc_build_attributes.findIndex((pc_build_attr) => pc_build_attr.id == attr.attribute_id);
                if(INDEX_ATTR != -1){
                    this.pc_build_attributes[INDEX_ATTR].product_selected = attr.product;
                    this.pc_build_attributes[INDEX_ATTR].quantity = attr.quantity;
                    this.pc_build_attributes[INDEX_ATTR].pb_build_user_id = attr.pb_build_user_id;
                    this.pc_build_attributes[INDEX_ATTR].pb_build_attr_id = attr.id;
                    this.pc_build_attributes[INDEX_ATTR].subtotal = CartResource.getAmountTotalProduct(attr.product);
                    this.pc_build_attributes[INDEX_ATTR].total = Number(this.pc_build_attributes[INDEX_ATTR].subtotal) * this.pc_build_attributes[INDEX_ATTR].quantity;
                }
            })
        },
        seletec_pc_build_user(pc_build_user){
            let back_up_attributes = this.pc_build_attributes;

            this.pc_build_attributes = [];
            back_up_attributes = back_up_attributes.map((atrr) => {
                atrr.product_selected = null;
                atrr.quantity = 0;
                atrr.subtotal = 0;
                atrr.total = 0;
                return atrr;
            })

            setTimeout(() => {

                this.pc_build_attributes = back_up_attributes;
                this.pc_build_user_selected = pc_build_user;
                if(pc_build_user){
                    this.formatAttributeSelected(pc_build_user);
                }

                setTimeout(() => {
                    this.getTotalPcBuild();
                    this.sub_atribute = null;
                    this.atribute = 1;
                    // if(!this.is_initial){
                        this.listFilters();
                    // }
                }, 50);

            }, 25);
        },
        async pc_build_asigned_discount(){
            let data = {
                attribute_selected: this.attribute_selected,
                total: this.TOTAL_SUM_ORIGINAL_PC_BUILD,
                pc_build_user_id: this.pc_build_user_selected.id,
            }
          const resp = await myApi.post(
                "ecommerce/pc_builders/asigned-discount",
                data
          );
          console.log(resp);
            this.pc_build_user_selected = resp.data.pc_builder;
            if(resp.data.pc_builder){
                this.formatAttributeSelected(resp.data.pc_builder);
                setTimeout(() => {
                    this.getTotalPcBuild();
                }, 50);
            }
        },
        categorieSeleted(categorie_id){
          this.categorie_g_selected = categorie_id;
          this.sub_categorie_selected = null;
          this.categorie_selected = null;
          this.listFilters();
        },
        subcategorieSeleted(sub_categorie_id){
          this.sub_categorie_selected = sub_categorie_id;
          this.categorie_g_selected = null;
          this.categorie_selected = null;
          this.listFilters();
        },
        changeRange(){
          this.min_price = this.$refs.min_price.value;
          this.max_price = this.$refs.max_price.value;
          setTimeout(() => {
            this.listFilters();
          }, 15);
        },
        reloadMyJs() {
            const detail_product = document.createElement("script");
            detail_product.src = "../../assets/js/myjs/detailproduct.js";
            document.body.appendChild(detail_product);
            detail_product.onload = () => {
                console.log("archivo.js cargado detail_product");
            };
            var script10 = document.createElement('script');
                script10.src = '../../assets/js/script.js';
                document.body.appendChild(script10);
                script10.onload = () => {
                    console.log('archivo.js cargado 10');
                }    
            const script4 = document.createElement('script');
                script4.src = '../../assets/js/swiper-slider/swiper-custom.min.js';
                document.body.appendChild(script4);
                script4.onload = () => {
                console.log('archivo.js cargado 4');
            }
            const script2 = document.createElement("script");
            script2.src = "../../assets/js/feather/feather.min.js";
            document.body.appendChild(script2);
            script2.onload = () => {
                console.log("archivo.js cargado 2");
            };
        },
        selectedAttributes(subattribute){
            let INDEX = this.sub_attributes.findIndex(item => item == subattribute.id);
            if(INDEX != -1){
                this.sub_attributes.splice(INDEX,1);
            }else{
                this.sub_attributes.push(subattribute.id);
            }
            setTimeout(() => {
                this.listFilters();
            }, 25);
        },
        selectedMarca(marca){
            let INDEX = this.marcas_selected.findIndex(item => item == marca.marcas_id);
            if(INDEX != -1){
                this.marcas_selected.splice(INDEX,1);
            }else{
                this.marcas_selected.push(marca.marcas_id);
            }
            setTimeout(() => {
                this.listFilters();
            }, 25);
        },
        getWithExpiry(key,val = 0) {
            const itemStr = localStorage.getItem(key)
            if (!itemStr) {
                return val == 0 ? null : [];
            }
            const item = JSON.parse(itemStr)
            const now = new Date()
            if (now.getTime() > item.expiry) {
                localStorage.removeItem(key)
                return val == 0 ? null : [];
            }
            return item.value
        },
        getAmountTotal(product){
            if(product.discount_g && !product.code_offer){
                if(product.discount_g.discount_id){
                return Number(product.discount_g.new_amount.toFixed(2));
                // percentage
                }else{
                if(product.discount_g.type_discount == 1){//%
                    return Number((product.precio_general - (product.precio_general*product.discount_g.discount*0.01)).toFixed(2));
                }else{
                    return Number((product.precio_general - product.discount_g.discount).toFixed(2));
                }
                }
            }
            if(product.code_offer){
                if(product.code_offer.new_amount){
                return Number(product.code_offer.new_amount.toFixed(2));
                // percentage
                }else{
                if(product.code_offer.type_discount == 1){//%
                    return Number((product.precio_general - (product.precio_general*product.code_offer.discount*0.01)).toFixed(2));
                }else{
                    return Number((product.precio_general - product.code_offer.discount).toFixed(2));
                }
                }
            }
            return  Number(product.precio_general).toFixed(2);
        },
        getAmountDiscount(product){
            return product.precio_general;
        },
        async deleteCardItem(pc_build_attr){
            pc_build_attr.product_selected = null;
            pc_build_attr.quantity = null;
            pc_build_attr.total = null;
            let INDEX = this.attribute_selected.findIndex((attr) => attr.attribute_id == pc_build_attr.id);
            let ATTR_SELECTED = {};
            if(INDEX != -1){
                ATTR_SELECTED = this.attribute_selected[INDEX];
                this.attribute_selected.splice(INDEX,1);
            }
            this.getTotalPcBuild();
            // 
            const resp = await myApi.post(
                "ecommerce/pc_builders/delete-product",
                {pc_build_product: ATTR_SELECTED}
            );
            console.log(resp);
            this.pc_build_asigned_discount();
        },
        getTotalPcBuild(){
           this.TOTAL_SUM_PC_BUILD = Number((this.pc_build_attributes.reduce((sum,item) => sum + (item.total ? parseFloat(item.total) : 0),0)).toFixed(2));
            this.TOTAL_SUM_ORIGINAL_PC_BUILD = Number((this.pc_build_attributes.reduce((sum,item) => {
                let TOTAL = 0;
                if(item.product_selected && item.product_selected.total_origin){
                    TOTAL += item.product_selected.total_origin;
                }else{
                    TOTAL += (item.total ? parseFloat(item.total) : 0);
                }
                return sum + TOTAL;
            },0)).toFixed(2));
        },
        addCart(){
            
            let CARTS = this.CARTS;

            this.pc_build_attributes.forEach((attrib) => {
                if(attrib.product_selected){
                    let INDEX = CARTS.findIndex(
                        (product) => product.uniqd == attrib.product_selected.uniqd
                    );
                    if(INDEX == -1){
                        attrib.product_selected.is_pc_build = 1;
                        CartResource.add(CARTS,attrib.product_selected,this.emitter,this.$notify,this.$cookies,attrib.quantity);
                    }else{
                        this.removeCart(CARTS[INDEX],attrib);
                    }
                }
            });

        },
        removeCart(CART,attrib){
            let INDEX = this.CARTS.findIndex(product => product.uniqd == CART.uniqd);

            if(!store.getters.loggedIn){
                if(INDEX != -1){
                    this.CARTS.splice(INDEX,1);
                }
                localStorage.setItem('carts',JSON.stringify(this.CARTS));
                this.$notify({
                    title: "Producto removido del carrito",
                    type: 'error',
                    duration: 3500,
                });
                this.emitter.emit('cart-shop', this.CARTS);
            }else{
                if(INDEX != -1){
                    this.deleteCart(this.CARTS[INDEX],INDEX,attrib);
                }
            }
        },
        async deleteCart(data,index,attrib){
            try {
                const resp = await myApi.post(
                    "ecommerce/carts/delete/"+data.cart_id,
                    {
                        CARTS: this.CARTS,
                        from_pc_build: 1000,
                    }
                );
                console.log(resp);
                if(resp.data.message == 203){
                    this.emitter.emit("cart-shop", resp.data.CARTS);
                }else{
                    if(index != -1){
                        this.CARTS.splice(index,1);
                    }
                    this.emitter.emit('cart-shop', this.CARTS);
                    this.$notify({
                        title: "Producto removido del carrito",
                        type: 'error',
                        duration: 1000,
                    });
                    attrib.product_selected.is_pc_build = 1;
                    CartResource.add(this.CARTS,attrib.product_selected,this.emitter,this.$notify,this.$cookies,attrib.quantity);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async resetPcBuilder(){
            try {
                const resp = await myApi.post(
                    "ecommerce/pc_builders/reset-product/",
                    {
                        pc_build_user: this.pc_build_user_selected.id,
                    }
                );
                console.log(resp);
                this.seletec_pc_build_user(resp.data.pc_builder);
                this.$notify({
                    title: `TU PCBUILDER '${this.pc_build_user_selected.name}' SE LIMPIO!`,
                    type: 'primary',
                    duration: 3500,
                });
            } catch (error) {
                console.log(error);
            }
        },
        async add_pc_confirmation_product(product){
            let data = {
                product_id: product.id,
            }
          const resp = await myApi.post(
                "ecommerce/pc_builders/confirmation-product",
                data
          );
          console.log(resp);
          if(resp.data.message == 403){
            this.$notify({
                text: `Producto fuera de las compatibilidades!`,
                type:'error',
                duration: 4000,
            });
            return;
          }else{
            
            let INDEX = this.attribute_selected.findIndex((attr) => attr.attribute_id == this.atribute);
            let data = product;
            
            if(INDEX != -1){
                this.attribute_selected[INDEX].product_id = data.id;
                this.attribute_selected[INDEX].product = data;
                this.add_pc_builder_product(this.attribute_selected[INDEX]);
            }else{
                this.attribute_selected.push({
                    attribute_id: this.atribute,
                    tipo: this.sub_atribute,
                    product_id: data.id,
                    product: data,
                    quantity: 1,
                });
                this.add_pc_builder_product({
                    attribute_id: this.atribute,
                    tipo: this.sub_atribute,
                    product_id: data.id,
                    product: data,
                    quantity: 1,
                });
                // this.pc_build_user_selected
            }
    
            let INDEX_ATTR = this.pc_build_attributes.findIndex((pc_build_attr) => pc_build_attr.id == this.atribute);
            if(INDEX_ATTR != -1){
                this.pc_build_attributes[INDEX_ATTR].product_selected = data;
                this.pc_build_attributes[INDEX_ATTR].quantity = 1;
                this.pc_build_attributes[INDEX_ATTR].subtotal = CartResource.getAmountTotalProduct(data);
                this.pc_build_attributes[INDEX_ATTR].total = Number(this.pc_build_attributes[INDEX_ATTR].subtotal) * this.pc_build_attributes[INDEX_ATTR].quantity;
            }
            setTimeout(() => {
                this.sub_atribute = null;
                this.getTotalPcBuild();
            }, 50);

          }
        },
        scrollTop(){
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 50);
        },
    },
    created(){
      this.search_product = this.slug_product;
      this.categorie_selected = this.categorie_id;
      this.categorie_g_selected = this.categorie_second_id;
      this.sub_categorie_selected = this.categorie_third_id;
      this.categories_selected = this.categorie_selected_external;

      this.attributes = this.getWithExpiry('attributes_shop_'+this.categorie_id,1);
      this.marca_options = this.getWithExpiry('marcas_shop_'+this.categorie_id,1);

      this.$store.dispatch('getDats','ecommerce');
    },
    async mounted(){
    //   this.listFilters()
      // let categories_i = this.$cookies.get("categories_i") ? this.$cookies.get("categories_i") : null;
        this.INDEXBDS = this.$store.state.dats;
        //   setTimeout(() => {
        //     let categories_i =  this.INDEXBDS.find((idb) => idb.name == 'categories_i');
        //     if(categories_i){
        //       this.categories = JSON.parse(categories_i.data);
        //     }else{

        //     }
        //   }, 200);
      
      this.filters_config_all();
      this.listKits();

      const script_range_slider = document.createElement("script");
      script_range_slider.src = "../../assets/js/range-slider.js";
      document.body.appendChild(script_range_slider);
      script_range_slider.onload = () => {
          console.log("archivo.js cargado range-slider");
      };
      setTimeout(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
      }, 150);

        this.emitter.on('acept-terminos',data => {
            console.log(data);
            this.is_loading_carts = true;
            setTimeout(() => {
                this.is_loading_carts = false;
                this.show_go_cart = false;
            },2000)
            this.addCart();
        });
    //   const resp = await myApi.get('https://api.ipify.org');
    //   console.log(resp);
    //   this.ip_publica = localStorage.getItem("ip_publica");

    //   if(!this.ip_publica){
    //       fetch('https://api.ipify.org?format=json')
    //         .then(response => response.json())
    //         .then(data => {
    //             console.log(data.ip);
    //             localStorage.setItem("ip_publica", data.ip+"-"+new Date().getTime());
    //             this.ip_publica = localStorage.getItem("ip_publica");
    //             this.pc_builder_users();
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         })
    //   }
    //   if(this.ip_publica){
    //       this.pc_builder_users();
    //   }
      this.emitter.on('cart-shop',data => {
        this.CARTS = data;
      });
      this.emitter.emit("cart-list", {});
      this.emitter.on('pc-build-product-selec',data => {
          console.log(data);
          let INDEX = this.attribute_selected.findIndex((attr) => attr.attribute_id == this.atribute);

          if(this.attribute_selected.length == 0){
            this.add_pc_confirmation_product(data);
          }else{

              if(INDEX != -1){
                this.attribute_selected[INDEX].product_id = data.id;
                this.attribute_selected[INDEX].product = data;
                this.add_pc_builder_product(this.attribute_selected[INDEX]);
              }else{
                this.attribute_selected.push({
                    attribute_id: this.atribute,
                    tipo: this.sub_atribute,
                    product_id: data.id,
                    product: data,
                    quantity: 1,
                });
                this.add_pc_builder_product({
                    attribute_id: this.atribute,
                    tipo: this.sub_atribute,
                    product_id: data.id,
                    product: data,
                    quantity: 1,
                });
                // this.pc_build_user_selected
              }
    
              let INDEX_ATTR = this.pc_build_attributes.findIndex((pc_build_attr) => pc_build_attr.id == this.atribute);
              if(INDEX_ATTR != -1){
                this.pc_build_attributes[INDEX_ATTR].product_selected = data;
                this.pc_build_attributes[INDEX_ATTR].quantity = 1;
                this.pc_build_attributes[INDEX_ATTR].subtotal = CartResource.getAmountTotalProduct(data);
                this.pc_build_attributes[INDEX_ATTR].total = Number(this.pc_build_attributes[INDEX_ATTR].subtotal) * this.pc_build_attributes[INDEX_ATTR].quantity;
              }
              setTimeout(() => {
                this.sub_atribute = null;
                this.getTotalPcBuild();
              }, 50);

          }

      });
      this.emitter.on('pc-build-add-kit',data => {
        // this.seletec_pc_build_user(data)
        console.log(data);
        this.pc_builder_users(data);
      });
    }
}
</script>