<template>
    <!-- Main Start -->
    <div class="main">
      <section class="page-body p-0">
        <div class="row">
          <div class="order-2 order-lg-1 col-lg-5">
            <div class="content-box">
              <div>
                <h5>Crear una cuenta</h5>
                <p class="font-md content-color">¡Registrate con nosotros!</p>
                <form action="index.php" class="custom-form form-pill">
                  <div class="input-box1">
                    <label for="full-name">Nombres:</label>
                    <input class="form-control" type="text" v-model="name"  @input="onlyStringVarcharN" required name="full-name" id="full-name" />
                  </div>
                  <div class="input-box1">
                    <label for="full-name">Apellidos:</label>
                    <input class="form-control" type="text" v-model="surname" @input="onlyStringVarcharS" required name="sur-name" id="sur-name" />
                  </div>
                  <div class="input-box1">
                    <label for="email">Correo electrónico:</label>
                    <input class="form-control" type="email" v-model="email" required name="email" id="email" />
                  </div>
                  <div class="input-box1">
                    <label for="password">Contraseña:</label>
                    <div class="icon-input">
                      <input class="form-control" type="password" v-model="password" required name="password" id="password" />
                      <img class="showHidePassword" src="/assets/icons/svg/eye-1.svg" alt="eye" />
                    </div>
                  </div>

                  <button type="button" @click="register()" v-if="!loading" class="btn-solid rounded-pill line-none theme-color">Registrarse </button>
                  <div class="loader-5 center" v-if="loading"><span></span></div>
                  <a href="#" @click="tohome()" class="btn-solid rounded-pill line-none btn-outline mt-3 d-flex justify-content-center">Ir al sitio web </a>
                </form>

                <span class="backto-link font-default content-color text-decoration-none"
                  >¿Ya tienes una cuenta? <a class="text-decoration-underline theme-color" href="#" @click="tologin()">Iniciar Sesión </a>
                </span>
                <!-- <div id="g_id_onload"
                    data-client_id="820389707210-noeljepidcc5jh7h7tu3d91rsh7ra99r.apps.googleusercontent.com"
                    data-callback="handleCredentialResponse">
                </div>
                <div class="g_id_signin" data-type="standard"></div> -->
               <!--  <span class="line"><span>Or </span> </span>
                <a href="https://www.google.com/" class="button-link"><img src="/assets/icons/png/google.png" alt="google" /> Sign up </a>
                <a href="https://www.facebook.com/" class="button-link"><img src="/assets/icons/png/fb.png" alt="facebook" /> Sign up </a> -->
              </div>
            </div>
          </div>
          <div class="order-1 order-lg-2 col-lg-7">
            <div class="img-box">
            <img class="bg-img" src="/assets/images/bg/bg-zd-000.jpg" alt="banner" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Main End -->
    <!-- Main End -->
</template>
<script>
import myApi from '../../plugins/axios'
import store from '../../plugins/store'

export default {
    name: 'RegisterSection',
    data(){
        return {
            email: null,
            name: null,
            surname: null,
            password: null,
            loading: false,
            gogleIn:{
              email: null,
              name: null,
              surname: null,
              imagen: null,
              google_id: null,
            }
        }
    },
    methods: {
        tologin(){
            this.$router.push({
                name: 'Login',
            })
        },
        tohome() {
            this.$router.push({
                name: 'Home',
            })
        },
        onlyStringVarcharN(){
          const regex = /[^A-Za-záéíóúÁÉÍÓÚñÑ\s]/g;
          if (regex.test(this.name)) {
            this.name = this.name.replace(regex, '');
          }
        },
        onlyStringVarcharS(){
          const regex = /[^A-Za-záéíóúÁÉÍÓÚñÑ\s]/g; 
          if (regex.test(this.surname)) {
            this.surname = this.surname.replace(regex, '');
          }
        },
        async register(){
            if(!(/^[^@]+@\w+(\.\w+)+\w$/.test(this.email))){
              this.$notify({
                  title: "Ingresa un dirección de correo electronica valida.",
                  duration: 3500,
                  type:'error'
              });
              return;
            }
            this.loading = true;
            let resp = await myApi.post('ecommerce/register',{
              surname: this.surname,
              name: this.name,
              email: this.email,
              password: this.password
            });
            this.loading = false;
            if(resp.data.error){
              for (let key in resp.data.error) {
                this.$notify({
                    title: "VALIDACIÓN",
                    text: resp.data.error[key],
                    duration: 3500,
                    type:'error',
              });
              }
            }else{
              // if(resp.data.success == false){

              // }else{
                this.$notify({
                  title: "Tu registro fue exitoso , ahora podras acceder a tu perfil",
                  duration: 3500,
                });
              // }
              this.login();
              // this.$router.push({name: 'Login'});
            }
            console.log(resp);
        },
        async login(){
          try {
            let resp = await myApi.post('ecommerce/login',{email: this.email,password: this.password});
            console.log(resp);
            if(resp.data.error){
              for (let key in resp.data.error) {
                this.$notify({
                    title: "VALIDACIÓN",
                    text: resp.data.error[key],
                    duration: 3500,
                    type:'error',
              });
              }
            }else{
              store.dispatch('saveToken',{
                token: resp.data.token,
                user: resp.data.user,
              });
              setTimeout(() => {
                window.location.href = process.env.VUE_APP_FRONTED+"perfil-cliente?perfil=1";
              }, 50);
            }
          } catch (err) {
            if(err.response.data){
                  // alert(err.response.data.message)
                this.$notify({
                      title: "VALIDACIÓN",
                      text: err.response.data.message,
                      duration: 3500,
                      type:'error',
                });
              }else{
                this.$router.push({name: 'Login'});
                this.$notify({
                      title: "VALIDACIÓN",
                      text: "Hubo un error al intentar registrarse, intentalo nuevamente",
                      duration: 3500,
                      type:'error',
                });
              }
              console.log(err);
          }
        },
        decodeJwtResponse (token) 
        {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        }
    },
    mounted() {
        const script2 = document.createElement('script');
        script2.src = '../../assets/js/password-showhide.js';
        document.body.appendChild(script2);
        script2.onload = () => {
            console.log('archivo.js cargado 2');
        }

        window.handleCredentialResponse = async (response) => {
          // decodeJwtResponse() is a custom function defined by you
          // to decode the credential response.
          let responsePayload = this.decodeJwtResponse(response.credential);

          // console.log("ID: " + responsePayload.sub);
          // console.log('Full Name: ' + responsePayload.name);
          // console.log('Given Name: ' + responsePayload.given_name);
          // console.log('Family Name: ' + responsePayload.family_name);
          // console.log("Image URL: " + responsePayload.picture);
          console.log("Email: " + responsePayload.email);

          this.gogleIn.email = responsePayload.email;
          this.gogleIn.name = responsePayload.given_name;
          this.gogleIn.surname = responsePayload.family_name;
          this.gogleIn.imagen = responsePayload.picture;
          this.gogleIn.google_id = responsePayload.sub;

          try {
            let resp = await myApi.post('ecommerce/login-google',{google: this.gogleIn});
            console.log(resp);
            if(resp.data.error){
              for (let key in resp.data.error) {
                this.$notify({
                    title: "VALIDACIÓN",
                    text: resp.data.error[key],
                    duration: 3500,
                    type:'error',
              });
              }
            }else{
              store.dispatch('saveToken',{
                token: resp.data.token,
                user: resp.data.user,
              });
              setTimeout(() => {
                window.location.href = process.env.VUE_APP_FRONTED;
              }, 50);
            }
          } catch (err) {
            console.log(err);
              if(err.response.data){
                  // alert(err.response.data.message)
                this.$notify({
                      title: "VALIDACIÓN",
                      text: err.response.data.message,
                      duration: 3500,
                      type:'error',
                });
              }else{
                this.$notify({
                      title: "VALIDACIÓN",
                      text: "Hubo un error al intentar registrarse, intentalo nuevamente",
                      duration: 3500,
                      type:'error',
                });
              }
          }

        }
        setTimeout(() => {
          const script3 = document.createElement('script');
          script3.src = 'https://accounts.google.com/gsi/client';
          document.body.appendChild(script3);
          script3.onload = () => {
              console.log('archivo.js cargado 3');
          }
        }, 50);
    }, 
}
</script>