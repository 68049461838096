<template>
    <div>
        <section class="tracking-order" v-if="ORDER_SELECTED">
            <div class="py-5 tracking d-none d-sm-block">
                <div class="container text-center">
                    <h2>Tracking Del Pedido #{{ ORDER_SELECTED.order_id_external ? ORDER_SELECTED.order_id_external : ORDER_SELECTED.id }}</h2>
                    <!-- <p>Puedes ver el movimiento de tu pedido hasta la llegada en tus manos</p> -->
                    <router-link class="btn btn-outline btn-sm white-space-no" :to="'/perfil-cliente/?uniqd='+ORDER_SELECTED.uniqd">Detalle de la Orden</router-link>
                </div>
                <div class=" mb-3">
                    <div class="">
                        <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                            <div class="step" :class="{'completed': ORDER_SELECTED.status == 'PREPARANDO PEDIDO' || ORDER_SELECTED.order_states.length > 0}">
                                <div class="step-icon-wrap">
                                    <div class="step-icon"><img src="/assets/images/tracking/orden-pendiente.png" class="img-responsive" alt="order-placed" /></div>
                                </div>
                                <h4 class="step-title">Preparando pedido<span> <br>{{ ORDER_SELECTED.created_at }}</span></h4>
                            </div>
                            <div class="step" :class="{'completed': getStateActive(ORDER_SELECTED,'PENDIENTE DE ENVIO')}">
                                <div class="step-icon-wrap">
                                    <div class="step-icon"><img src="/assets/images/tracking/orden-recibida.png" class="img-responsive" alt="order-placed" /></div>
                                </div>
                                <h4 class="step-title">Pendiente de envio<span> <br>{{ getStateDate(ORDER_SELECTED,'PENDIENTE DE ENVIO') }}</span></h4>
                            </div>
                            <div class="step" :class="{'completed':  getStateActive(ORDER_SELECTED,'EN TRANSITO')}">
                                <div class="step-icon-wrap">
                                    <div class="step-icon"><img src="/assets/images/tracking/orden-en-camino.png" class="img-responsive" alt="order-placed" /></div>
                                </div>
                                <h4 class="step-title">Orden en transito<span> <br>{{ getStateDate(ORDER_SELECTED,'EN TRANSITO') }}</span></h4>
                            </div>
                            <div class="step" v-if="ORDER_SELECTED.status != 'ORDEN COMPLETADA'" :class="{'completed': getStateActive(ORDER_SELECTED,'ENTREGADO')}">
                                <div class="step-icon-wrap">
                                    <div class="step-icon"><img src="/assets/images/tracking/orden-entregado.png" class="img-responsive" alt="order-placed" /></div>
                                </div>
                                <h4 class="step-title">Orden entregada<span><br>{{ getStateDate(ORDER_SELECTED,'ENTREGADO') }}</span></h4>
                            </div>

                            <div class="step" v-if="ORDER_SELECTED.status == 'ORDEN COMPLETADA'" :class="{'completed': getStateActive(ORDER_SELECTED,'ORDEN COMPLETADA')}">
                                <div class="step-icon-wrap">
                                    <div class="step-icon"><img src="/assets/images/tracking/orden-entregado.png" class="img-responsive" alt="order-placed" /></div>
                                </div>
                                <h4 class="step-title">Orden completada<span><br>{{ getStateDate(ORDER_SELECTED,'ORDEN COMPLETADA') }}</span></h4>
                                <br>
                                <a class="btn-whatsapp" href="https://web.whatsapp.com/send?phone=50378501000&text=">Whatsapp</a> 
                            </div>

                        </div>
                    </div>
                </div>
                <div class="mapa-tracking" >
                    <div class="google-map" v-if="!isMobile()" ref="googleMap" style="height: 300px"></div>
                </div>
            </div>
            <div class="container py-5 tracking d-block d-sm-none">
                <div class="container text-center">
                    <h2>TRACKING DEL PEDIDO #{{ ORDER_SELECTED.order_id_external ? ORDER_SELECTED.order_id_external : ORDER_SELECTED.id }}</h2>
                    <!-- <p>Puedes ver el movimiento de tu pedido hasta la llegada en tus manos</p> -->
                    <router-link class="btn btn-outline btn-sm white-space-no" :to="'/perfil-cliente/?uniqd='+ORDER_SELECTED.uniqd">Detalle de la Orden</router-link>
                </div>
                <div class="">
                    <div class="mapa-tracking" >
                        <div class="google-map" v-if="isMobile()" ref="googleMap" style="height: 300px"></div>
                    </div>
                    <div class="">
                        <div id="tracking-pre"></div>
                        <div id="tracking">
                            <div class="tracking-list">
                            <div class=""  :class="{'tracking-item': ORDER_SELECTED.status == 'PREPARANDO PEDIDO' || ORDER_SELECTED.order_states.length > 0}">
                                <div class="tracking-icon " :class="{'status-current blinker' : ORDER_SELECTED.status == 'PREPARANDO PEDIDO','status-intransit' : ORDER_SELECTED.status != 'PREPARANDO PEDIDO'}">
                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                    </svg>
                                </div>
                                <div class="tracking-date"><img src="/assets/images/tracking/orden-pendiente.png" class="img-responsive" alt="order-placed" /></div>
                                <div class="tracking-content">Orden preparando pedido<span>{{ ORDER_SELECTED.created_at }}</span></div>
                            </div>
                            <div class=""  :class="{'tracking-item': getStateActive(ORDER_SELECTED,'PENDIENTE DE ENVIO'), 'tracking-item-pending' : !getStateActive(ORDER_SELECTED,'PENDIENTE DE ENVIO')}">
                                <div class="tracking-icon status-intransit" :class="{'status-current blinker' : ORDER_SELECTED.status == 'PENDIENTE DE ENVIO','status-intransit' : ORDER_SELECTED.status != 'PENDIENTE DE ENVIO'}">
                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                    </svg>
                                </div>
                                <div class="tracking-date"><img src="/assets/images/tracking/orden-recibida.png" class="img-responsive" alt="order-placed" /></div>
                                <div class="tracking-content">Pendiente de envio<span>{{ getStateDate(ORDER_SELECTED,'PENDIENTE DE ENVIO') }}</span></div>
                            </div>
                            <div class=""  :class="{'tracking-item':  getStateActive(ORDER_SELECTED,'EN TRANSITO'), 'tracking-item-pending' : !getStateActive(ORDER_SELECTED,'EN TRANSITO')}">
                                <div class="tracking-icon " :class="{'status-current blinker' : ORDER_SELECTED.status == 'EN TRANSITO','status-intransit' : ORDER_SELECTED.status != 'EN TRANSITO'}">
                                    <!--  -->
                                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                    </svg>
                                </div>
                                <div class="tracking-date"><img src="/assets/images/tracking/orden-en-camino.png" class="img-responsive" alt="order-placed" /></div>
                                <div class="tracking-content">Orden en transito<span>{{ getStateDate(ORDER_SELECTED,'EN TRANSITO') }}</span></div>
                            </div>
                                <div class="" v-if="ORDER_SELECTED.status != 'ORDEN COMPLETADA'" :class="{'tracking-item': getStateActive(ORDER_SELECTED,'ENTREGADO'), 'tracking-item-pending' : !getStateActive(ORDER_SELECTED,'ENTREGADO')}">
                                    <div class="tracking-icon" :class="{'status-current blinker' : ORDER_SELECTED.status == 'ENTREGADO','status-intransit' : ORDER_SELECTED.status != 'ENTREGADO'}">
                                        <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                        </svg>
                                    </div>
                                    <div class="tracking-date"><img src="/assets/images/tracking/orden-entregado.png" class="img-responsive" alt="order-placed" /></div>
                                    <div class="tracking-content">Orden entregada<span>{{ getStateDate(ORDER_SELECTED,'ENTREGADO') }}</span></div>
                                </div>
                                <div class="" v-if="ORDER_SELECTED.status == 'ORDEN COMPLETADA'" :class="{'tracking-item': getStateActive(ORDER_SELECTED,'ORDEN COMPLETADA'), 'tracking-item-pending' : !getStateActive(ORDER_SELECTED,'ORDEN COMPLETADA')}">
                                    <div class="tracking-icon" :class="{'status-current blinker' : ORDER_SELECTED.status == 'ORDEN COMPLETADA','status-intransit' : ORDER_SELECTED.status != 'ORDEN COMPLETADA'}">
                                        <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                        </svg>
                                    </div>
                                    <div class="tracking-date"><img src="/assets/images/tracking/orden-entregado.png" class="img-responsive" alt="order-placed" /></div>
                                    <div class="tracking-content">Orden completada<span>{{ getStateDate(ORDER_SELECTED,'ORDEN COMPLETADA') }}</span></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import GoogleMapsApiLoader from "google-maps-api-loader"
import myApi from '../../../plugins/axios'
export default {
    name: 'TrackingPcPedido',
    data(){
        return {
            // MAPS
            mapa_error: false,
            direccion_correcta: false,
            direccion_incorrecta: false,
            directionsRenderer: null,
            directionsService: null,
            google: null,
            map: null,
            marker:null,
            marker2:null,
            latitud: 0,
            id: null,
            longitud: 0,
            referencia:null,
            numDeltas : 50,
            delay : 5,
            i : 0,
            // 
            order_id: null,
            ORDER_SELECTED: null,
        }
    },
    methods:{
        initializeMap(lat,lng) {
            const mapContainer = this.$refs.googleMap

            this.map = new this.google.maps.Map(mapContainer, {
                center: {lat: lat, lng: lng},
                gestureHandling: 'greedy',
                zoom: 16,
                disableDefaultUI: true,
            });

            const icon1 = {
                url: '/assets/logos/camion.png', // Ruta de tu icono personalizado
                scaledSize: new this.google.maps.Size(50, 50), // Tamaño del icono
            };

            this.marker = new this.google.maps.Marker({
                position: {
                    lat: lat,
                    lng: lng
                },
                map: this.map,
                title: "Latitude:"+ 0 +" | Longitude:"+ 0,
                icon: icon1,
            });

            if(this.ORDER_SELECTED.status == 'ENTREGADO' || this.ORDER_SELECTED.status == 'ORDEN COMPLETADA'){

                const icon2 = {
                    url: '/assets/logos/servicio-al-cliente.png', // Ruta de tu icono personalizado
                    scaledSize: new this.google.maps.Size(50, 50), // Tamaño del icono
                };
    
                this.marker2 = new this.google.maps.Marker({
                    position: {
                        lat: Number(this.ORDER_SELECTED.client_general.latitud),
                        lng: Number(this.ORDER_SELECTED.client_general.longitud)
                    },
                    map: this.map,
                    title: "Latitude:"+ this.ORDER_SELECTED.client_general.latitud +" | Longitude:"+ this.ORDER_SELECTED.client_general.longitud,
                    icon: icon2,
                });
    
                // 
                const point1 = { lat: lat, lng: lng };
                const point2 = { lat: Number(this.ORDER_SELECTED.client_general.latitud), lng: Number(this.ORDER_SELECTED.client_general.longitud) };
                // var self = this;
                // Inicializar el servicio de direcciones
                this.directionsService = new this.google.maps.DirectionsService();
                this.directionsRenderer = new this.google.maps.DirectionsRenderer({
                    suppressMarkers: true, // Suprime los marcadores A y B
                    markerOptions: {
                        icon: {
                            url: '/assets/logos/Map-Marker-Transparent.png',
                            scaledSize: new this.google.maps.Size(1, 1), // Tamaño muy pequeño o transparente
                        },
                    },
                });
                this.directionsRenderer.setMap(this.map);
    
                this.calculateAndDisplayRoute(point1,point2);
                
            }

            // const bounds = new this.google.maps.LatLngBounds();
            // bounds.extend(new this.google.maps.LatLng(point1.lat, point1.lng));
            // bounds.extend(new this.google.maps.LatLng(point2.lat, point2.lng));
            // this.map.fitBounds(bounds);

            // this.map.addListener('click', (mapsMouseEvent) => {

            //     let lat = Number(mapsMouseEvent.latLng.lat().toString());
            //     let lng = Number(mapsMouseEvent.latLng.lng().toString());
            //     console.log(lat,lng);
            //     var result = {
            //         lat : lat,
            //         lng : lng
            //     };
            //     // self.validar(lat,lng)
            //     this.efecto(result);
            //     this.direccion_correcta = true;
            //     // this.moveMarker();

            // });
        },
        efecto(result){
            this.i = 0;
            this.deltaLat = (result.lat - this.latitud)/ this.numDeltas;
            this.deltaLng = (result.lng - this.longitud)/ this.numDeltas;
            this.moveMarker();
        },
        moveMarker(){
            this.latitud += this.deltaLat;
            this.longitud += this.deltaLng;
            this.marker.setTitle("Latitude:" + this.latitud + " | Longitude:"+ this.longitud);
            this.marker.setPosition({
                lat: this.latitud,
                lng: this.longitud
            });
            if(this.i !== this.numDeltas){
                this.i++;
                setTimeout(this.moveMarker(), this.delay);
            }
        },
        calculateAndDisplayRoute(start, end) {
            this.directionsService.route({
                origin: start,
                destination: end,
                travelMode: 'DRIVING' // Puedes cambiar a 'WALKING', 'BICYCLING', etc.
            },(response, status) => {
                if (status === 'OK') {
                    // this.marker.setMap(null);
                    // this.marker2.setMap(null);
                    this.directionsRenderer.setDirections(response);
                } else {
                    window.alert('No se pudo calcular la ruta: ' + status);
                }
            });
        },
        async initGoogleMap(){
            // 
            const googleMapApi = await GoogleMapsApiLoader({
                apiKey: 'AIzaSyA6MRhgLWpKf7XfNNUP3H9yz-ts9R3hvD8',
            });
            this.google = googleMapApi
            var that = this
            function onError() {
                that.mapa_error = true;
            }
            function onSuccess(geo) {
                // const position = {
                //     lat: geo.coords.latitude,
                //     lng: geo.coords.longitude
                // };
                // Reposition the map to the detected location
                that.latitud =  Number(geo.coords.latitude)
                that.longitud =  Number(geo.coords.longitude)
                that.mapa_error = false;
                that.initializeMap(that.latitud,that.longitud);
                // that.validar(that.latitud,that.longitud)
            }

            if (!navigator.geolocation) {
                onError();
            } else {
                navigator.geolocation.getCurrentPosition(onSuccess, onError);
            }
            // this.obtener_direcciones();
            // this.initializeMap(this.latitud,this.longitud);
        },
        async initGoogleMapEntrega(ORDER = null){
            // 
            const googleMapApi = await GoogleMapsApiLoader({
                apiKey: 'AIzaSyA6MRhgLWpKf7XfNNUP3H9yz-ts9R3hvD8',
            });
            this.google = googleMapApi
            var that = this
            function onError() {
                that.mapa_error = true;
            }
            function onSuccess(geo) {
                console.log(geo,ORDER);
                // if(ORDER.status == 'ENTREGADO'){
                //     that.latitud = Number(ORDER.latitud) ;
                //     that.longitud = Number(ORDER.longitud) ;
                // }else{
                    that.latitud =  13.678767566370581;//Number(geo.coords.latitude)
                    that.longitud =  -89.27604134734102;//Number(geo.coords.longitude)
                // }
                that.mapa_error = false;
                that.initializeMap(that.latitud,that.longitud);
                // that.validar(that.latitud,that.longitud)
            }

            if (!navigator.geolocation) {
                onError();
            } else {
                navigator.geolocation.getCurrentPosition(onSuccess, onError);
            }
        },
        isMobile() {
            if(window.innerWidth <= 767 || screen.width <= 767) {
                return true;
            }
            else {
                return false;
            }
        },
        getStateDate(tacking_selected,status){
            if(tacking_selected.order_states.length > 0){
                let STATUS = tacking_selected.order_states.find((item) => item.state == status);
                if(STATUS){
                return STATUS.created_at;
                }
            }
            return "";
        },
        getStateActive(tacking_selected,status){
            if(tacking_selected.order_states.length > 0){
                let IS_ENTREGADO = tacking_selected.order_states.find((item) => item.state == 'ENTREGADO');
                if(IS_ENTREGADO){
                    return true;
                }
                let STATUS = tacking_selected.order_states.find((item) => item.state == status);
                if(STATUS){
                    return true;
                    // if(STATUS.state == tacking_selected.status){
                    //     return true;
                    // }
                }
            }
            return false;
        },
        getStateWarning(tacking_selected,status){
            if(tacking_selected.order_states.length > 0){
                let STATUS = tacking_selected.order_states.find((item) => item.state == status);
                // let STATUS_CURRENT = tacking_selected.order_states.find((item) => item.state == tacking_selected.status);
                if(STATUS){
                if(STATUS.state != tacking_selected.status){
                    return true;
                }
                }
            }
            return false;
        },
        getStateDanger(tacking_selected,status){
            if(tacking_selected.order_states.length > 0){
                let STATUS = tacking_selected.order_states.find((item) => item.state == status);
                if(!STATUS){
                return true;
                }
            }
            return false;
        },
    },
    created(){
        this.order_id = this.$route.params.uniqd;
    },
    async mounted(){
        // this.initGoogleMap();
        const resp = await myApi.get('ecommerce/order_show/'+this.order_id);
        console.log(resp);
        this.ORDER_SELECTED = resp.data.order;
        this.initGoogleMapEntrega(this.ORDER_SELECTED);
    }
}
</script>