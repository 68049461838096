<template>
    <div>
      <div class="modal view-product fade" id="viewModal" tabindex="-1" aria-labelledby="viewModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" v-if="product_selected_modal">
            <div class="modal-body">
              <span class="close-modal d-none d-md-block" data-bs-toggle="modal" data-bs-target="#viewModal"><i data-feather="x"></i></span>
              <section class="product-page">
                <div class="view-product ">
                    <div class="container-lg">
                      <div class="row g-3 g-xl-4">
                        <div class="col-md-6">
                          <div class="product product-single row">
                            <div class="col-12">
                              <div class="product-gallery product-gallery-sticky product-gallery-vertical">
                                <div class="swiper-container product-single-swiper swiper-theme nav-inner"
                                    data-swiper-options="{
                                    'navigation': {
                                        'nextEl': '.swiper-button-next',
                                        'prevEl': '.swiper-button-prev'
                                    }
                                }">
                                    <div class="swiper-wrapper row cols-1 gutter-no">
                                        <template v-for="(image, index) in product_selected_modal.images" :key="index">
                                          <div class="swiper-slide">
                                              <figure class="product-image">
                                                  <img :src="image.imagen"
                                                      :data-zoom-image="image.imagen"
                                                      :alt="product_selected_modal.title" width="800"
                                                      height="900">
                                              </figure>
                                          </div>
                                        </template>
                                    </div>
                                    <button class="swiper-button-modal swiper-button-next"></button>
                                    <button class="swiper-button-prev"></button>
                                    <a href="#" class="product-gallery-btn product-image-full"><i
                                            class="w-icon-zoom"></i></a>
                                </div>
                                <div class="product-thumbs-wrap swiper-container" data-swiper-options="{
                                    'navigation': {
                                        'nextEl': '.swiper-button-next',
                                        'prevEl': '.swiper-button-prev'
                                    },
                                    'breakpoints': {
                                        '992': {
                                            'direction': 'vertical',
                                            'slidesPerView': 'auto'
                                        }
                                    }
                                }">
                                    <div class="product-thumbs swiper-wrapper row cols-lg-1 cols-4 gutter-sm">
                                        <template v-for="(image, index) in product_selected_modal.images" :key="index">
                                          <div class="product-thumb swiper-slide">
                                              <img :src="image.imagen"
                                                :alt="product_selected_modal.title" width="800" height="900">
                                          </div>
                                        </template>
                                    </div>
                                    <button class="swiper-button-prev"></button>
                                    <button class="swiper-button-modal swiper-button-next"></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="product-detail-box">
                            <div class="product-option">
                              <h1>{{product_selected_modal.title}}</h1>
                              <div class="option price">
                                <!-- <h5>Precio</h5> -->
                                <span>$ {{ getAmountTotal(product_selected_modal) }} </span> 
                                <del v-if="product_selected_modal.discount_g || product_selected_modal.code_offer">$ {{ getAmountDiscount(product_selected_modal) }} </del>
                                <!-- <span> $ {{ product_selected_modal.precio_general }}  
                                  <del>$300.00</del>
                                </span>  -->
                              </div>
                              <div class="option" v-if="product_selected_modal.summary">
                                <h5> Descripción: </h5>
                                <p class="content-color">{{ product_selected_modal.summary }}</p>
                              </div>
                              <div class="btn-group">
                                <div class="plus-minus">
                                  <i class="sub" data-feather="minus"></i>
                                  <input type="number" v-model="cantidad" min="1" max="10" />
                                  <i class="add" data-feather="plus"></i>
                                </div>
                                
                                <template v-if="is_pc_build">
                                  <a href="javascript:void(0)" @click="addCartKit()" class="btn-solid btn-sm addtocart-btn">Agregar al carrito </a>
                                  <a href="javascript:void(0)" @click="addBuyKit()" data-bs-dismiss="modal" aria-label="Close" class="btn-outline btn-sm wishlist-btn">Comprar
                                    ahora</a>
                                </template>
                                <template v-if="!is_pc_build">
                                  <a href="javascript:void(0)" @click="addCart()" class="btn-solid btn-sm addtocart-btn">Agregar al carrito </a>
                                  <a href="javascript:void(0)" @click="addBuy()" data-bs-dismiss="modal" aria-label="Close" class="btn-outline btn-sm wishlist-btn">Comprar
                                    ahora</a>
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <!-- v-if="product_selected_modal" -->
      <!-- <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="pswp__bg"></div>
          <div class="pswp__scroll-wrap">
              <div class="pswp__container">
                  <div class="pswp__item"></div>
                  <div class="pswp__item"></div>
                  <div class="pswp__item"></div>
              </div>
              <div class="pswp__ui pswp__ui--hidden">
                  <div class="pswp__top-bar">
                      <div class="pswp__counter"></div>
  
                      <button class="pswp__button pswp__button--close" aria-label="Close (Esc)"></button>
                      <button class="pswp__button pswp__button--zoom" aria-label="Zoom in/out"></button>
  
                      <div class="pswp__preloader">
                          <div class="loading-spin"></div>
                      </div>
                  </div>
  
                  <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                      <div class="pswp__share-tooltip"></div>
                  </div>
  
                  <button class="pswp__button--arrow--left" aria-label="Previous (arrow left)">
                    ❮
                  </button>
                  <button class="pswp__button--arrow--right" aria-label="Next (arrow right)">
                    ❯
                  </button>
  
                  <div class="pswp__caption">
                      <div class="pswp__caption__center"></div>
                  </div>
              </div>
          </div>
      </div> -->
    </div>
</template>

<script>
import CartResource from '../../../resource/cart'
  import myApi from "../../../plugins/axios";
  import store from '../../../plugins/store'
export default {
    name: 'ModalDetailProduct',
    data(){
        return {
            cantidad: 1,
            product_selected_modal: null,
            CARTS: [],
        }
    },
    props:['is_pc_build'],
    methods:{
      getAmountTotal(product){
        if(product.discount_g && !product.code_offer){
            if(product.discount_g.discount_id){
            return Number(product.discount_g.new_amount.toFixed(2)).toFixed(2);
            // percentage
            }else{
            if(product.discount_g.type_discount == 1){//%
                return Number((product.precio_general - (product.precio_general*product.discount_g.discount*0.01)).toFixed(2)).toFixed(2);
            }else{
                return Number((product.precio_general - product.discount_g.discount).toFixed(2)).toFixed(2);
            }
            }
        }
        if(product.code_offer){
            if(product.code_offer.new_amount){
            return Number(product.code_offer.new_amount.toFixed(2)).toFixed(2);
            // percentage
            }else{
            if(product.code_offer.type_discount == 1){//%
                return Number((product.precio_general - (product.precio_general*product.code_offer.discount*0.01)).toFixed(2)).toFixed(2);
            }else{
                return Number((product.precio_general - product.code_offer.discount).toFixed(2)).toFixed(2);
            }
            }
        }
        return Number(product.precio_general).toFixed(2);
      },
      getAmountDiscount(product){
        return (product.precio_general).toFixed(2);
      },
      addCart(){
          if(this.cantidad < 0){
            this.$notify({
              title: "Cantidad de producto no válida",
              duration: 2500,
              type: 'error'
            });
            return;
          }
          let CARTS = this.CARTS;//this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
          // console.log(CARTS);
          CartResource.add(CARTS,this.product_selected_modal,this.emitter, this.$notify,this.$cookies,this.cantidad);
          // let CARTS = this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
          // // console.log(CARTS);
          // let INDEX = CARTS.findIndex(product => product.uniqd == this.product_selected_modal.uniqd);
          
          // if(INDEX != -1){
          //     CARTS[INDEX].quantity = CARTS[INDEX].quantity + this.cantidad;
          //     CARTS[INDEX].total =  CARTS[INDEX].subtotal * CARTS[INDEX].quantity;
          // }else{
          //     CARTS.push({
          //         title: this.product_selected_modal.title ,
          //         imagen: this.product_selected_modal.image,
          //         uniqd: this.product_selected_modal.uniqd ,
          //         slug: this.product_selected_modal.slug ,
          //         quantity: this.cantidad,
          //         price_unit: Math.round(this.product_selected_modal.precio_general),
          //         subtotal: Math.round(this.product_selected_modal.precio_general),
          //         total: Math.round(this.product_selected_modal.precio_general) * this.cantidad,
          //         type_discount: null,
          //         discount: null,
          //         code_cupon: null,
          //         code_discount: null,
          //     });
          // }

          // this.$cookies.set('carts',CARTS);

          // this.emitter.emit('cart-shop', CARTS);

          // this.$notify({
          //     title: "CARRITO DE COMPRA",
          //     text: `TU PRODUCTO '${this.product_selected_modal.title}' SE AGREGO AL CARRITO CORRECTAMENTE!`,
          //     duration: 1000,
          // });
      },
      addBuy(){
        if(this.cantidad < 0){
          this.$notify({
            title: "Cantidad de producto no válida",
            duration: 2500,
            type: 'error'
          });
          return;
        }
        this.addCart();
        setTimeout(() => {
          this.$router.push({
            name: "Checkout"
          });
        }, 100);
      },
      loadScriptJQuery(){
        const zoom_query = document.createElement("script");
        zoom_query.src = "../../assets/vendor/zoom/jquery.zoom.js";
        document.body.appendChild(zoom_query);
        zoom_query.onload = () => {
          console.log("archivo.js cargado jquery.zoom");
        };

        const swiper_sliders = document.createElement("script");
        swiper_sliders.src = "../../assets/vendor/swiper/swiper-bundle.min.js";
        document.body.appendChild(swiper_sliders);
        swiper_sliders.onload = () => {
          console.log("archivo.js cargado swiper_sliders");
        };

        // this.$cookies.set("DTP",1000);
        setTimeout(() => {
          const detail_product = document.createElement("script");
          detail_product.src = "../../assets/js/myjs/scriptJquery2.js";
          document.body.appendChild(detail_product);
          detail_product.onload = () => {
            console.log("archivo.js cargado scriptjquery");
          };
        },50)
      },
      async addCartKit(){
        if(this.quantity < 0){
          this.$notify({
            title: "Cantidad de producto no válida",
            duration: 2500,
            type: 'error'
          });
          return;
        }
        let CARTS = this.CARTS;
        // CartResource.add(CARTS,PRODUCTY,this.emitter,this.$notify,this.$cookies);
        this.ERROR_TEXT = null;
        if(!store.getters.loggedIn){
            this.product_selected_modal.products.forEach((prod) => {
                let data = CartResource.register_guest(prod,this.quantity);
                data.is_kit = 1;
                CARTS.push(data);
            })
            this.emitter.emit("cart-shop", CARTS);
            localStorage.setItem('carts',JSON.stringify(CARTS));
            this.$notify({
                title: "Productos agregados al carrito",
                duration: 3500,
                type: 'success',
            });
        }else{
            let NewProducts = [];
            this.product_selected_modal.products.forEach((prod) => {
                let data = CartResource.register_client(prod,this.quantity);
                NewProducts.push(data);
            })
            try {
                const resp = await myApi.post(
                    "ecommerce/carts-multiple/",{
                        products: NewProducts,
                    }
                );
                console.log(resp);
                resp.data.carts.forEach((cart) => {
                    CARTS.push(CartResource.update(cart));
                });
                this.emitter.emit("cart-shop", CARTS);
                this.$notify({
                    title: "Productos agregados al carrito",
                    duration: 3500,
                    type: 'success',
                });
            } catch (error) {
                console.log(error);
              this.$notify({
                title: "Algo salio mal intentalo nuevamente",
                duration: 2500,
                type: 'error',
              });
            }
        }
      },
      addBuyKit(){
        if(this.quantity < 0){
          this.$notify({
            title: "Cantidad de producto no válida",
            duration: 2500,
            type: 'error'
          });
          return;
        }
        this.addCartKit();
        setTimeout(() => {
          this.$router.push({
            name: "Checkout"
          });
        }, 100);
      },
    },
    created(){
        // console.log(this.product_selected_modal);
    },
    mounted(){
      // let IsDTP = this.$cookies.get("DTP");
      this.emitter.on('product-detail-modal',data => {
        console.log(data)
          this.product_selected_modal = null;
          setTimeout(() => {
            this.product_selected_modal = data;
            setTimeout(() => {
              if(this.product_selected_modal.images.length == 1){
                // Quitar los botones de navegación del DOM
                var nextButton = document.querySelector('.swiper-button-next');
                var prevButton = document.querySelector('.swiper-button-prev');
                if (nextButton) {
                    nextButton.parentNode.removeChild(nextButton);
                }
                if (prevButton) {
                    prevButton.parentNode.removeChild(prevButton);
                }
              }
              const script2 = document.createElement('script');
              script2.src = '../../assets/js/feather/feather.min.js';
              document.body.appendChild(script2);
              script2.onload = () => {
                  console.log('archivo.js cargado 2');
              }
  
              const detail_product = document.createElement('script');
              detail_product.src = '../../assets/js/myjs/modal.js';
              document.body.appendChild(detail_product);
              detail_product.onload = () => {
                  console.log('');
              }
              // if(!IsDTP){
                this.loadScriptJQuery();
              // }
            },50)
          }, 100);

        });

        this.emitter.on('cart-shop',data => {
            this.CARTS = data;
        });
        this.emitter.emit("cart-list", {});
    },
}
</script>